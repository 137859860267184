import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetCustomerUrl } from '../api';
import { AppStore, CustomerUrl, RecordList } from '../types';

// 请求成功时传送的参数
interface SuccessParams {
  list: Array<CustomerUrl>;
}
// 请求失败时传送的参数
interface FailureParams {
  error: string;
}

const customerUrlSlice = createSlice({
  name: 'customerUrl',
  initialState: { fetching: false, loaded: false } as RecordList<CustomerUrl>,
  reducers: {
    customerUrlRequest: (state: RecordList<CustomerUrl>, action: PayloadAction) => {
      state.fetching = true;
      return state;
    },
    customerUrlSuccess: (state: RecordList<CustomerUrl>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.list;
      return state;
    },
    customerUrlFailure: (state: RecordList<CustomerUrl>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    customerUrlClear: (state: RecordList<CustomerUrl>) => {
      state = { fetching: false, loaded: false } as RecordList<CustomerUrl>;
      return state;
    },
  },
});

export const { customerUrlRequest, customerUrlSuccess, customerUrlFailure, customerUrlClear } =
  customerUrlSlice.actions;
export default customerUrlSlice.reducer;

export const customerUrlEffect = {
  'customerUrl/clear': (store: ToolkitStore<AppStore>) => store.dispatch(customerUrlClear()),
  'customerUrl/customerUrlRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction) => {
    try {
      let res = await apiGetCustomerUrl();
      let data = res.data;
      if (data.code === 200) {
        store.dispatch(customerUrlSuccess({ list: data.data }));
      } else {
        store.dispatch(customerUrlFailure({ error: data.msg || '获取失败' }));
      }
    } catch (e: any) {
      store.dispatch(customerUrlFailure({ error: e.toString() || '获取失败' }));
    }
  },
};
