import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetComplaintDetail } from '../api';
import { AppStore, Complaint, DetailRecord } from '../types';

interface RequestParams {
  id: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams extends RequestParams {
  result: Complaint;
}

interface FailureParams extends RequestParams {
  error: string;
}

type ComplaintMap = { [x: string]: DetailRecord<Complaint> };

const complaintDetailSlice = createSlice({
  name: 'complaintDetail',
  initialState: {} as ComplaintMap,
  reducers: {
    complaintDetailRequest: (state: ComplaintMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { fetching: false, loaded: false };
      }
      state[action.payload.id].fetching = true;
      return state;
    },
    complaintDetailSuccess: (state: ComplaintMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { fetching: false, loaded: false };
      }
      state[action.payload.id].fetching = false;
      state[action.payload.id].loaded = true;
      state[action.payload.id].result = action.payload.result!;
      return state;
    },
    complaintDetailFailure: (state: ComplaintMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { fetching: false, loaded: false };
      }
      state[action.payload.id].fetching = false;
      state[action.payload.id].loaded = true;
      state[action.payload.id].error = action.payload.error;
      return state;
    },
    complaintDetailClear: (state: ComplaintMap) => {
      state = {};
      return state;
    },
  },
});

export const { complaintDetailRequest, complaintDetailSuccess, complaintDetailFailure, complaintDetailClear } =
  complaintDetailSlice.actions;
export default complaintDetailSlice.reducer;

export const complaintDetailEffect = {
  'complaintDetail/clear': (store: ToolkitStore<AppStore>) => store.dispatch(complaintDetailClear()),
  'complaintDetail/complaintDetailRequest': async (
    store: ToolkitStore<AppStore>,
    action: PayloadAction<RequestParams | undefined>
  ) => {
    var complaintDetail = store.getState().complaintDetail[action.payload?.id || ''];
    if (complaintDetail?.loaded === true && !complaintDetail?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(complaintDetailSuccess({ id: action.payload?.id ?? '', result: complaintDetail!.result! }));
    } else {
      try {
        let res = await apiGetComplaintDetail(action.payload?.id ?? '');
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(complaintDetailSuccess({ id: action.payload?.id ?? '', result: data.data }));
        } else {
          store.dispatch(complaintDetailFailure({ id: action.payload?.id ?? '', error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(complaintDetailFailure({ id: action.payload?.id ?? '', error: e.toString() || '获取失败' }));
      }
    }
  },
};
