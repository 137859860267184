import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppBar from '../components/appBar';
import Page from '../components/page';
import { AppStore } from '../types';
import { APIProtocolType } from '../utils/dict';

export default function ProtocolPage() {
  const { state } = useLocation();
  const protocolMap = useSelector((state: AppStore) => state.protocol);
  const protocol = protocolMap[state as unknown as APIProtocolType];
  return (
    <Page
      className="d-flex-c"
      contentClassName="flex-1 h-1 overflow-y"
      appBar={<AppBar title={protocol.result?.textTitle ?? '协议'} />}
    >
      <div className="p-10" dangerouslySetInnerHTML={{ __html: protocol.result?.textContent ?? '' }}></div>
    </Page>
  );
}
