import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetCardTransferList } from '../api';
import { CardTransfer, AppStore, RecordList } from '../types';

interface RequestParams {
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  results: Array<CardTransfer>;
}

interface FailureParams {
  error: string;
}

const cardTransferSlice = createSlice({
  name: 'cardTransfer',
  initialState: { fetching: false, loaded: false } as RecordList<CardTransfer>,
  reducers: {
    cardTransferRequest: (state: RecordList<CardTransfer>, action: PayloadAction<RequestParams | undefined>) => {
      state.fetching = true;
      return state;
    },
    cardTransferSuccess: (state: RecordList<CardTransfer>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.results!;
      return state;
    },
    cardTransferFailure: (state: RecordList<CardTransfer>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    cardTransferClear: (state: RecordList<CardTransfer>) => {
      state = { fetching: false, loaded: false };
      return state;
    },
  },
});

export const { cardTransferRequest, cardTransferSuccess, cardTransferFailure, cardTransferClear } =
  cardTransferSlice.actions;
export default cardTransferSlice.reducer;

export const cardTransferEffect = {
  'cardTransfer/clear': (store: ToolkitStore<AppStore>) => store.dispatch(cardTransferClear()),
  'cardTransfer/cardTransferRequest': async (
    store: ToolkitStore<AppStore>,
    action: PayloadAction<RequestParams | undefined>
  ) => {
    var cardTransferList = store.getState().cardTransfer;
    if (cardTransferList?.loaded === true && !cardTransferList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(cardTransferSuccess({ results: cardTransferList!.results! }));
    } else {
      try {
        let res = await apiGetCardTransferList();
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(cardTransferSuccess({ results: data.rows ?? [] }));
        } else {
          store.dispatch(cardTransferFailure({ error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(cardTransferFailure({ error: e.toString() || '获取失败' }));
      }
    }
  },
};
