import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetComplaintList } from '../api';
import { Complaint, AppStore, RecordList } from '../types';

interface RequestParams {
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  results: Array<Complaint>;
}

interface FailureParams {
  error: string;
}

const complaintSlice = createSlice({
  name: 'complaint',
  initialState: { fetching: false, loaded: false } as RecordList<Complaint>,
  reducers: {
    complaintRequest: (state: RecordList<Complaint>, action: PayloadAction<RequestParams | undefined>) => {
      state.fetching = true;
      return state;
    },
    complaintSuccess: (state: RecordList<Complaint>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.results!;
      return state;
    },
    complaintFailure: (state: RecordList<Complaint>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    complaintClear: (state: RecordList<Complaint>) => {
      state = { fetching: false, loaded: false };
      return state;
    },
  },
});

export const { complaintRequest, complaintSuccess, complaintFailure, complaintClear } = complaintSlice.actions;
export default complaintSlice.reducer;

export const complaintEffect = {
  'complaint/clear': (store: ToolkitStore<AppStore>) => store.dispatch(complaintClear()),
  'complaint/complaintRequest': async (
    store: ToolkitStore<AppStore>,
    action: PayloadAction<RequestParams | undefined>
  ) => {
    var complaintList = store.getState().complaint;
    if (complaintList?.loaded === true && !complaintList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(complaintSuccess({ results: complaintList!.results! }));
    } else {
      try {
        let res = await apiGetComplaintList();
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(complaintSuccess({ results: data.rows ?? [] }));
        } else {
          store.dispatch(complaintFailure({ error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(complaintFailure({ error: e.toString() || '获取失败' }));
      }
    }
  },
};
