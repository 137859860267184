import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetCardPackList } from '../api';
import { AppStore, CardPack, RecordList } from '../types';
import { modelParseFlow, modelParsePrice } from '../utils/utils';

interface RequestParams {
  cardId: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  cardId: string;
  results: Array<CardPack>;
}

interface FailureParams {
  error: string;
  cardId: string;
}

type CardPackMap = { [x: string]: RecordList<CardPack> };

const cardPackSlice = createSlice({
  name: 'cardPack',
  initialState: {} as CardPackMap,
  reducers: {
    cardPackRequest: (state: CardPackMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = true;
      return state;
    },
    cardPackSuccess: (state: CardPackMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].results = action.payload.results!;
      return state;
    },
    cardPackFailure: (state: CardPackMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].error = action.payload.error;
      return state;
    },
    cardPackClear: (state: CardPackMap) => {
      state = {};
      return state;
    },
  },
});

export const { cardPackRequest, cardPackSuccess, cardPackFailure, cardPackClear } = cardPackSlice.actions;
export default cardPackSlice.reducer;

export const cardPackEffect = {
  'cardPack/clear': (store: ToolkitStore<AppStore>) => store.dispatch(cardPackClear()),
  'cardPack/cardPackRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams>) => {
    var packList = store.getState().cardPack[action.payload?.cardId || ''];
    if (!action.payload.cardId) {
      store.dispatch(cardPackSuccess({ cardId: action.payload.cardId, results: [] }));
    } else if (packList?.loaded === true && !packList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(cardPackSuccess({ cardId: action.payload?.cardId ?? '', results: packList!.results! }));
    } else {
      try {
        let res = await apiGetCardPackList(action.payload.cardId);
        let data = res.data;
        if (data.code === 200) {
          let list: CardPack[] = [];
          (data.rows ?? []).forEach((i: CardPack) => {
            list.push(
              Object.assign({}, i, {
                salePrice: modelParsePrice(i.salePrice ?? 0),
                totalFlow: modelParseFlow(i.totalFlow ?? 0),
              })
            );
          });
          store.dispatch(cardPackSuccess({ cardId: action.payload.cardId, results: list }));
        } else {
          store.dispatch(cardPackFailure({ cardId: action.payload.cardId, error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(cardPackFailure({ cardId: action.payload.cardId, error: e.toString() || '获取失败' }));
      }
    }
  },
};
