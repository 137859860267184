import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiApplySwapCard } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import { LoadingMask, showToast } from '../components/dialog';
import Page from '../components/page';
import main, { AppLocalKey } from '../main';
import { addressRequest } from '../redux/addressReducer';
import { Address, AppStore } from '../types';

export default function ApplySwitchCardPage() {
  const { iccid, cardId } = useLocation().state ?? {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);
  const addressList = useSelector((state: AppStore) => state.address);
  const [address, setAddress] = useState<Address>();
  const [remark, setRemark] = useState('');
  useEffect(() => {
    if (!iccid || !cardId) {
      navigate(-1);
    }
    if (user.result?.userId) {
      dispatch(addressRequest());
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (!address) {
      let selectedAddress = localStorage.getItem(AppLocalKey.selectAddress);
      if (selectedAddress) {
        setAddress(JSON.parse(selectedAddress));
      } else if ((addressList.results?.length ?? 0) > 0) {
        let defaultAddress = addressList.results!.find((i) => i.ifDefault);
        if (defaultAddress) {
          setAddress(defaultAddress!);
        }
      }
    }
  }, [addressList]); // eslint-disable-line

  async function handleSubmit() {
    if (!cardId) {
      return showToast('无法查询到您输入的卡号,\n请核对后重试!\n');
    }
    if (!address?.provinceName) {
      return showToast('地址栏不能为空,\n请选择地址\n');
    }
    if (!remark) {
      return showToast('请填写换卡原因');
    }
    try {
      LoadingMask.show('正在提交...');

      let body = {
        cardId: cardId,
        iccid: iccid,
        provinceName: address.provinceName!,
        cityName: address.cityName!,
        countyName: address.countyName!,
        receiverName: address.receiverName!,
        receiverPhone: address.receiverPhone!,
        receiverAddress: address.receiverAddress!,
        customerDesc: remark,
      };
      var res = await apiApplySwapCard(body);
      var data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '提交成功');
        navigate(-1);
      } else {
        showToast(data.msg ?? '提交失败');
      }
    } catch (e: any) {
      showToast(e.message || '提交失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page
      className="d-flex-c bg-color-gray"
      contentClassName="flex-1 h-1 overflow-y p-20"
      appBar={<AppBar title="申请换卡" className="bg-color-gray" />}
    >
      <div className="font-size-14 mb-10">填写信息</div>
      <div className="bg-color-white border-radius-8 p-10">
        <div className="border-bottom pv-10 font-size-14">{iccid}</div>
        <div
          className="border-bottom pv-10 mt-10 d-flex-r ai-center"
          onClick={() => navigate('/address', { state: { isSelect: true } })}
        >
          <div className={`flex-1 ${address ? '' : 'text-color-6'}`}>
            {address ? (
              <div>
                <div className="d-flex-r ai-center">
                  <div className="font-size-14 font-widget-16">{address.receiverName}</div>
                  <div className="text-color-9 ml-10">{address.receiverPhone}</div>
                </div>
                <div>{`${address.provinceName}${address.cityName}${address.countyName}${address.receiverAddress}`}</div>
              </div>
            ) : (
              '请选择地址'
            )}
          </div>
          <img className="h-12" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="border-bottom pv-10 mt-10">
          <textarea
            className="bw-100 h-80"
            placeholder="请您仔细填写换卡原因，并联系客服专员核实，否则审核将不予通过；提交换卡申请并转卡成功后，原卡将被注销，请知悉！"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </div>
      </div>
      <Button className="mt-30" type="primary" shape="circle" long size="md" onClick={handleSubmit}>
        提交申请
      </Button>
      <div className="text-color-9 mt-30">
        <div className="mb-10 font-size-14">换卡需知</div>
        {[
          '1、请您按照要求填写换卡申请，并在提交完换卡工单后，务必联系客服专员核实确认，避免审核失败。',
          '2、每张卡片仅有一次换卡机会，提交后原卡将会被强制销户，请勿随意提交；',
          '3、新卡快递发出后，可以在【我的】-【换卡记录】中查询快递信息及物流轨迹。',
          '4、您收到新卡后，请根据说明书指导自主操作转卡，如遇任何问题，请及时联系人工客服处理。',
          '5、原卡上所有数据（余额、流量、优惠券、资费详情、充消记录、订购记录等）将自动转移到新卡，新卡仅须完成实名认证，稍等30分钟即可用网。',
        ].map((i) => (
          <div key={i}>{i}</div>
        ))}
      </div>
    </Page>
  );
}
