import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetCardList } from '../api';
import { AppStore, Card, RecordList } from '../types';
import { modelParseFlow, modelParsePrice } from '../utils/utils';

interface RequestParams {
  forceRefresh?: boolean;
}
// 请求成功时自动传送的参数
interface SuccessParams {
  results: Array<Card>;
}

interface FailureParams {
  error: string;
}

const cardSlice = createSlice({
  name: 'card',
  initialState: { fetching: false, loaded: false } as RecordList<Card>,
  reducers: {
    cardRequest: (state: RecordList<Card>, action: PayloadAction<RequestParams | undefined>) => {
      state.fetching = true;
      return state;
    },
    cardSuccess: (state: RecordList<Card>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.results!;
      return state;
    },
    cardFailure: (state: RecordList<Card>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    cardClear: (state: RecordList<Card>) => {
      state = { fetching: false, loaded: false };
      return state;
    },
  },
});

export const { cardRequest, cardSuccess, cardFailure, cardClear } = cardSlice.actions;
export default cardSlice.reducer;

export const cardEffect = {
  'card/clear': (store: ToolkitStore<AppStore>) => store.dispatch(cardClear()),
  'card/cardRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams | undefined>) => {
    var cardList = store.getState().card;
    if (cardList?.loaded === true && !cardList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(cardSuccess({ results: cardList!.results! }));
    } else {
      try {
        let res = await apiGetCardList();
        let data = res.data;
        if (data.code === 200) {
          let list: Card[] = [];
          (data.rows ?? []).forEach((i: any) => {
            let item: Card = Object.assign({}, i, {
              userTotalBalance: modelParsePrice(i.cardDetailWalletVo?.availableAmount ?? 0),
              transferId: i.cardDetailConvertVo?.id,
              transferStatus: i.cardDetailConvertVo?.status,

              totalFlow: modelParseFlow(i.totalFlow ?? 0),
              usedFlow: modelParseFlow(i.usedFlow ?? 0),
              surplusFlow: modelParseFlow(i.surplusFlow ?? 0),
              ifDefault: i.defaultStatus === 'Y',
              renewalStatus: i.renewalStatus,
            });
            if (i.cardPackOrderDetailVoList) {
              let cp = i.cardPackOrderDetailVoList.find((i: any) => i.status === 'Y');
              item.theMonth = cp;
              if (item.theMonth) {
                item.theMonth.packTotalFlow = modelParseFlow((cp.packTotalFlow ?? 0) * 1);
                item.theMonth.surplusFlow = modelParseFlow((cp.surplusFlow ?? 0) * 1);
                item.theMonth.totalFlow = modelParseFlow((cp.totalFlow ?? 0) * 1);
                item.theMonth.usedFlow = modelParseFlow((cp.usedFlow ?? 0) * 1);
              }
              let np = i.cardPackOrderDetailVoList.find((i: any) => i.status === 'N');
              item.nextMonth = np;
              if (item.nextMonth) {
                item.nextMonth.packTotalFlow = modelParseFlow((np.packTotalFlow ?? 0) * 1);
                item.nextMonth.surplusFlow = modelParseFlow((np.surplusFlow ?? 0) * 1);
                item.nextMonth.totalFlow = modelParseFlow((np.totalFlow ?? 0) * 1);
                item.nextMonth.usedFlow = modelParseFlow((np.usedFlow ?? 0) * 1);
              }
            }
            list.push(item);
          });
          store.dispatch(cardSuccess({ results: list }));
        } else {
          store.dispatch(cardFailure({ error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(cardFailure({ error: e.toString() || '获取失败' }));
      }
    }
  },
};
