import { useNavigate } from 'react-router-dom';
import { showConfirm, showToast } from '../../components/dialog';
import main from '../../main';
import { AppStore, CurrentCard } from '../../types';
import { CardStatus } from '../../utils/dict';
import { useSelector } from 'react-redux';

interface Props {
  card?: CurrentCard;
}
export default function HomeMenu({ card }: Props) {
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);

  async function handleJump(path: string) {
    if (!card?.id) {
      let res = await showConfirm({
        title: '温馨提示',
        content: '您尚未绑定卡片，该功能不可用！',
        confirmBtnText: '去绑卡',
      });
      if (res !== 1) return;
      navigate('/cardProcess', { state: { step: 1 } });
    } else {
      navigate(path);
    }
  }
  return (
    <div className="d-flex-r ai-center jc-around text-color-white mt-30">
      <div
        className="text-center"
        onClick={() => {
          if (user?.result?.payLimit === 'Y') {
            return showToast('当前账户存在风险，禁止交易');
          }
          handleJump(`/charge?id=${card?.id}`);
        }}
      >
        <img className="h-30" src={main.pic('icon_recharge.png')} alt="" />
        <div>充值缴费</div>
      </div>
      <div className="text-center" onClick={() => handleJump('/orderList')}>
        <img className="h-30" src={main.pic('icon_order.png')} alt="" />
        <div>订单管理</div>
      </div>
      <div className="text-center pos-r" onClick={() => handleJump('/cardCheck')}>
        <img className="h-30" src={main.pic('icon_intel.png')} alt="" />
        <div>智能检测</div>
        {!!card && card.cardStatus !== CardStatus.activated && (
          <img
            style={{ top: '-5px' }}
            className="pos-a r-0 w-14 h-14 z-10"
            src={main.pic('icon_check_warning.png')}
            alt=""
          />
        )}
      </div>
      <div className="text-center" onClick={() => handleJump('/coupon')}>
        <img className="h-30" src={main.pic('icon_coupon.png')} alt="" />
        <div>卡券中心</div>
      </div>
      <div className="text-center" onClick={() => navigate('/cardList')}>
        <img className="h-30" src={main.pic('icon_switch_card.png')} alt="" />
        <div>切换卡片</div>
      </div>
    </div>
  );
}
