import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppBar from '../components/appBar';
import Page from '../components/page';
import main from '../main';
import { AppStore } from '../types';
import { CustomServiceType } from '../utils/dict';
import { findCustomerUrl } from '../utils/utils';

export default function CustomerPage() {
  const { tel, from } = useLocation().state ?? {};
  const user = useSelector((state: AppStore) => state.user);
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const loginCustomer = useSelector((state: AppStore) => state.loginCustomerUrl);
  const customer = useSelector((state: AppStore) => state.customerUrl);

  let url: string = main.customer;
  if (!user.result?.userId) {
    if (!!loginCustomer.results?.length) {
      let c = loginCustomer.results!.find((i) => i.type === CustomServiceType.visitorGroup);
      if (c && c.serviceUrl) {
        url = c.serviceUrl;
      }
    }
  } else {
    url = findCustomerUrl(customer, currentCard?.result);
  }
  // 没有找到任何客服链接 用天诺的默认链接
  let _url = url;
  if (!_url) {
    _url = main.customer;
  }
  // 容联客服 添加clientId
  if (_url.includes('7moor.com') && !_url.includes('clientId')) {
    if (tel || user.result?.phonenumber) {
      _url += `&clientId=${tel ?? user.result?.phonenumber ?? ''}`;
    }
    if (from) {
      _url += `&urlTitle=${from}`;
    }
  }
  let _tel = tel || user.result?.phonenumber || '';
  let userParams = { uid: _tel ?? '', nickName: _tel };
  _url += `&tel=${_tel}&cardId=${currentCard?.result?.iccid ?? ''}`;
  if (_url.includes('customerFields')) {
    _url = _url.replace('customerFields', `&userParams=${JSON.stringify(userParams)}&customerFields`);
  } else {
    _url += `&userParams=${JSON.stringify(userParams)}`;
  }

  console.log('customerUrl', _url);

  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1 d-flex-c" appBar={<AppBar title="客服" />}>
      <iframe className="flex-1 border-none" title="客服" src={_url} />
    </Page>
  );
}
