import { useEffect, useState } from 'react';
import { showToast } from '../dialog';
import jsonList from './area.json';

interface JsonData {
  label: string;
  value: string;
  children?: JsonData[];
}

interface CitySelectDialogProps {
  province?: string;
  city?: string;
  area?: string;
}
interface Props extends CitySelectDialogProps {
  onCancel: () => void;
  onConfirm: (c: string[]) => void;
}

type JsonType = 'province' | 'city' | 'area';

export default function CitySelectDialog({ onCancel, onConfirm, province: p, city: c, area: a }: Props) {
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [area, setArea] = useState('');
  const [provinceList, setProvinceList] = useState<Array<JsonData>>([]);
  const [cityList, setCityList] = useState<Array<JsonData>>([]);
  const [areaList, setAreaList] = useState<Array<JsonData>>([]);
  const [type, setType] = useState<JsonType>('province');

  useEffect(() => {
    let _type: JsonType = 'province';
    setProvinceList(jsonList);
    if (p) {
      let pData = jsonList.find((i: JsonData) => i.label.includes(p) || p.includes(i.label));
      if (pData) {
        _type = 'province';
        setProvince(p);
        setCityList(pData.children!);
        if (c) {
          let cData = pData.children!.find((i: JsonData) => i.label.includes(c) || c.includes(i.label));
          if (cData) {
            _type = 'city';
            setCity(c);
            setAreaList(cData.children);
            if (a) {
              let aData = cData.children!.find((i: JsonData) => i.label.includes(a) || a.includes(i.label));
              if (aData) {
                _type = 'area';
                setArea(a);
              }
            }
          }
        }
      }
    }
    setType(_type);
  }, []); //eslint-disable-line

  function getCurrentValue() {
    if (type === 'province') {
      return province;
    } else if (type === 'city') {
      return city;
    } else if (type === 'area') {
      return area;
    }
  }

  function getTypeData() {
    let data: Array<JsonData> = [];
    if (type === 'province') {
      data = provinceList;
    } else if (type === 'city') {
      data = cityList;
    } else if (type === 'area') {
      data = areaList;
    }
    return data;
  }

  function handleTypeSelect(_type: JsonType) {
    var _t = _type;
    if (_type === 'area') {
      if (!city) {
        _t = 'city';
      }
      if (!province) {
        _t = 'province';
      }
    } else if (_type === 'city') {
      if (!province) {
        _t = 'province';
      }
    }

    if (_t !== type) {
      setType(_t);
    }
  }

  function handleSelect(item: JsonData) {
    if (type === 'province') {
      setProvince(item.label);
      setCity('');
      setArea('');
      setType('city');
      setCityList(item.children ?? []);
      setAreaList([]);
    } else if (type === 'city') {
      setCity(item.label);
      setArea('');
      setType('area');
      setAreaList(item.children ?? []);
    } else if (type === 'area') {
      setArea(item.label);
    }
  }

  function handleConfirm() {
    if (!province) {
      return showToast('请选择省份');
    } else if (!city) {
      return showToast('请选择城市');
    } else if (!area) {
      return showToast('请选择地区');
    }
    onConfirm([province, city, area]);
  }

  let list = getTypeData();
  return (
    <div className="city-select-sheet bg-color-white border-radius-tl-8 border-radius-tr-8">
      <div className="d-flex-r ai-center pv-14 border-bottom">
        <div className="text-color-6 ph-10" onClick={onCancel}>
          取消
        </div>
        <div className="font-size-14 flex-1 text-center">城市选择</div>
        <div className="text-color-primary ph-10" onClick={handleConfirm}>
          确定
        </div>
      </div>
      <div className="d-flex-r pl-10 pv-14 select-block">
        <div
          className={`select-item ${type === 'province' ? 'active' : ''}`}
          onClick={() => handleTypeSelect('province')}
        >
          {province || '请选择'}
        </div>
        <div className={`select-item ${type === 'city' ? 'active' : ''}`} onClick={() => handleTypeSelect('city')}>
          {city || '请选择'}
        </div>
        <div className={`select-item ${type === 'area' ? 'active' : ''}`} onClick={() => handleTypeSelect('area')}>
          {area || '请选择'}
        </div>
      </div>
      <div className="p-10">请选择{type === 'province' ? '省份' : type === 'city' ? '城市' : '县/区'}</div>
      <div className="list-block">
        {list.map((item, idx) => (
          <div
            onClick={() => handleSelect(item)}
            key={(item.label || '') + idx}
            className={`p-10 ${item.label === getCurrentValue() ? 'text-color-primary' : ''}`}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
}
