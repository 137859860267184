import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiBindCard, apiGetCommonCaptcha, apiLogin } from '../../api';
import Button from '../../components/button';
import { LoadingMask, showToast } from '../../components/dialog';
import main, { AppLocalKey } from '../../main';
import { cardRequest } from '../../redux/cardReducer';
import { currentCardRequest } from '../../redux/currentCardReducer';
import { userInfoRequest } from '../../redux/userInfoReducer';
import { delay, validateTel } from '../../utils/utils';
interface Props {
  iccid: string;
  onOk: () => void;
}
const time = 60;
export default function BindTel({ iccid, onOk }: Props) {
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [captcha, setCaptcha] = useState('');
  const timerRef = useRef<{ timer?: NodeJS.Timeout; time: number }>({ time: 0 });
  const [showTime, setShowTime] = useState(0);
  const dispatch = useDispatch();

  async function handleSendCaptcha() {
    if (!validateTel(tel)) {
      showToast('请输入正确的手机号');
      return;
    }
    if (showTime > 0) return;

    try {
      LoadingMask.show();
      let res = await apiGetCommonCaptcha(tel);
      let data = res.data;
      if (data.code === 200) {
        timerRef.current.time = time;
        startTimer();
      } else {
        showToast(data.msg || '发送失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '验证码发送失败,请稍后再试');
    } finally {
      LoadingMask.hide();
    }
  }
  function startTimer() {
    if (timerRef.current.timer) {
      return;
    }
    timerRef.current.timer = setInterval(() => {
      timerRef.current.time -= 1;
      setShowTime(timerRef.current.time);
      if (timerRef.current.time <= 0) {
        clearInterval(timerRef.current.timer);
        timerRef.current.timer = undefined;
        return;
      }
    }, 1000);
  }
  async function handleBind() {
    if (!validateTel(tel)) {
      showToast('请输入正确的手机号');
      return;
    }
    if (!captcha) {
      return showToast('请输入验证码');
    }
    LoadingMask.show('正在绑定...');
    try {
      let loginRes = await apiLogin(tel, captcha);

      let loginData = loginRes.data;

      if (loginData.code === 200) {
        let token = loginData.data.access_token;
        main.loginToken = token;
        localStorage.setItem(AppLocalKey.loginToken, token);
        localStorage.setItem(AppLocalKey.loginUsername, tel);
        dispatch(userInfoRequest());
        await delay(1000);
        let historyPhone = localStorage.getItem(AppLocalKey.historyPhone) ?? '';
        if (!!historyPhone) {
          if (!historyPhone.includes(tel)) {
            historyPhone = `${tel},` + historyPhone;
          }
        } else {
          historyPhone = tel;
        }
        historyPhone = historyPhone.split(',').splice(0, 5).join(',');
        localStorage.setItem(AppLocalKey.historyPhone, historyPhone);
        // 绑卡
        let bindRes = await apiBindCard(iccid, name, true);
        let bindData = bindRes.data;
        if (bindData.code === 200) {
          dispatch(cardRequest({ forceRefresh: true }));
          dispatch(currentCardRequest({ forceRefresh: true }));
        } else {
          showToast(bindData.msg || '绑定失败');
        }
        onOk();
      } else {
        showToast(loginData.msg || '登录失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '登录失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <div className="ml-20 pt-50 d-flex-c ai-center w-335">
      <div className="mb-10">绑定手机号</div>
      <div className="w-280 p-10 d-shadow text-center border-radius-8">
        <img className="h-126" src={main.pic('icon_bind_tel.png', true)} alt="" />
      </div>
      <div className="mt-50 border border-radius-4 d-flex-r ai-center p-10 bw-100">
        <input
          type="text"
          className="flex-1"
          placeholder="请输入姓名"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mt-10 border border-radius-4 d-flex-r ai-center p-10 bw-100">
        <input
          type="text"
          className="flex-1"
          placeholder="请输入手机号"
          value={tel}
          onChange={(e) => setTel(e.target.value)}
        />
      </div>
      <div className="mt-10 border border-radius-4 d-flex-r ai-center p-10 bw-100">
        <input
          type="text"
          className="flex-1"
          placeholder="请输入验证码"
          value={captcha}
          onChange={(e) => setCaptcha(e.target.value)}
        />
        <div
          className={`ph-10 ${showTime <= 0 ? 'text-color-primary' : 'text-color-9'} font-size-12`}
          onClick={handleSendCaptcha}
        >
          {showTime > 0 ? `(${showTime})s` : '发送验证码'}
        </div>
      </div>
      <Button className="mt-50" type="primary" size="md" shape="circle" long onClick={handleBind}>
        立即绑定
      </Button>
    </div>
  );
}
