import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppStore, Card } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { cardRequest } from '../redux/cardReducer';
import Page from '../components/page';
import AppBar from '../components/appBar';
import BindCard from './cardProcessComponents/bindCard';
import BuyPack from './cardProcessComponents/buyPack';
import RealName from './cardProcessComponents/realName';
import DefaultLoading from '../components/defaultPage/defaultLoading';
export default function CardProcessPage() {
  const dispatch = useDispatch();
  const { step: argStep, iccid: argIccid, monthType } = useLocation().state ?? {};
  /** 当前卡号 传过来的或者刚绑定的 */
  const [iccid, setIccid] = useState(argIccid);
  /** 1 绑定卡片 2 充值订购 3 实名认证 */
  const [step, setStep] = useState(argStep ?? 1);
  const [card, setCard] = useState<Card>();
  const user = useSelector((state: AppStore) => state.user);
  const cardList = useSelector((state: AppStore) => state.card);

  useEffect(() => {
    if (user.result?.userId) {
      dispatch(cardRequest());
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if ((cardList.results?.length ?? 0) > 0 && iccid) {
      setCard(cardList.results?.find((item) => item.iccid === iccid));
    }
  }, [cardList, iccid, step]); // eslint-disable-line

  let pageTitle = '绑定卡片';
  if (step === 1) {
    pageTitle = '绑定卡片';
  } else if (step === 2) {
    pageTitle = '充值订购';
  } else if (step === 3) {
    pageTitle = '实名认证';
  }

  function renderStep(label: string, active?: boolean) {
    return (
      <div className="d-flex-c ai-center jc-center">
        <div
          className={`w-34 h-34 border-radius-34 d-flex-r d-flex-r ai-center jc-center ${
            active ? 'bg-color-white-t3' : 'bg-color-t0'
          }`}
        >
          <div className={`w-22 h-22 border-radius-24 ${active ? 'bg-color-white' : 'bg-color-white-t3'}`}></div>
        </div>
        <div className="mt-5 text-color-white">{label}</div>
      </div>
    );
  }
  return (
    <div className="pos-r">
      <div className="pos-a z-0 t-0 l-0 r-0 vh-40 gradient-desc-primary-t1"></div>
      <Page
        className="pos-r z-10 d-flex-c"
        contentClassName="flex-1 h-1 d-flex-c overflow-hidden"
        appBar={<AppBar className="bg-color-t0" white title={pageTitle} />}
      >
        <div className="d-flex-r ai-center mv-20 mh-40">
          {renderStep('绑定卡片', true)}
          <div className="flex-1 h-2 bg-color-white-t3 mb-14"></div>
          {renderStep('充值订购', step >= 2)}
          <div className="flex-1 h-2 bg-color-white-t3 mb-14"></div>
          {renderStep('实名认证', step === 3)}
        </div>
        <div className="flex-1 h-1 overflow-y d-flex-c bg-color-white border-radius-tl-18 border-radius-tr-18">
          {step !== 1 && !card && (
            <div className="mt-100">
              <DefaultLoading />
            </div>
          )}
          {step === 1 && (
            <BindCard
              onOk={(v) => {
                setIccid(v);
                setStep(2);
              }}
            />
          )}
          {step === 2 && !!card && (
            <div className="flex-1 h-1 overflow-y">
              <BuyPack card={card} monthType={monthType} onOk={() => setStep(3)} />
            </div>
          )}
          {step === 3 && !!card && <RealName card={card} />}
        </div>
      </Page>
    </div>
  );
}
