import React, { useRef, useState } from 'react';
import main from '../../main';
interface Props {
  onClick?: () => void;
  onComplaint?: () => void;
}

const itemWidth = 40;
const itemHeight = 120;
const space = 20;
export default function LoginFloatCustomer({ onClick, onComplaint }: Props) {
  const divRef = useRef<any>();
  const [left, setLeft] = useState<number>(window.innerWidth - itemWidth - space); // 向左移动的距离
  const [top, setTop] = useState<number>(window.innerHeight - itemHeight - 150); // 向上移动的距离
  function handleClick(e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick!();
    }
  }

  //开始拖拽
  function handleDragStart() {
    divRef!.current!.style.transition = 'none';
    document.body.style.overflow = 'hidden';
  }
  //触摸移动
  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (e.targetTouches.length === 1) {
      let touch = e.targetTouches[0];
      let left = touch.clientX - itemWidth / 2;
      let top = touch.clientY - itemHeight / 2;
      if (left < space) {
        left = space;
      }
      if (left > window.innerWidth - itemWidth - space) {
        left = window.innerWidth - itemWidth - space;
      }
      if (top < space) {
        top = space;
      }
      if (top > window.innerHeight - itemHeight - space) {
        top = window.innerHeight - itemHeight - space;
      }
      setLeft(left);
      setTop(top);
    }
  }
  function hanldeTouchEnd() {
    document.body.style.overflow = '';
  }
  // shake-animation
  return (
    <div
      className="bg-color-white d-shadow pos-f d-flex-c ai-center jc-center border-radius-40 z-300"
      ref={divRef}
      style={{
        width: `${itemWidth}px`,
        height: `${itemHeight}px`,
        left: `${left}px`,
        top: `${top}px`,
      }}
      onTouchStart={(e) => handleDragStart()}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => hanldeTouchEnd()}
      onClick={handleClick}
    >
      <img className="icon-24" src={main.pic('icon_customer.png', true)} alt="" />
      <div className="w-12 text-color-primary mt-5">在线客服</div>
    </div>
  );
}
