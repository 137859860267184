import { Action, configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { AppStore } from '../types';
import addressReducer, { addressEffect } from './addressReducer';
import availableCouponReducer, { availableCouponEffect } from './availableCouponReducer';
import buyPackLogReducer, { buyPackLogEffect } from './buyPackLogReducer';
import cardPackReducer, { cardPackEffect } from './cardPackReducer';
import cardReducer, { cardEffect } from './cardReducer';
import cardTransferReducer, { cardTransferEffect } from './cardTransferReducer';
import chargeDirectReducer, { chargeDirectEffect } from './chargeDirectReducer';
import chargeRenewalReducer, { chargeRenewalEffect } from './chargeRenewalReducer';
import complaintDetailReducer, { complaintDetailEffect } from './complaintDetailReducer';
import complaintReducer, { complaintEffect } from './complaintReducer';
import couponReducer, { couponEffect } from './couponReducer';
import currentCardReducer, { currentCardEffect } from './currentCardReducer';
import customerUrlReducer, { customerUrlEffect } from './customerUrlReducer';
import incomeReducer, { incomeEffect } from './incomeReducer';
import loginCustomerUrlReducer, { loginCustomerUrlEffect } from './loginCustomerUrlReducer';
import orderDetailReducer, { orderDetailEffect } from './orderDetailReducer';
import orderReducer, { orderEffect } from './orderReducer';
import payDeductionReducer, { payDeductionEffect } from './payDeductionReducer';
import payTypeConfigReducer, { payTypeConfigEffect } from './payTypeConfigReducer';
import protocolReducer, { protocolEffect } from './protocolReducer';
import startupReducer, { startUp, startUpEffect } from './startupReducer';
import systemConfigReducer, { systemConfigEffect } from './systemConfigReducer';
import themeReducer, { themeEffect } from './themeReducer';
import userInfoReducer, { userInfoEffect } from './userInfoReducer';
import chargeFirstReducer, { chargeFirstEffect } from './chargeFirstReducer';
import cardSliderReducer, { cardSliderEffect } from './cardSliderReducer';
import { logoutEffect } from './logoutReducer';

// 所有的Effect放到这里
const effectData: { [x: string]: Function } = Object.assign(
  {},
  logoutEffect,
  startUpEffect,
  themeEffect,
  protocolEffect,
  userInfoEffect,
  systemConfigEffect,
  currentCardEffect,
  customerUrlEffect,
  payTypeConfigEffect,
  cardPackEffect,
  cardEffect,
  addressEffect,
  chargeRenewalEffect,
  chargeDirectEffect,
  payDeductionEffect,
  availableCouponEffect,
  orderEffect,
  orderDetailEffect,
  incomeEffect,
  buyPackLogEffect,
  couponEffect,
  cardTransferEffect,
  loginCustomerUrlEffect,
  complaintEffect,
  complaintDetailEffect,
  chargeFirstEffect,
  cardSliderEffect,
  {}
);

// 数据中间件 这里综合了所有Effect 已配置到store里 所以store里不需要再动了
const dataMiddleWare = (storeApi: ToolkitStore) => (next: Function) => (action: Action) => {
  next(action);
  let fn = effectData[action.type];
  if (fn) {
    fn(storeApi, action);
  }
};

// 清除数据中间件 清除所有用户相关数据 用于注销
const logoutMiddleware: any = (storeApi: ToolkitStore) => (next: Function) => (action: Action) => {
  next(action);
  if (action.type === 'logout/logoutRequest') {
    for (let keys of Object.keys(effectData)) {
      if (keys.includes('/clear')) {
        effectData[keys](storeApi);
      }
    }
  }
};

// 打印中间件 输出store日志
const printMiddleware: any = (storeApi: ToolkitStore) => (next: Function) => (action: Action) => {
  console.log('ACTION_TYPE:', action.type, { action, store: storeApi.getState() });
  next(action);
};

const store: ToolkitStore<AppStore> = configureStore({
  reducer: {
    // 所有reducer放到这里
    startup: startupReducer,
    theme: themeReducer,
    protocol: protocolReducer,
    user: userInfoReducer,
    systemConfig: systemConfigReducer,
    currentCard: currentCardReducer,
    loginCustomerUrl: loginCustomerUrlReducer,
    customerUrl: customerUrlReducer,
    payTypeConfig: payTypeConfigReducer,
    cardPack: cardPackReducer,
    card: cardReducer,
    address: addressReducer,
    chargeRenewal: chargeRenewalReducer,
    chargeDirect: chargeDirectReducer,
    payDeduction: payDeductionReducer,
    availableCoupon: availableCouponReducer,
    order: orderReducer,
    orderDetail: orderDetailReducer,
    income: incomeReducer,
    buyPackLog: buyPackLogReducer,
    coupon: couponReducer,
    cardTransfer: cardTransferReducer,
    complaint: complaintReducer,
    complaintDetail: complaintDetailReducer,
    chargeFirst: chargeFirstReducer,
    cardSlider: cardSliderReducer,
  },
  middleware: [printMiddleware, dataMiddleWare, logoutMiddleware],
});

export default store;

store.dispatch(startUp('start'));
