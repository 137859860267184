import main from './main';
import { Address } from './types';
import { APIProtocolType } from './utils/dict';
/**
 * 获取协议
 * @param type 协议类型
 * @returns
 */
export async function apiGetProtocol(type: APIProtocolType) {
  let res = await main.api.get('/client/sysPrivacy/list', {
    params: {
      pageSize: 1,
      pageNum: 1,
      orderByColumn: 'createTime',
      isAsc: 'desc',
      textType: type,
      appName: main.appName ?? 'ctFlow',
    },
  });

  return res;
}

/**
 * 图片上传
 * @param file
 * @returns
 */
export async function apiUploadPic(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  let res = await main.api.post('/client/common/upload', formData);

  let url: string | null = null;
  if (res.data.code === 200) {
    url = res.data.data.url;
  }

  return url;
}
/**
 * 获取微信静默授权openId
 * @param code 微信CODE
 * @param appId 微信APPID
 * @returns
 */
export async function apiCodeToOpenId(code: string, appId: string) {
  let res = await main.api.get(`/client/common/getUserOpenId?code=${code}&appId=${appId}`);
  return res;
}

/**
 * 获取用户信息
 * @param token
 * @returns
 */
export async function apiGetUserInfo(token?: string) {
  main.api.defaults.headers.common['Authorization'] = `Bearer ${main.loginToken || token}`;
  let res = await main.api.get('/system/user/getInfo');

  return res;
}

/**
 * 获取 验证码 通用
 * @param tel 手机号
 * @returns
 */
export async function apiGetCommonCaptcha(tel: string) {
  let res = await main.api.get('/resource/sysSms/code?phonenumber=' + tel + '&applyDetailName=' + main.appName);
  return res;
}

/**
 * 登录 得到token
 * @param tel 手机号
 * @param captcha 验证码
 * @returns
 */
export async function apiLogin(tel: string, captcha: string) {
  let res = await main.api.post('/auth/smsLogin', {
    phonenumber: tel,
    smsCode: captcha,
    deptId: main.deptId,
    appType: main.appName,
    appName: main.appName,
  });
  return res;
}

/**
 * 登录 得到token
 * @param tel 手机号
 * @param cardNum 卡号
 * @returns
 */
export async function apiLoginWithCard(tel: string, cardNum: string) {
  var res = await main.api.post('/auth/iccIdPhoneLogin', {
    phonenumber: tel,
    iccId: cardNum,
    deptId: main.deptId,
    appType: main.appName,
    appName: main.appName,
  });

  return res;
}

/**
 * 退出登录
 * @returns
 */
export async function apiLogout() {
  var res = await main.api.delete('/auth/logout');
  return res;
}
/**
 * 账户注销
 * @returns
 */
export async function apiDestroyAccount() {
  var res = await main.api.delete('/client/common/destroyAccount');
  return res;
}

/**
 * 获取配置列表
 * @returns
 */
export async function apiGetConfigList() {
  return await main.api.post('/client/common/getConfig');
}

/**
 * 获取当前卡
 * @returns
 */
export async function apiGetCardInfo(id?: string) {
  var res = await main.api.get('/client/cardDetail/getInfo', { params: { id } });
  return res;
}
/**
 *  获取总共的卡数量
 * @returns
 */
export async function apiGetAllCardInfo() {
  var res = await main.api.get('/client/cardDetail/getAllCard');
  return res;
}

/**
 * 获取登录页的客服链接
 * @returns
 */
export async function apiGetLoginCustomerUrl() {
  var res = await main.api.get('/client/cardServiceGroup/login', { params: { appName: main.appName } });
  return res;
}

/**
 * 获取登录后的客服链接
 * @returns
 */
export async function apiGetCustomerUrl() {
  var res = await main.api.get('/client/cardServiceGroup/list', { params: { appName: main.appName } });
  return res;
}

/**
 * 获取支付类型列表
 * @returns
 */
export async function apiGetPayTypeConfigList() {
  var res = await main.api.get('/client/payConfig/list', {
    params: {
      status: 'enable',
      orderByColumn: 'sortNum',
      isAsc: 'asc',
      appName: main.appName,
    },
  });
  return res;
}

/// 获取套餐包列表
export async function apiGetCardPackList(cardId: string) {
  var res = await main.api.get('/client/cardPackDetail/listByCardId', {
    params: {
      cardId: cardId,
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/**
 * 获取已绑定卡列表 首页转卡弹窗专用
 * @param iccid
 * @returns
 */
export async function apiGetCardListForIccid(iccid?: string) {
  var res = await main.api.get('/client/cardDetail/list', {
    params: {
      iccid,
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/**
 * 获取已绑定卡列表
 * @returns
 */
export async function apiGetCardList() {
  var res = await main.api.get('/client/cardDetail/list', {
    params: {
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/**
 * 编辑卡信息
 * @param id
 * @param remark
 * @param isDefault
 * @returns
 */
export async function apiEditCard(id: string, remark?: string, isDefault?: boolean) {
  var res = await main.api.post('/client/cardDetail/setDefault', {
    id: id,
    remark: remark,
    defaultStatus: isDefault === true ? 'Y' : undefined,
  });
  return res;
}

/**
 * 解除绑定卡号
 * @param cardId
 * @returns
 */
export async function apiRemoveCard(cardId: string) {
  var res = await main.api.post(`/client/cardDetail/unbind?cardId=${cardId}`);

  return res;
}

/**
 * 获取地址列表
 * @returns
 */
export async function apiGetAddressList() {
  let res = await main.api.get('/client/cardUserAddress/list', {
    params: {
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/**
 * 创建地址 更新地址 更新 创建是同一个接口
 * @param address
 * @returns
 */
export async function apiEditAddress(address: Address) {
  var fn = address.id ? main.api.put : main.api.post;
  let res = await fn('/client/cardUserAddress', address);

  return res;
}

/**
 * 删除地址
 * @param id
 * @returns
 */
export async function apiRemoveAddress(id: string) {
  let res = await main.api.delete('/client/cardUserAddress/' + id);

  return res;
}

/**
 * 申请换卡
 * @param body
 * @returns
 */
export async function apiApplySwapCard(body: any) {
  var res = await main.api.post('/client/cardDetailConvert', body);

  return res;
}
/**
 * jsapi配置
 * @param url
 * @param appId
 * @returns
 */
export async function apiGetWeChatSignature(url: string, appId: string) {
  return await main.api.get(`/client/common/getWeChatSignature`, { params: { url: encodeURIComponent(url), appId } });
}

/**
 * 修改转卡记录状态
 * @param id 转卡记录ID
 * @param nextStatus 卡状态  待签收->签收  待转卡->转卡中
 * @param newIccid 新的iccid
 * @returns
 */
export async function apiUpdateCardTransferLogStatus(id: string, nextStatus: string, newIccid?: string) {
  var res = await main.api.put('/client/cardDetailConvert', { id: id, status: nextStatus, newIccid: newIccid });

  return res;
}

/**
 * 查询卡库里的卡号
 * @param iccid 卡号
 */
export async function apiGetCardLibraryList(iccid: string) {
  return await main.api.get('/client/cardLibrary/list', {
    params: {
      iccid,
    },
  });
}
/**
 * 绑定卡号
 * @param iccid 卡号
 * @param remark 备注
 * @param isDefault
 * @returns
 */
export async function apiBindCard(iccid: string, remark = '', isDefault = false) {
  var res = await main.api.post('/client/cardDetail/bind', {
    iccid: iccid,
    remark: remark,
    defaultStatus: isDefault === true ? 'Y' : 'N',
  });

  return res;
}

/**
 * 验证号码是不是卡号和是不是手机号
 * @param num 输入的内容
 * @returns
 */
export async function apiValidateNum(num: string) {
  return await main.api.get(`/client/cardDetail/query/cardAndUser/${num}`, { params: { appName: main.appName } });
}

/**
 * 首页是否弹出活动
 * @param cardId 卡id
 * @param rechargeType  充值类型
 * @returns
 */
export async function apiIsActive(cardId: string, rechargeType: string) {
  var res = await main.api.get('/client/cardPay/isActive', { params: { cardId: cardId, payType: rechargeType } });
  return res;
}

/**
 *  购买首充活动 创建订单
 * @param cardId
 * @param cardPayDetailId
 * @returns
 */
export async function apiCreateFirstActivityOrder(cardId: string, cardPayDetailId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/create', {
    cardId: cardId,
    cardPayDetailId: cardPayDetailId,
  });
  return res;
}

/**
 *  购买首充活动支付信息
 * @param orderId
 * @param payType
 * @param openId
 * @param appId
 * @returns
 */
export async function apiPayFirstActivityOrder(orderId: string, payType: string, openId: string, appId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/getPayInfo', {
    orderId: orderId,
    payType: payType,
    openId,
    appId,
    // 'payConfigId': payTypeId,
  });
  return res;
}

/**
 * 首充活动列表
 * @param cardId 卡id
 * @returns
 */
export async function apiGetFirstActivityList(cardId: string) {
  var res = await main.api.get('/client/cardPay/first', { params: { cardId: cardId } });

  return res;
}

/**
 * 续充活动列表 购买套餐时候使用
 * @param cardId 卡id
 * @returns
 */
export async function apiGetChargeRenewalList(cardId: string) {
  var res = await main.api.get('/client/cardPay/renewal', { params: { cardId: cardId } });

  return res;
}

/**
 * 直充活动列表 购买套餐时候使用
 * @param cardId 卡id
 * @returns
 */
export async function apiGetDirectRechargeList(cardId: string) {
  var res = await main.api.get('/client/cardPay/directList', { params: { cardId: cardId } });

  return res;
}
/**
 * 支付优惠
 * @param cardId 卡id
 */
export async function apiGetPayConfigReduction(cardId: string) {
  var res = await main.api.get('client/payConfig/discounts', { params: { cardId: cardId } });
  return res;
}
/**
 *  获取用户余额和是否有密码
 * @param cardId 卡id
 * @returns
 */
export async function apiGetBalance(cardId: string) {
  var res = await main.api.get('/client/cardDetailWallet', { params: { cardId: cardId } });

  return res;
}
/**
 * 获取优惠券列表
 * @returns
 */
export async function apiGetCouponList(cardId: string) {
  var res = await main.api.get('/client/payCouponCardUser/list', {
    params: {
      orderByColumn: 'createTime',
      isAsc: 'desc',
      cardId,
    },
  });

  return res;
}
/**
 * 使用优惠券
 * @param id 优惠券id
 * @returns
 */
export async function apiUseFlowCoupon(id: string) {
  var res = await main.api.post(`/client/payCouponCardUser/useFlow?id=${id}`);
  return res;
}

/**
 * 获取可用优惠券列表
 * @param cardId 卡ID
 * @param cardPackDetailId 套餐ID
 * @param number 套餐数量
 * @returns
 */
export async function apiGetAvailableCouponList(cardId: string, cardPackDetailId: string, number: number) {
  var res = await main.api.get('/client/cardPackOrder/getCouponListByCardPackDetailId', {
    params: {
      cardId: cardId,
      cardPackDetailId: cardPackDetailId,
      number,
    },
  });
  return res;
}

/**
 * 预下单
 * @param cardPackDetailId 套餐ID
 * @param cardId 卡ID
 * @param couponCodeIds 优惠券ID
 * @param dateType 日期类型 本期 下期
 * @param number 购买套餐数量
 * @returns
 */
export async function apiPreOrder(
  cardPackDetailId: string,
  cardId: string,
  couponCodeIds?: Array<string>,
  dateType?: string,
  number?: number
) {
  var res = await main.api.post('/client/cardPackOrder/preview', {
    cardPackDetailId: cardPackDetailId,
    cardId: cardId,
    couponCodeIds: couponCodeIds,
    dateType: dateType,
    number,
  });
  return res;
}

/**
 * 新增和修改操作密码
 * @param pwd 密码
 * @param captcha 验证码
 * @param phone 手机号
 * @returns
 */
export async function apiCreateAndEditPwd(pwd?: string, captcha?: string, phone?: string) {
  var res = await main.api.post('/client/cardUser/editPayPassWordByPhoneNumber', {
    phoneNumber: phone,
    code: captcha,
    payPassWord: pwd,
  });

  return res;
}

/**
 * 统一支付
 * @param body
 * @returns
 */
export async function apiUnifiedPay(body: any) {
  var res = await main.api.post('/client/cardDetailWalletOrder/unifiedPay', body);
  return res;
}

/**
 * 套餐订单列表
 * @returns
 */
export async function apiGetPackOrderList(cardId: string) {
  var res = await main.api.get('/client/cardPackOrder/list', {
    params: {
      orderByColumn: 'createTime',
      isAsc: 'desc',
      cardId,
    },
  });
  return res;
}

/**
 * 套餐订单详情
 * @param id id
 * @returns
 */
export async function apiGetPackOrderDetail(id: string) {
  var res = await main.api.get(`/client/cardPackOrder/${id}`);

  return res;
}
/**
 * 关闭订单
 * @param id
 * @returns
 */
export async function apiPackOrderTimeout(id: string) {
  var res = await main.api.post(`/client/cardPackOrder/packOrderTimeout`, { id });

  return res;
}

/**
 * 获取充消记录
 * @returns
 */
export async function apiGetIncomeList() {
  var res = await main.api.get('/client/cardDetailWalletLog/list', {
    params: {
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/**
 * 获取套餐订购记录列表
 * @returns
 */
export async function apiGetPackBuyLogList() {
  var res = await main.api.get('/client/cardPackOrderDetail/list', {
    params: {
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });

  return res;
}

/**
 * 创建余额充值订单
 * @param cardId 卡ID
 * @param cardPayDetailId 充值项ID
 * @param appId appId
 * @param openId openId
 * @returns
 */
export async function apiBalanceRecharge(cardId: string, cardPayDetailId: string, appId: string, openId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/create', {
    cardId,
    cardPayDetailId,
    appId,
    openId,
  });

  return res;
}

/**
 * 获取余额充值订单支付参数
 * @param orderId 订单ID
 * @param payType 支付方式
 * @param openId openId
 * @returns
 */
export async function apiGetBalanceRechargePayInfo(orderId: string, payType: string, openId: string) {
  var res = await main.api.post('/client/cardDetailWalletOrder/getPayInfo', {
    orderId: orderId,
    payType: payType,
    openId: openId,
    appId: main.appId,
    // 'payConfigId': payTypeId,
  });

  return res;
}

/**
 * 延迟实名认证
 * @param cardId 卡id
 * @returns
 */
export async function apiUpdateRealTime(cardId: string) {
  var res = await main.api.post('/client/cardDetail/updateRealTime', { cardId: cardId });

  return res;
}
/**
 * 获取 移动、电信、广电 实名链接
 * @param cardId
 * @returns
 */
export async function apiGetRealUrl(cardId: string) {
  var res = await main.api.get('/client/common/getRealUrl', { params: { cardId: cardId } });
  return res;
}
/**
 * 获取换卡记录列表
 * @returns
 */
export async function apiGetCardTransferList() {
  var res = await main.api.get('/client/cardDetailConvert/list', {
    params: {
      orderByColumn: 'createTime',
      isAsc: 'desc',
    },
  });
  return res;
}

/**
 * 获取换卡记录列表 弹窗专用
 * @param id
 * @returns
 */
export async function apiGetSwapCardLogDetail(id?: string) {
  var res = await main.api.get('/client/cardDetailConvert/' + id);
  return res;
}

/**
 * 手机号修改手机号
 * @param oldTel 现有手机号
 * @param captcha 现有手机号验证码
 * @param newTel 新手机号
 * @param newCaptcha 新手机号验证码
 * @returns
 */
export async function apiChangeTelByTel(oldTel?: string, captcha?: string, newTel?: string, newCaptcha?: string) {
  var res = await main.api.post('/client/cardUser/editPhoneNumberByPhoneNumber', {
    phoneNumber: oldTel,
    code: captcha,
    phoneNumberNew: newTel,
    codeNew: newCaptcha,
  });
  return res;
}

/**
 * 操作密码修改手机号
 * @param newTel 新手机号
 * @param newCaptcha 新手机号验证码
 * @param payPassWord 操作密码
 * @returns
 */
export async function apiChangeTelByPassword(newTel?: string, newCaptcha?: string, payPassWord?: string) {
  var res = await main.api.post('/client/cardUser/editPhoneNumberByPayPassWord', {
    payPassWord: payPassWord,
    phoneNumberNew: newTel,
    codeNew: newCaptcha,
  });
  return res;
}

/**
 * 获取工单列表
 * @param tel
 * @returns
 */
export async function apiGetComplaintList(tel?: string) {
  let res = await main.api.get('/client/cardUserWorkOrder/list', {
    params: { phoneNumber: tel, orderByColumn: 'createTime', isAsc: 'desc' },
  });

  return res;
}

/**
 * 获取工单详情
 * @param id
 * @returns
 */
export async function apiGetComplaintDetail(id: string) {
  let res = await main.api.get('/client/cardUserWorkOrder/' + id);

  return res;
}

/**
 * 提交投诉建议
 * @param data
 * @returns
 */
export async function apiCreateComplaint(data: Object) {
  let res = await main.api.post('/client/cardUserWorkOrder', data);

  return res;
}

/**
 * 获取轮播图列表
 * @returns
 */
export async function apiGetCardSliderList() {
  let res = await main.api.get('/client/cardSlideshow/list');
  return res;
}

/**
 * 绑定卡前验证卡号是否是自己要换的卡，或者是别人要换的卡
 * 是否支持绑定卡
 * @param iccid
 * @returns   1 自己要换的卡，2 别人要换的卡 3 走绑卡
 */
export async function apiBindCardBefore(iccid: string) {
  return await main.api.get('/client/cardDetailConvert/bindBefore', { params: { iccid } });
}
