import React from 'react';
import main from '../../main';
interface Props {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: 'primary' | 'default' | 'danger' | 'warning' | 'gray' | 'green';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  long?: boolean;
  shape?: 'circle' | 'round';
  bgPic?: boolean;
  outline?: boolean;
}
export default function Button({
  className,
  onClick,
  children,
  disabled,
  type,
  size,
  shape,
  outline,
  long,
  bgPic,
}: Props) {
  return (
    <div
      className={`${className} button ${type ?? 'default'} ${size ?? 'sm'} ${shape ?? 'round'} ${
        outline ? 'outline' : ''
      } ${long ? 'long' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={!disabled && onClick ? onClick : undefined}
      style={bgPic ? { backgroundImage: `url(${main.pic('pic_btn_bg.png')})`, backgroundSize: '100% 100%' } : undefined}
    >
      {children}
    </div>
  );
}
