import React, { useEffect, useRef, useState } from 'react';
import { AppStore, CurrentCard } from '../../types';
import { CardStatus } from '../../utils/dict';
import dayjs from 'dayjs';
import { delay, formatDateDiff } from '../../utils/utils';
import Slider from 'react-slick';
import main from '../../main';
import { showConfirm, showToast } from '../../components/dialog';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
interface Props {
  card: CurrentCard;
}
export default function HomeTips({ card }: Props) {
  const [tips, setTips] = useState<string[]>([]);
  const timerRef = useRef<any>({});
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);

  useEffect(() => {
    checkTips();
  }, [card]); //eslint-disable-line

  /// 待激活卡倒计时
  function startActiveTimer() {
    if (timerRef.current.timer) return;
    timerRef.current.timer = setInterval(() => {
      if (card?.cardStatus !== CardStatus.waitactivation || !card?.expiredAt) {
        clearInterval(timerRef.current.timer);
        timerRef.current.timer = null;
        return;
      }
      var timeStr = formatDateDiff(dayjs(), dayjs(card?.expiredAt ?? undefined));
      if (timeStr) {
        timeStr += '后未激活将自动解绑';
        setTips(['请订购套餐完成激活~', timeStr]);
      }
    }, 1000);
  }

  async function checkTips() {
    let _tips = [];
    if (card === null || !card?.iccid) {
      _tips = ['请立即绑定卡片~ '];
    } else if (card!.cardStatus === CardStatus.waitactivation) {
      _tips = ['请订购套餐完成激活~'];
    } else if (card!.cardStatus === CardStatus.preRealName) {
      _tips = ['卡号未实名,实名认证后可正常使用~'];
    } else if (card!.cardStatus === CardStatus.activated) {
      _tips = ['每个账单周期内只能订购一次基础包,请按需选择'];
    } else if (card!.cardStatus === CardStatus.stop) {
      _tips = ['卡号已停机,请开机后再使用~'];
    } else if (card!.cardStatus === CardStatus.prestop) {
      _tips = ['卡号长时间未使用,购买套餐后可正常使用~'];
    } else if (card!.cardStatus === CardStatus.unknown) {
      _tips = ['卡号状态未知,请联系客服处理~'];
    } else if (card!.cardStatus === CardStatus.soldout) {
      _tips = ['卡号已销户,请联系客服处理~'];
    } else if (card!.cardStatus === CardStatus.error) {
      _tips = ['异常终端,请联系客服处理~'];
    } else {
      _tips = ['卡号状态未知,请联系客服处理~'];
    }

    if (card?.theMonth && !card?.nextMonth) {
      var packDay = dayjs(card!.theMonth!.endEffectTime!);
      var newDay = dayjs();
      if (newDay.isBefore(packDay) && Math.abs(newDay.diff(packDay, 'd')) <= 6) {
        _tips.push('尽快提前6天订购下期套餐，否则会断网提醒！！！');
        if (!main.packExpiredShowModal[card.iccid!]) {
          main.packExpiredShowModal[card.iccid!] = true;
          await delay(500);
          let res = await showConfirm({
            title: '提示',
            content: '尽快提前6天订购下期套餐，否则会断网提醒！！！',
            hideCancelBtn: true,
            confirmBtnText: '去订购',
          });
          if (res === 1) {
            if (user?.result?.payLimit === 'Y') {
              return showToast('当前账户存在风险，禁止交易');
            }
            navigate('/cardProcess', { state: { step: 2, iccid: card.iccid, monthType: 'nextMonth' } });
          }
        }
      }
    }
    if (card && card.theMonth && (card.theMonth.surplusFlow ?? 0) <= 3 && (card.theMonth.surplusFlow ?? 0) > 0) {
      _tips.push('您本期流量已不足，为避免产生额外扣费，请订购加油包');
      if (!main.flowLack[card.iccid!]) {
        main.flowLack[card.iccid!] = true;
        await delay(500);
        let res = await showConfirm({
          title: '提示',
          content: '您本期流量已不足，为避免产生额外扣费，请订购加油包继续使用，感谢您的理解和支持。',
          hideCancelBtn: true,
          confirmBtnText: '去订购',
        });
        if (res === 1) {
          if (user?.result?.payLimit === 'Y') {
            return showToast('当前账户存在风险，禁止交易');
          }
          navigate('/cardProcess', { state: { step: 2, iccid: card.iccid } });
        }
      }
    }
    if (card && card.theMonth && (card.theMonth.surplusFlow ?? 0) < 0) {
      _tips.push('您本期流量已超出，超出部分将按照1元/G扣费');
      if (!main.flowExceed[card.iccid!]) {
        main.flowExceed[card.iccid!] = true;
        await delay(500);
        let res = await showConfirm({
          title: '提示',
          content: '您本期流量已超出，超出部分将按照1元/G扣费，您也可以补订加油包补正，感谢您的理解和支持。',
          hideCancelBtn: true,
          confirmBtnText: '去订购',
        });
        if (res === 1) {
          if (user?.result?.payLimit === 'Y') {
            return showToast('当前账户存在风险，禁止交易');
          }
          navigate('/cardProcess', { state: { step: 2, iccid: card.iccid } });
        }
      }
    }
    if (card?.cardStatus === CardStatus.stop && !!card!.stopReason) {
      _tips.push(card.stopReason!);
      if (!main.stopDialog[card!.iccid!]) {
        main.stopDialog[card!.iccid!] = true;
        delay(500).then((value) =>
          showConfirm({
            title: '提示',
            content: card!.stopReason!,
            hideCancelBtn: true,
            confirmBtnText: '我知道了',
          })
        );
      }
    }
    if (card?.cardStatus === CardStatus.waitactivation) {
      startActiveTimer();
    } else if (timerRef.current.timer) {
      clearInterval(timerRef.current.timer);
      timerRef.current.timer = null;
    }
    setTips(_tips);
  }
  let __tips = [...tips, ...tips];
  return (
    <div className="bg-color-gray p-5 d-flex-r ai-center border-radius-4 mt-20">
      <img className="h-16 mr-5" src={main.pic('icon_notice.png', true)} alt="" />
      <div className="w-260 h-22">
        <Slider className="h-22 flex-1" autoplay arrows={false} vertical>
          {__tips.map((item, idx) => (
            <div key={item + idx} className="omit-text mt-1">
              {item}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
