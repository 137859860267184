import React from 'react';
import main from '../../main';
interface Props {
  tips?: string;
}
export default function DefaultNoData({ tips }: Props) {
  return (
    <div className="text-center">
      <img className="w-200 h-140" src={main.pic('pic_nodata.png', true)} alt="" />
      <div>{tips ?? '暂无数据'}</div>
    </div>
  );
}
