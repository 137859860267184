import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiLoginWithCard } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import { LoadingMask, showToast } from '../components/dialog';
import Page from '../components/page';
import Radiobox from '../components/radioBox';
import main, { AppLocalKey } from '../main';
import { userInfoRequest } from '../redux/userInfoReducer';
import { APIProtocolType } from '../utils/dict';
import { delay, validateTel } from '../utils/utils';

export default function LoginCardPage() {
  const state: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState('');
  const [username, setUsername] = useState(state.username);
  const [selectProtocol, setSelectProtocol] = useState(false);

  async function handleClick() {
    if (!selectProtocol) {
      showToast('请勾选协议');
      return;
    }
    if (!cardNumber) {
      showToast('请输入卡号');
      return;
    }
    if (!validateTel(username)) {
      showToast('请输入正确的手机号');
      return;
    }
    try {
      LoadingMask.show('正在登录...');
      let res: AxiosResponse<any, any> = await apiLoginWithCard(username, cardNumber);

      let data = res.data;

      if (data.code === 200) {
        let token = data.data.access_token;
        main.loginToken = token;
        localStorage.setItem(AppLocalKey.loginToken, token);
        localStorage.setItem(AppLocalKey.loginUsername, username);
        dispatch(userInfoRequest());
        await delay(1000);
        showToast(data.msg ?? '登录成功');
        navigate('/', { replace: true });
      } else {
        showToast(data.msg || '登录失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '登录失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page
      className="bg-color-white d-flex-c"
      contentClassName="bw-100 flex-1 h-1 d-flex-c ai-center p-32"
      appBar={<AppBar title="" />}
    >
      <div className="flex-1"></div>
      <img className="w-190 h-90" src={main.pic('img_login_logo.png', true)} alt="" />
      <div className="border border-radius-20 bw-100 pv-10 pl-10 d-flex-r ai-center mt-60">
        <input
          className="flex-1 font-size-14"
          value={cardNumber}
          max={11}
          placeholder="请输入卡号"
          onChange={(e) => setCardNumber(e.target.value)}
        />
        {!!cardNumber && (
          <div className="ph-10 d-flex-r ai-center" onClick={() => setCardNumber('')}>
            <img className="w-14" src={main.pic('icon_close_black.png')} alt="" />
          </div>
        )}
      </div>
      <div className="border border-radius-20 bw-100 pv-10 pl-10 d-flex-r ai-center mt-20">
        <input
          className="flex-1 font-size-14"
          value={username}
          max={11}
          placeholder="请输入本人在用手机号"
          onChange={(e) => setUsername(e.target.value)}
        />
        {!!username && (
          <div className="ph-10 d-flex-r ai-center" onClick={() => setUsername('')}>
            <img className="w-14" src={main.pic('icon_close_black.png')} alt="" />
          </div>
        )}
      </div>
      <div className="mt-10 font-size-12 text-color-9">
        输入手机号，开启全新体验！未注册手机号验证后将自动创建账号。
      </div>
      <Button className="mt-64" type="primary" shape="circle" size="md" long onClick={handleClick}>
        登录
      </Button>
      <div className="d-flex-r ai-center font-size-12 mt-20">
        <Radiobox className="mr-5" value={selectProtocol} onChange={(v) => setSelectProtocol(!!v)} />
        <div onClick={() => setSelectProtocol(!selectProtocol)}>已阅读并同意</div>
        <div
          className="text-color-primary"
          onClick={() => navigate('/protocol', { state: APIProtocolType.userAgreement })}
        >
          《用户协议》
        </div>
        <div>和</div>
        <div
          className="text-color-primary"
          onClick={() => navigate('/protocol', { state: APIProtocolType.privacyAgreement })}
        >
          《隐私政策》
        </div>
      </div>
      <img className="w-80 h-24 mt-100 mb-88" src={main.pic('icon_oper_logo.png', true)} alt="" />
      <div className="flex-1"></div>
    </Page>
  );
}
