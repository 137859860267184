import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetCouponList } from '../api';
import { AppStore, Coupon, RecordList } from '../types';

interface RequestParams {
  cardId: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams extends RequestParams {
  results: Array<Coupon>;
}

interface FailureParams extends RequestParams {
  error: string;
}

type CouponMap = { [x: string]: RecordList<Coupon> };

const couponSlice = createSlice({
  name: 'coupon',
  initialState: {} as CouponMap,
  reducers: {
    couponRequest: (state: CouponMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = true;
      return state;
    },
    couponSuccess: (state: CouponMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].results = action.payload.results!;
      return state;
    },
    couponFailure: (state: CouponMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].error = action.payload.error;
      return state;
    },
    couponClear: (state: CouponMap) => {
      state = {};
      return state;
    },
  },
});

export const { couponRequest, couponSuccess, couponFailure, couponClear } = couponSlice.actions;
export default couponSlice.reducer;

export const couponEffect = {
  'coupon/clear': (store: ToolkitStore<AppStore>) => store.dispatch(couponClear()),
  'coupon/couponRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams>) => {
    var couponList = store.getState().coupon[action.payload.cardId];
    if (couponList?.loaded === true && !couponList?.error && !action.payload.forceRefresh) {
      store.dispatch(couponSuccess({ ...action.payload, results: couponList!.results! }));
    } else {
      try {
        let res = await apiGetCouponList(action.payload.cardId);
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(couponSuccess({ ...action.payload, results: data.rows ?? [] }));
        } else {
          store.dispatch(couponFailure({ ...action.payload, error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(couponFailure({ ...action.payload, error: e.toString() || '获取失败' }));
      }
    }
  },
};
