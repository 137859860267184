import React, { useState } from 'react';
import { DialogTitle } from '../../components/dialog';
import Button from '../../components/button';
import { formatNumber } from '../../utils/utils';
interface Props {
  price: number | string;
  onOk: (s: string | number) => void;
}
export default function ValidatePasswordDialog({ price, onOk }: Props) {
  const [value, setValue] = useState('');
  return (
    <div className="vh-40 bg-color-white border-radius-tl-10 border-radius-tr-10 d-flex-c">
      <DialogTitle title="余额支付" onCancel={() => onOk(0)} />
      <div className="ph-20 pb-10 flex-1 d-flex-c">
        <div className="text-color-primary text-center mt-30">
          <span className="font-size-20">{formatNumber(price)}</span>元
        </div>
        <div className="text-color-9 text-center mt-20">请输入操作密码</div>
        <div className="border border-radius-40 p-10 mt-10">
          <input
            className="bw-100"
            type="password"
            inputMode="numeric"
            maxLength={6}
            value={value}
            placeholder="请输入6位操作密码"
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="flex-1"></div>
        <Button type="primary" size="md" shape="circle" long onClick={() => onOk(value)}>
          立即支付
        </Button>
      </div>
    </div>
  );
}
