import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetCardSliderList } from '../api';
import { CardSlider, AppStore, RecordList } from '../types';

interface RequestParams {
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  results: Array<CardSlider>;
}

interface FailureParams {
  error: string;
}

const cardSliderSlice = createSlice({
  name: 'cardSlider',
  initialState: { fetching: false, loaded: false } as RecordList<CardSlider>,
  reducers: {
    cardSliderRequest: (state: RecordList<CardSlider>, action: PayloadAction<RequestParams | undefined>) => {
      state.fetching = true;
      return state;
    },
    cardSliderSuccess: (state: RecordList<CardSlider>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.results!;
      return state;
    },
    cardSliderFailure: (state: RecordList<CardSlider>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    cardSliderClear: (state: RecordList<CardSlider>) => {
      state = { fetching: false, loaded: false };
      return state;
    },
  },
});

export const { cardSliderRequest, cardSliderSuccess, cardSliderFailure, cardSliderClear } = cardSliderSlice.actions;
export default cardSliderSlice.reducer;

export const cardSliderEffect = {
  'cardSlider/clear': (store: ToolkitStore<AppStore>) => store.dispatch(cardSliderClear()),
  'cardSlider/cardSliderRequest': async (
    store: ToolkitStore<AppStore>,
    action: PayloadAction<RequestParams | undefined>
  ) => {
    var cardSliderList = store.getState().cardSlider;
    if (cardSliderList?.loaded === true && !cardSliderList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(cardSliderSuccess({ results: cardSliderList!.results! }));
    } else {
      try {
        let res = await apiGetCardSliderList();
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(cardSliderSuccess({ results: data.rows ?? [] }));
        } else {
          store.dispatch(cardSliderFailure({ error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(cardSliderFailure({ error: e.toString() || '获取失败' }));
      }
    }
  },
};
