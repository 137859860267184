import React from 'react';
import main from '../../main';
interface MenuType {
  label: string;
  value: string;
}
interface Props {
  options: MenuType[];
  value: string;
  onChange: (value: string) => void;
}
export default function PackTabBar({ onChange, options, value }: Props) {
  return (
    <div className="w-375 h-40 pos-r border-radius-tl-20 border-radius-tr-20 d-shadow-t">
      {options[0].value === value ? (
        <img className="pos-a z-10 l-0 t-0 b-0 h-40" src={main.pic('icon_tab_left_active.png', true)} alt="" />
      ) : (
        <img className="pos-a z-0 l-0 t-0 b-0 h-40" src={main.pic('icon_tab_left.png', true)} alt="" />
      )}
      {options[1].value === value ? (
        <img className="pos-a z-10 r-0 t-0 b-0 h-40" src={main.pic('icon_tab_right_active.png', true)} alt="" />
      ) : (
        <img className="pos-a z-0 r-0 t-0 b-0 h-40" src={main.pic('icon_tab_right.png', true)} alt="" />
      )}
      <div className="pos-a z-20 l-0 t-0 r-0 b-0 d-flex-r">
        <div className="flex-1 d-flex-c ai-center" onClick={() => onChange(options[0].value)}>
          <div className="lh-40 font-size-14">{options[0].label}</div>
          <img
            className={`w-30 pos-a b-0 ${options[0].value === value ? '' : 'opacity-0'}`}
            src={main.pic('icon_tab_active.png', true)}
            alt=""
          />
        </div>
        <div className="flex-1 d-flex-c ai-center" onClick={() => onChange(options[1].value)}>
          <div className="lh-40 font-size-14">{options[1].label}</div>
          <img
            className={`w-30 pos-a b-0 ${options[1].value === value ? '' : 'opacity-0'}`}
            src={main.pic('icon_tab_active.png', true)}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
