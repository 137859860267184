import { useEffect } from 'react';
interface Props {
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}
export default function CountCalc({ value, max, min, onChange }: Props) {
  useEffect(() => {
    let _value = value || 0;
    if (_value < min) {
      _value = min;
    }
    if (_value > max) {
      _value = max;
    }
    if (_value !== value) {
      onChange(_value);
    }
  }, [value]); // eslint-disable-line

  function handleChange(v: number) {
    let res = value + v;
    if (res < min) {
      return;
    }
    if (res > max) {
      return;
    }
    onChange(res);
  }
  return (
    <div className="d-flex-r ai-center">
      <div
        className={`border-left text-center w-30 border-top border-bottom border-radius-tl-4 border-radius-bl-4 h-24 lh-24 font-size-20 ${
          (!!min || min === 0) && value <= min ? 'text-color-9 border-color-gray' : 'border-color-3'
        }`}
        onClick={() => handleChange(-1)}
      >
        -
      </div>
      <div className="border text-center w-30 h-24 lh-24 font-size-14 border-color-3">{value}</div>
      <div
        className={`border-right text-center w-30 border-top border-bottom border-radius-tr-4 border-radius-br-4 h-24 lh-24 font-size-20 ${
          (!!max || max === 0) && value >= max ? 'text-color-9 border-color-gray' : 'border-color-3'
        }`}
        onClick={() => handleChange(1)}
      >
        +
      </div>
    </div>
  );
}
