import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '../components/appBar';
import { showConfirm } from '../components/dialog';
import Page from '../components/page';
import { availableCouponClear } from '../redux/availableCouponReducer';
import { buyPackLogRequest } from '../redux/buyPackLogReducer';
import { cardRequest } from '../redux/cardReducer';
import { couponRequest } from '../redux/couponReducer';
import { currentCardRequest } from '../redux/currentCardReducer';
import { incomeRequest } from '../redux/incomeReducer';
import { orderRequest } from '../redux/orderReducer';
import { AppStore } from '../types';
export default function IFramePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currCard = useSelector((state: AppStore) => state.currentCard);
  // url 跳转的外部链接 title页面标题 pay 是否支付跳转 back活动页面和充值页面完成后再后退
  const { url, title, pay, back } = location.state;

  useEffect(() => {
    dispatch(currentCardRequest());
  }, []); // eslint-disable-line

  async function handlePayBack() {
    navigate(-2);
    await showConfirm({
      title: '提示',
      content: '是否支付完成?',
      confirmBtnText: '支付完成',
      cancelBtnText: '未完成',
    });
    dispatch(cardRequest({ forceRefresh: true }));
    dispatch(incomeRequest({ forceRefresh: true }));
    dispatch(buyPackLogRequest({ forceRefresh: true }));
    dispatch(currentCardRequest({ forceRefresh: true }));
    dispatch(orderRequest({ forceRefresh: true, cardId: currCard?.result?.id ?? '' }));
    dispatch(couponRequest({ forceRefresh: true, cardId: currCard?.result?.id ?? '' }));
    dispatch(availableCouponClear());
    if (back) {
      navigate(-2);
    } else {
      navigate('/pack-order-list', { replace: true });
    }
  }
  return (
    <Page
      className="d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={<AppBar title={title ?? '支付结果'} onBack={pay ? handlePayBack : undefined} />}
    >
      <iframe className="flex-1 border-none" title={title ?? '支付结果'} src={url} />
    </Page>
  );
}
