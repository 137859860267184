import { useEffect, useState } from 'react';
import { apiGetWeChatSignature } from '../../api';
import { DialogTitle } from '../../components/dialog';
import main from '../../main';

interface ScanCardResult {
  /** 扫码结果 0 取消 1 成功 2 未准备 3 版本不支持 */
  res: 0 | 1 | 2 | 3;
  msg: string;
}
interface Props {
  title?: string;
  onOk: (res: ScanCardResult) => void;
}
export default function ScanCardDialog({ onOk, title }: Props) {
  const [niccid, setNiccid] = useState('');

  // 1可以使用  2 未准备 3 客户端不支持
  const [wxReady, setWxReady] = useState(2);
  useEffect(() => {
    wxConfig();
  }, []);

  async function wxConfig() {
    // @ts-ignore
    let wx = window.wx;
    let res = await apiGetWeChatSignature(
      main.isIos14 ? main.enterUrl : window.location.href.split('#')[0],
      main.appId
    );
    let data = res.data;

    if (data.code === 200) {
      let _data = data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'scanQRCode'], // 必填，需要使用的JS接口列表
      });
    }

    wx.ready(() => {
      wx.checkJsApi({
        //判断当前客户端版本是否支持指定JS接口
        jsApiList: ['scanQRCode'],
        success: function (res: any) {
          // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
          if (res.checkResult.scanQRCode !== true) {
            setWxReady(3);
          } else {
            setWxReady(1);
          }
        },
      });
    });
  }

  function handleScan() {
    if (wxReady === 2) {
      onOk({ res: 2, msg: '微信相机正在准备，请稍后再试' });
    } else if (wxReady === 3) {
      onOk({ res: 3, msg: '抱歉，当前客户端版本不支持扫一扫' });
    }
    // @ts-ignore
    let wx = window.wx;
    // 调起企业微信扫一扫接口
    wx.scanQRCode({
      desc: 'scanQRCode desc',
      needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
      scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res: any) {
        var result: string = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        if (result.includes(',')) {
          result = result.split(',')[1];
        }
        setNiccid(result);
      },
      error: function (res: any) {
        if (res.errMsg.indexOf('function_not_exist') > 0) {
          onOk({ res: 3, msg: '微信版本过低请升级' });
        }
      },
    }); //wx.scanQRcode结束
  }

  function handleConfirm() {
    if (wxReady === 2) {
      onOk({ res: 2, msg: '微信相机正在准备，请稍后再试' });
    } else if (wxReady === 3) {
      onOk({ res: 3, msg: '抱歉，当前客户端版本不支持扫一扫' });
    } else if (!niccid) {
      onOk({ res: 0, msg: '取消' });
    } else {
      onOk({ res: 1, msg: niccid });
    }
  }

  return (
    <div className=" d-flex-c ai-center  overflow-hidden">
      <div className="w-280 bg-color-white border-radius-8">
        <DialogTitle title={title} onCancel={() => onOk({ res: 0, msg: '取消' })} />
        <div className="p-20">
          <div className="mb-10">转卡后老卡数据将会转移到新卡，老卡将彻底作废，确定转卡吗？</div>
          <div className="d-flex-r border border-radius-20 p-10" onClick={handleScan}>
            <input className="flex-1 mr-10" readOnly placeholder="扫描识别卡片背面条形码" type="text" value={niccid} />
            <img className="icon-16" src={main.pic('icon_scan_xs.png')} alt="" />
          </div>
        </div>
        <div className="d-flex-r border-top bw-100">
          <div className="flex-1 p-10 text-center border-right" onClick={() => onOk({ res: 0, msg: '取消' })}>
            取消
          </div>
          <div className="flex-1 p-10 text-center text-color-primary" onClick={handleConfirm}>
            确定
          </div>
        </div>
      </div>
    </div>
  );
}
