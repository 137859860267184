import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '../components/appBar';
import Button from '../components/button';
import Page from '../components/page';
import main, { AppLocalKey } from '../main';
import { addressRequest } from '../redux/addressReducer';
import { Address, AppStore } from '../types';
import { dataListStatus } from '../utils/utils';

export default function AddressListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 是否是选择地址
  const { isSelect } = useLocation().state ?? {};
  const user = useSelector((state: AppStore) => state.user);
  const addressList = useSelector((state: AppStore) => state.address);

  useEffect(() => {
    if (user.result?.userId) {
      dispatch(addressRequest());
    }
  }, [user]); // eslint-disable-line

  function handleSelect(item: Address) {
    let addressJson = JSON.stringify(item);
    localStorage.setItem(AppLocalKey.selectAddress, addressJson);
    navigate(-1);
  }

  function renderItem(item: Address, index: number) {
    return (
      <div key={item.id! + index} className="bg-color-white mb-10 p-10 border-radius-6">
        <div className="d-flex-r ai-center pb-10 border-bottom">
          <img className="icon-36 mr-10" src={main.pic('icon_card_status_active.png', true)} alt="" />
          <div className="flex-1">
            <div className="d-flex-r ai-center">
              <div className="font-size-14 font-widget-600 mr-10">{item.receiverName}</div>
              <span className="font-size-12 text-color-9 mr-10">{item.receiverPhone}</span>
              {item.status === 'Y' && (
                <span className="font-size-10 text-color-white pv-2 ph-10 border-radius-10 bg-color-red mr-10">
                  默认
                </span>
              )}
            </div>
            <div className="mt-10 text-color-9">
              <span>{item.provinceName}</span>
              <span>{item.cityName}</span>
              <span>{item.countyName}</span>
              <span>{item.receiverAddress}</span>
            </div>
          </div>
        </div>
        <div className="pt-10 d-flex-r ai-center">
          <div className="flex-1"></div>
          {isSelect ? (
            <Button type="primary" shape="circle" size="xs" onClick={() => handleSelect(item)}>
              <div className="w-60">选择</div>
            </Button>
          ) : (
            <Button type="primary" shape="circle" size="xs" onClick={() => navigate('/addressEdit', { state: item })}>
              <div className="w-60">编辑</div>
            </Button>
          )}
        </div>
      </div>
    );
  }

  let el = dataListStatus({ list: addressList });
  if (el) {
    return (
      <Page
        className="d-flex-c bg-color-gray"
        contentClassName="flex-1 pt-100 d-flex-c ai-center"
        appBar={<AppBar title="地址管理" className="bg-color-gray" />}
      >
        {el}
        <div className="pt-40">
          <Button type="primary" shape="circle" size="sm" onClick={() => navigate('/addressEdit')}>
            <div className="w-80">添加地址</div>
          </Button>
        </div>
      </Page>
    );
  }
  let list = addressList.results!;
  return (
    <Page
      className="bg-color-gray d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c p-20"
      appBar={<AppBar title="地址管理" className="bg-color-gray" />}
    >
      <div className="flex-1 h-1 overflow-y mt-10 border-radius-8">
        {list.map((item, idx) => renderItem(item, idx))}
      </div>
      <div className="pt-20">
        <Button type="primary" shape="circle" size="md" long onClick={() => navigate('/addressEdit')}>
          添加地址
        </Button>
      </div>
    </Page>
  );
}
