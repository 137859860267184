import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetAllCardInfo, apiGetUserInfo } from '../api';
import { AppStore, DetailRecord, User } from '../types';
import { systemConfigRequest } from './systemConfigReducer';
import { currentCardRequest } from './currentCardReducer';
import main from '../main';
import { customerUrlRequest } from './customerUrlReducer';
import { payTypeConfigRequest } from './payTypeConfigReducer';
import { cardSliderRequest } from './cardSliderReducer';

// 请求成功时自动传送的参数
interface SuccessParams {
  record: User;
}

interface FailureParams {
  error: string;
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: { fetching: false, loaded: false } as DetailRecord<User>,
  reducers: {
    userInfoRequest: (state: DetailRecord<User>, action: PayloadAction) => {
      state.fetching = true;
      return state;
    },
    userInfoSuccess: (state: DetailRecord<User>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.result = action.payload.record!;
      return state;
    },
    userInfoFailure: (state: DetailRecord<User>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    userInfoClear: (state: DetailRecord<User>) => {
      state = { fetching: false, loaded: false } as DetailRecord<User>;
      return state;
    },
  },
});

export const { userInfoRequest, userInfoSuccess, userInfoFailure, userInfoClear } = userInfoSlice.actions;
export default userInfoSlice.reducer;

export const userInfoEffect = {
  'userInfo/clear': (store: ToolkitStore<AppStore>) => store.dispatch(userInfoClear()),
  'userInfo/userInfoRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction) => {
    try {
      let res = await apiGetUserInfo();
      let data = res.data;
      if (data.code === 200) {
        let allCardInfo = await apiGetAllCardInfo();
        let allCardInfoData = allCardInfo.data;
        if (allCardInfoData.code === 200) {
          main.bindCardNum = allCardInfoData.data;
        } else {
          main.bindCardNum = 0;
        }
        main.bindCardNumLoaded = true;

        store.dispatch(systemConfigRequest());
        store.dispatch(currentCardRequest());
        store.dispatch(customerUrlRequest());
        store.dispatch(payTypeConfigRequest());
        store.dispatch(cardSliderRequest());
        store.dispatch(userInfoSuccess({ record: data.data.user }));
      } else {
        store.dispatch(userInfoFailure({ error: data.msg || '获取失败' }));
      }
    } catch (e: any) {
      store.dispatch(userInfoFailure({ error: e.toString() || '获取失败' }));
    }
  },
};
