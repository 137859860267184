import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import DefaultNoData from '../../components/defaultPage/defaultNoData';
import { showBottom } from '../../components/dialog';
import Radiobox from '../../components/radioBox';
import main from '../../main';
import { availableCouponRequest, createAvailableCouponKey } from '../../redux/availableCouponReducer';
import { AppStore, Coupon } from '../../types';
import { CouponExpiredType, CouponType } from '../../utils/dict';
import { formatNumber, modelParsePrice } from '../../utils/utils';
interface Props {
  cardId: string;
  packDetailId: string;
  number: number;
  values: string[];
  className?: string;
  onChange: (values: string[]) => void;
}
export default function CouponSelect({ className, cardId, packDetailId, number, values, onChange }: Props) {
  const [select, setSelect] = useState<string[]>(values ?? []);
  const dispatch = useDispatch();
  const availableCouponList = useSelector(
    (state: AppStore) =>
      state.availableCoupon[
        createAvailableCouponKey({
          cardId,
          packDetailId,
          number,
        })
      ]
  );

  useEffect(() => {
    dispatch(availableCouponRequest({ cardId, packDetailId, number }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (availableCouponList?.results?.length && !select?.length) {
      setSelect([availableCouponList?.results[0].id!]);
      onChange([availableCouponList?.results[0].id!]);
    }
  }, [availableCouponList]); // eslint-disable-line

  async function handleSelectCoupon() {
    let res = await showBottom({
      maskHide: true,
      buildContent: (onOk) => (
        <CouponSelectDialog values={select} coupons={availableCouponList?.results ?? []} onOk={onOk} />
      ),
    });
    if (!res) return;
    setSelect(res);
    onChange(res);
  }

  let couponTips = '';
  if (!availableCouponList?.results?.length) {
    couponTips = '暂无可用优惠券';
  } else if (!select?.length) {
    couponTips = '有可用优惠券';
  } else if (select.length === 1) {
    couponTips = availableCouponList?.results[0].couponName!;
  } else if (select.length > 1) {
    let v = 0;
    availableCouponList!.results!.forEach((i) => {
      if (select.includes(i.id!)) {
        v += modelParsePrice(i.reduceAmount!);
      }
    });
    couponTips = `组合优惠 - ${v}`;
  }

  return (
    <div className={`d-flex-r ai-center pv-10 ${className}`} onClick={handleSelectCoupon}>
      <div className="flex-1">选择优惠券</div>
      <div className="text-color-primary mr-10">{couponTips}</div>
      <img className="h-12" src={main.pic('icon_right_xs.png')} alt="" />
    </div>
  );
}
interface CouponSelectDialogProps {
  values: string[];
  coupons: Coupon[];
  onOk: (values?: string[] | number) => void;
}
function CouponSelectDialog({ values, coupons, onOk }: CouponSelectDialogProps) {
  const [selected, setSelected] = useState<string[]>(values);

  function handleClick(coupon: Coupon) {
    // 如果新选了叠加，就把之前的不是叠加的全去掉、
    // 如果新选了不叠加，就只保留不叠加的
    let _tmp: string[] = [];

    // 先处理不叠加的 只保留最新的
    if (coupon.isOverlay !== 'Y') {
      if (selected.includes(coupon.id!)) {
        _tmp = [];
      } else {
        _tmp = [coupon.id!];
      }
    } else {
      // 再处理叠加的
      for (var item of coupons) {
        // 如果是已选中的优惠券 并且是叠加的，则还放到选中组
        if (selected.includes(item.id!) && item.isOverlay === 'Y') {
          _tmp.push(item.id!);
        }
      }
      if (!_tmp.includes(coupon.id!)) {
        // 如果自己不在
        // 保留完所有叠加的之后再把 自己放进去
        _tmp.push(coupon.id!);
      } else {
        // 如果自己在
        // 就把自己从选中组去掉
        _tmp.splice(_tmp.indexOf(coupon.id!), 1);
      }
    }
    setSelected(_tmp);
  }

  return (
    <div className="bg-color-white border-radius-tl-10 border-radius-tr-10 vh-50 d-flex-c">
      <div className="p-10 font-size-14 text-center">选择优惠券</div>
      {coupons.length <= 0 ? (
        <div className="mt-30">
          <DefaultNoData tips="暂无可用优惠券" />
        </div>
      ) : (
        <div className="flex-1 overflow-y">
          {coupons.map((item) => {
            let amountType: string = item.couponType === CouponType.flow ? 'G' : '元';
            let amount: string = (
              item.couponType === CouponType.flow ? item.addFlow ?? '' : formatNumber(item.reduceAmount ?? 0)
            ).toString();

            let expiredStr: string =
              item.expireType !== CouponExpiredType.instant
                ? `${dayjs(item.validEndTime!).format('YYYY/MM/DD')}失效`
                : `购买后${item.validDays}天内可用`;
            let _isOverlay = item.isOverlay === 'Y' ? '可叠加' : '不可叠加';
            return (
              <div
                key={item.id}
                className="d-flex-r ai-center pv-10 ph-20 border-bottom"
                onClick={() => handleClick(item)}
              >
                <div className="flex-1">
                  <div className="font-size-14">
                    {item.couponName}-{amount}
                    {amountType}
                  </div>
                  <div className="text-color-9 mt-3">{expiredStr}</div>
                </div>
                <div className="mr-10">{_isOverlay}</div>
                <Radiobox value={selected.includes(item.id!)} />
              </div>
            );
          })}
        </div>
      )}
      {coupons.length > 0 && (
        <div className="ph-20 pv-10">
          <Button type="primary" size="md" shape="circle" long onClick={() => onOk(selected ?? [])}>
            确定
          </Button>
        </div>
      )}
    </div>
  );
}
