import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, PayDeduction } from '../../types';
import { PayType } from '../../utils/dict';
import { payDeductionRequest } from '../../redux/payDeductionReducer';
import main from '../../main';
import Radiobox from '../../components/radioBox';
interface Props {
  cardId: string;
  balance?: number;
  useBalance?: boolean;
  value?: string;
  className?: string;
  onChange: (value: string) => void;
}
export default function PayTypeSelect({ className, useBalance, value, balance, onChange, cardId }: Props) {
  const dispatch = useDispatch();
  const payTypeList = useSelector((state: AppStore) => state.payTypeConfig);
  const payDeduction = useSelector(
    (state: AppStore) => state.payDeduction?.[cardId] ?? ({} as { [x: string]: PayDeduction })
  );
  useEffect(() => {
    dispatch(payDeductionRequest({ cardId }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (payTypeList.results?.length) {
      if (!useBalance) {
        onChange(payTypeList.results!.find((i) => i.payType !== PayType.balance)?.payType!);
      } else {
        onChange(payTypeList.results[0].payType!);
      }
    }
  }, [payTypeList]); // eslint-disable-line

  if (!payTypeList?.results?.length) {
    return <div></div>;
  }
  let list = payTypeList.results!;
  if (!useBalance) {
    list = list.filter((item) => item.payType !== PayType.balance);
  }
  return (
    <div className={className}>
      {list.map((i) => {
        let subLabel = i.payType === PayType.balance ? `(￥${balance})` : '';
        let reduce = payDeduction[i.payType!];
        if (reduce && i.payType !== PayType.balance) {
          if (reduce.reduction === 'all' || (reduce.reduction === 'first' && reduce.payCount <= 0)) {
            subLabel = `最高优惠${reduce.reductionAmount}元`;
          }
        }

        return (
          <div key={i.id!} className="d-flex-r ai-center pt-14" onClick={() => onChange(i.payType!)}>
            <img className="w-24 h-24 mr-10" src={main.pic(PayType.typeIcon(i.payType))} alt="" />
            <div className="font-size-14">{PayType.typeStr(i.payType)}</div>
            <div className="flex-1 text-color-9">{subLabel}</div>
            <Radiobox value={value === i.payType} />
          </div>
        );
      })}
    </div>
  );
}
