import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiBalanceRecharge, apiGetBalanceRechargePayInfo } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import { LoadingMask, showToast } from '../components/dialog';
import Page from '../components/page';
import main from '../main';
import { cardRequest } from '../redux/cardReducer';
import { chargeDirectRequest } from '../redux/chargeDirectReducer';
import { couponRequest } from '../redux/couponReducer';
import { currentCardRequest } from '../redux/currentCardReducer';
import { incomeRequest } from '../redux/incomeReducer';
import { AppStore, Card, ChargeDirect } from '../types';
import { PayType } from '../utils/dict';
import showPayPage from '../utils/pay';
import { dataListStatus, formatNumber, parseLocationParams } from '../utils/utils';
import { ChargeDirectSelect } from './components/chargePremoney';
import PayTypeSelect from './components/payTypeSelect';

export default function ChargePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { canBack } = location.state ?? {};
  const user = useSelector((state: AppStore) => state.user);
  const currCard = useSelector((state: AppStore) => state.currentCard);
  const [card, setCard] = useState<Card>();
  const cardList = useSelector((state: AppStore) => state.card);

  const chargeDirectList = useSelector((state: AppStore) => state.chargeDirect[card?.id ?? '']);

  const [preMoney, setPreMoney] = useState<ChargeDirect>();
  const [payType, setPayType] = useState('');
  const [wxReady, setWxReady] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => setWxReady(true), false);
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => setWxReady(true));
      }
    } else {
      setWxReady(true);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (user.result?.userId) {
      dispatch(currentCardRequest());
      dispatch(cardRequest());
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (card?.id) {
      dispatch(chargeDirectRequest({ cardId: card?.id! }));
    }
  }, [card]); // eslint-disable-line

  useEffect(() => {
    if (currCard.loaded && cardList?.loaded) {
      let _id = parseLocationParams(location.search)?.id ?? currCard.result?.id;
      let _card = cardList.results?.find((i) => i.id === _id);
      if (!!_card) {
        setCard(_card);
      } else {
        showToast('未找到可用卡号');
        if (!!canBack) {
          navigate(-1);
        } else {
          navigate('/');
        }
      }
    }
  }, [cardList, currCard]); // eslint-disable-line

  async function handlePay() {
    // main.openId = 'oVLsF6Q1Vr1wxaPk18nS7VafSg2w';
    if (!main.openId) {
      return showToast('授权失败,请在微信内打开');
    }
    if (!currCard.result?.id) {
      return showToast('请选择卡号');
    }
    if (!payType) {
      return showToast('请选择支付方式');
    }
    if (!preMoney) {
      return showToast('请选择充值金额');
    }

    try {
      LoadingMask.show('正在充值...');
      var res = await apiBalanceRecharge(card?.id!, preMoney!.payDetailId!, main.appId, main.openId!);
      let data = res.data;
      if (data.code === 200) {
        let _data = data.data;
        let orderId = _data.orderId;
        let payInfo = await apiGetBalanceRechargePayInfo(orderId, payType, main.openId!);
        let payInfoData = payInfo.data;
        if (payInfoData.code === 200) {
          let payResData = payInfoData.data;
          if (payType === PayType.hsmWxJsPay || payType === PayType.aliPage) {
            //  惠收米 收银台支付 第一章
            let payRes = await showPayPage(payResData, payType);

            if (payRes.code === 300) {
              if (payRes.url) {
                LoadingMask.hide();
                window.location.href = payRes.url;
              }
              showToast(payRes.msg);
            } else if (payRes.code === 500) {
              showToast(payRes.msg);
            } else {
              showToast('数据还在准备,请稍后重试');
            }
          } else if (wxReady) {
            let payRes = await showPayPage(payResData, payType);
            if (payRes.code === 200) {
              navigate(-1);
              showToast('支付成功');
              dispatch(cardRequest({ forceRefresh: true }));
              if (card!.id === currCard.result?.id) {
                dispatch(currentCardRequest({ forceRefresh: true }));
              }
              dispatch(couponRequest({ cardId: card!.id!, forceRefresh: true }));
              dispatch(incomeRequest({ forceRefresh: true }));
            } else if (payRes.code === 300) {
              if (payRes.url) {
                LoadingMask.hide();
                navigate('/iframe', { state: { url: payRes.url, title: '银联支付', pay: true, back: true } });
              }
            } else {
              showToast('支付失败');
            }
          } else {
            showToast('数据还在准备,请稍后重试');
          }
        } else {
          showToast(payInfoData.msg ?? '支付失败');
        }
      } else {
        showToast(data.msg ?? '支付失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '支付失败');
    } finally {
      LoadingMask.hide();
    }
  }

  let el = dataListStatus({ list: chargeDirectList });

  return (
    <div className="pos-r vw-100 vh-100">
      <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
      <Page
        className="pos-r d-flex-c"
        contentClassName="flex-1 h-1 d-flex-c bg-color-white overflow-hidden"
        appBar={<AppBar className="bg-color-t0" white title="充值中心" />}
      >
        {!!el ? (
          <div className="mt-50">{el}</div>
        ) : (
          <div className="flex-1 h-1 overflow-y p-10">
            <div className="d-flex-r ai-center border-bottom pb-10 mb-10">
              <div className="flex-1">
                <div className="text-color-9">卡号</div>
                <div className="font-size-18">{card?.iccid}</div>
              </div>
              <div>
                余额：
                <span className="font-size-14 text-color-origin">{formatNumber(card?.userTotalBalance ?? 0)}</span>元
              </div>
            </div>
            <div className="pv-10 text-color-9">选择充值面额</div>
            <ChargeDirectSelect cardId={card!.id!} value={preMoney?.payDetailId} onChange={(v) => setPreMoney(v)} />
            <div className="pv-10 text-color-9">选择支付方式</div>
            <PayTypeSelect cardId={card!.id!} useBalance={false} value={payType} onChange={(v) => setPayType(v)} />
          </div>
        )}
        {!el && (
          <div className="p-10">
            <Button type="primary" size="md" shape="circle" long onClick={handlePay}>
              立即支付(￥{preMoney?.amount ?? preMoney?.price ?? 0})
            </Button>
          </div>
        )}
      </Page>
    </div>
  );
}
