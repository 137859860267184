import { PayloadAction, createSlice, Action } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { protocolRequest } from './protocolReducer';
import { APIProtocolType } from '../utils/dict';
import { AppStore } from '../types';
import { loginCustomerUrlRequest } from './loginCustomerUrlReducer';

const startUpSlice = createSlice({
  name: 'startUp',
  initialState: 'noRequest',
  reducers: {
    startUp: (state: string, action: PayloadAction<string>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { startUp } = startUpSlice.actions;
export default startUpSlice.reducer;

export const startUpEffect = {
  'startUp/startUp': async (store: ToolkitStore<AppStore>, action: Action) => {
    store.dispatch(protocolRequest({ type: APIProtocolType.userManual }));
    store.dispatch(protocolRequest({ type: APIProtocolType.userAgreement }));
    store.dispatch(protocolRequest({ type: APIProtocolType.privacyAgreement }));
    store.dispatch(protocolRequest({ type: APIProtocolType.destroyAccount }));
    store.dispatch(loginCustomerUrlRequest());
  },
};
