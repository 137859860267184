import React, { useEffect } from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../types';
import { cardTransferRequest } from '../redux/cardTransferReducer';
import { dataListStatus } from '../utils/utils';
import main from '../main';
import { CardTransferStatus } from '../utils/dict';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

export default function CardTransferPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: AppStore) => state.user);
  const transferList = useSelector((state: AppStore) => state.cardTransfer);

  useEffect(() => {
    if (user.result?.userId) {
      dispatch(cardTransferRequest());
    }
  }, [user]); // eslint-disable-line

  let el = dataListStatus({ list: transferList });
  if (el) {
    return (
      <Page
        className="bg-color-gray d-flex-c"
        contentClassName="flex-1 h-1 d-flex-c pt-50"
        appBar={<AppBar title="换卡记录" className="bg-color-gray" />}
      >
        {el}
      </Page>
    );
  }

  let list = transferList.results!;
  return (
    <Page
      className="bg-color-gray d-flex-c"
      contentClassName="flex-1 h-1 overflow-y pv-10"
      appBar={<AppBar title="换卡记录" className="bg-color-gray" />}
    >
      {list.map((i, idx) => (
        <div
          key={i.id! + idx}
          className="mh-10 mt-10 bg-color-white border-radius-8 p-10"
          onClick={() => navigate('/cardTransfer/' + i.id, { state: { canBack: true } })}
        >
          <div className="d-flex-r ai-center">
            <div className="w-18 h-18 lh-20 text-center border-radius-10 bg-color-gray text-color-9">旧</div>
            <div className="flex-1 font-size-14 mh-10">{i.iccid}</div>
            <img className="h-16 mr-10" src={main.pic(CardTransferStatus.getTypeIcon(i.status))} alt="" />
            <div className={`text-color-${CardTransferStatus.getTypeColor(i.status)}`}>
              {CardTransferStatus.getTypeStr(i.status)}
            </div>
          </div>
          <div className="d-flex-r ai-center mt-10">
            <div className="w-18 h-18 lh-20 text-center border-radius-10 bg-color-primary text-color-white">新</div>
            <div className="flex-1 font-size-14 mh-10">{i.newIccid ?? '暂无'}</div>
            <div className="text-color-6">{dayjs(i.createTime).format('YYYY/MM/DD HH:mm')}</div>
          </div>
        </div>
      ))}
    </Page>
  );
}
