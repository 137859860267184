import React, { useEffect } from 'react';
import { AppStore, CurrentCard, SystemConfig } from '../../types';
import { useSelector } from 'react-redux';
import { CardStatus, CardTagType } from '../../utils/dict';
import dayjs from 'dayjs';
import main, { AppLocalKey } from '../../main';
import { showConfirm } from '../../components/dialog';
import { useNavigate } from 'react-router-dom';
interface Props {
  card: CurrentCard;
}
/**
 *
 * 循环系统配置列表，如果configValue有值 且configKey被包含在卡的标签列表内，提示信息为configValue，
 * 如果没有提示信息，则判断固定的几个值，如 [死卡],[亏损换同资费]
 * 如果匹配到的系统配置为 [充值活动]
 * - 如果卡状态是[已激活]或[已停机]，
 * - - 如果有备注(备注里设置的是时间)，如果时间格式正确且当前时间又在时间范围内，show = true;
 * - - 如果没有备注，则始终show = true;
 * - - 如果show = true 且没有跳转过，则弹窗让用户跳转到充值活动页面
 * 如果不是[充值活动]，则判断是否[换卡]，并弹窗显示提示信息，并跳转到换卡或客服页面
 * 否则弹窗提示信息并跳转客服页面
 */
export default function HomeAutoDialog({ card }: Props) {
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);
  const configList = useSelector((state: AppStore) => state.systemConfig);
  useEffect(() => {
    if (configList.results?.length) {
      checkTag();
    }
  }, [card, configList]); // eslint-disable-line

  async function checkTag() {
    if (!card.tagNameList?.length) return;
    const list = configList!.results!;

    let config: SystemConfig | undefined;
    let tips = '';
    for (var cfg of list) {
      if (cfg.configValue && card!.tagNameList!.includes(cfg!.configKey!)) {
        tips = cfg.configValue!;
        config = cfg;
      }
    }
    let title = config?.configKey;
    if (!tips) {
      if (card!.tagNameList!.includes(CardTagType.died)) {
        title = '死卡';
        // 死卡
        tips =
          '您好，因为运营商后台系统异常，您的卡片技术人员确认无法进行复，可联系客服免费给您发一张新卡，老卡数将全部转到新卡上，新卡仅需完成实名即可用网';
      } else if (card!.tagNameList!.includes(CardTagType.changeProduct)) {
        title = '亏损换同资费';
        // 亏损换同资费
        tips =
          '您好，因运营商后台系统异常，您的卡片无法恢复，可联系客服免费给您发一张新卡，老卡数据将全部转到新卡上，新卡仅需完成实名即可用网';
      } else if (card!.tagNameList!.includes(CardTagType.chargeCard)) {
        title = '亏损换号卡';
        // 亏损换号卡
        tips =
          '您好，因运营商后台系统异常，您的卡片无法恢复，运营商为了弥补您，给您另外发一张手机卡带通话、带短信、可以注册app';
      } else if (card!.tagNameList!.includes(CardTagType.update)) {
        title = '系统升级';
        // 系统升级
        tips =
          '运营商系统升级更新中，预计2个小时内恢复，届时请重启手机用网，在此期间一定不要动卡槽，给您带来的不便深表抱歉。';
      }
    }
    if (config?.configKey === CardTagType.rechargeActivity) {
      if (card?.cardStatus === CardStatus.activated || card?.cardStatus === CardStatus.stop) {
        let show = false;
        // 备注要求填写时间  格式 2023-01-01/2023-02-01 不填写必定弹窗
        if (config.remark) {
          let reg: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}\/[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
          if (reg.test(config.remark)) {
            let start = dayjs(config.remark!.split('/')[0] + ' 00:00:00');
            let end = dayjs(config.remark!.split('/')[1] + ' 23:59:59');
            let curr = dayjs();
            if (start.isBefore(curr) && curr.isBefore(end)) {
              show = true;
            }
          }
        } else {
          show = true;
        }
        if (!main.jumpToCharge[card.iccid!] && show) {
          main.jumpToCharge[card.iccid!] = true;
          let res = await showConfirm({ title: '充值活动', content: tips });
          if (res) {
            navigate(`/charge?id=${card.id}`, { state: { canBack: true } });
          }
        }
      }
    } else if (config?.remark?.includes('[换卡]')) {
      if (!main.jumpToTransfer[card.iccid!]) {
        main.jumpToTransfer[card.iccid!] = true;
        let toSwapCard = false;
        let toCustomer = false;
        if (config!.remark?.includes('[换卡]|')) {
          let dialog = await showConfirm({
            title: '提示',
            content: tips,
            cancelBtnText: '换卡',
            confirmBtnText: '联系客服',
          });
          if (dialog === 1) {
            toCustomer = true;
          } else {
            toSwapCard = true;
          }
        } else if (config!.remark?.includes('|[换卡]')) {
          let dialog = await showConfirm({
            title: '提示',
            content: tips,
            cancelBtnText: '联系客服',
            confirmBtnText: '换卡',
          });
          if (dialog === 1) {
            toSwapCard = true;
          } else {
            toCustomer = true;
          }
        } else {
          let dialog = await showConfirm({
            title: '提示',
            content: tips,
            hideCancelBtn: true,
            confirmBtnText: '换卡',
          });
          if (dialog === 1) {
            toSwapCard = true;
          }
        }
        if (toSwapCard) {
          navigate('/applySwitchCard', { state: { cardId: card.id, iccid: card.iccid } });
        } else if (toCustomer) {
          navigate('/customer', { state: { tel: user.result?.phonenumber, from: '打标-换卡' } });
        }
      }
    } else if (config?.configKey === AppLocalKey.renewal) {
      if (!main.tagDiaowShow[card.iccid! + config.configKey]) {
        main.tagDiaowShow[card.iccid! + config.configKey] = true;
        await showConfirm({
          title: '提示',
          hideCancelBtn: true,
          content: <div className="text-left ph-10">{tips}</div>,
          confirmBtnText: '我已知悉',
        });
      }
    } else if (tips.trim()) {
      if (!main.tagDiaowShow[card.iccid! + (config?.configKey ?? title)]) {
        main.tagDiaowShow[card.iccid! + (config?.configKey ?? title)] = true;
        await showConfirm({
          title: '提示',
          hideCancelBtn: true,
          content: tips,
          confirmBtnText: '联系客服',
        });
        navigate('/customer', { state: { tel: user.result?.phonenumber, from: '打标-' + title } });
      }
    }
  }

  return <div></div>;
}
