import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetIncomeList } from '../api';
import { AppStore, Income, RecordList } from '../types';

interface RequestParams {
  forceRefresh?: boolean;
}
// 请求成功时自动传送的参数
interface SuccessParams {
  results: Array<Income>;
}

interface FailureParams {
  error: string;
}

const incomeSlice = createSlice({
  name: 'income',
  initialState: { fetching: false, loaded: false } as RecordList<Income>,
  reducers: {
    incomeRequest: (state: RecordList<Income>, action: PayloadAction<RequestParams | undefined>) => {
      state.fetching = true;
      return state;
    },
    incomeSuccess: (state: RecordList<Income>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.results!;
      return state;
    },
    incomeFailure: (state: RecordList<Income>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },

    incomeClear: (state: RecordList<Income>) => {
      state = { fetching: false, loaded: false };
      return state;
    },
  },
});

export const { incomeRequest, incomeSuccess, incomeFailure, incomeClear } = incomeSlice.actions;
export default incomeSlice.reducer;

export const incomeEffect = {
  'income/clear': (store: ToolkitStore<AppStore>) => store.dispatch(incomeClear()),
  'income/incomeRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams | undefined>) => {
    var incomeList = store.getState().income;
    if (incomeList?.loaded === true && !incomeList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(incomeSuccess({ results: incomeList!.results! }));
    } else {
      try {
        let res = await apiGetIncomeList();
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(incomeSuccess({ results: data.rows ?? [] }));
        } else {
          store.dispatch(incomeFailure({ error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(incomeFailure({ error: e.toString() || '获取失败' }));
      }
    }
  },
};
