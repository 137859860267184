import React from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../types';
import main from '../main';
import Button from '../components/button';
import { useNavigate } from 'react-router-dom';
import { APIProtocolType } from '../utils/dict';
import { LoadingMask, showConfirm, showToast } from '../components/dialog';
import { apiLogout } from '../api';
import { logoutRequest } from '../redux/logoutReducer';

export default function SettingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: AppStore) => state.user);
  async function handleLogout() {
    let modalRes = await showConfirm({ title: '提示', content: '确定要退出登录吗?' });
    if (!modalRes) return;

    try {
      LoadingMask.show('正在退出...');
      let res = await apiLogout();
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '退出登录成功');
        dispatch(logoutRequest());
        navigate('/login', { replace: true });
      } else {
        showToast(data.msg ?? '退出失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '退出失败');
    } finally {
      LoadingMask.hide();
    }
  }
  return (
    <Page
      className="d-flex-c"
      contentClassName="flex-1 h-1 overflow-y bg-color-gray p-20"
      appBar={<AppBar className="bg-color-gray" title="账号设置" />}
    >
      <div className="bg-color-white ph-10 border-radius-4">
        <div className="d-flex-r pv-14 ai-center border-bottom">
          <div className="text-color-9 flex-1">账号</div>
          <div>{user.result?.nickName ?? user.result?.userName ?? ''}</div>
        </div>
        <div className="d-flex-r pv-14 ai-center border-bottom" onClick={() => navigate('/cardList')}>
          <div className="text-color-9 flex-1">卡号</div>
          <div>管理</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="d-flex-r pv-14 ai-center border-bottom" onClick={() => navigate('/changePhone')}>
          <div className="text-color-9 flex-1">更换手机号</div>
          <div>{user.result?.phonenumber ?? ''}</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="d-flex-r pv-14 ai-center border-bottom" onClick={() => navigate('/passwordEdit')}>
          <div className="text-color-9 flex-1">操作密码</div>
          <div>修改</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="d-flex-r pv-14 ai-center border-bottom" onClick={() => navigate('/address')}>
          <div className="text-color-9 flex-1">收货地址</div>
          <div>管理</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="d-flex-r pv-14 ai-center border-bottom" onClick={() => navigate('/income')}>
          <div className="text-color-9 flex-1">充消记录</div>
          <div>查看</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="d-flex-r pv-14 ai-center" onClick={() => navigate('/cardTransfer')}>
          <div className="text-color-9 flex-1">换卡记录</div>
          <div>查看</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
      </div>
      <div className="bg-color-white ph-10 border-radius-4 mt-20">
        <div
          className="d-flex-r pv-14 ai-center border-bottom"
          onClick={() => navigate('/protocol', { state: APIProtocolType.userAgreement })}
        >
          <div className="text-color-9 flex-1">用户协议</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div
          className="d-flex-r pv-14 ai-center border-bottom"
          onClick={() => navigate('/protocol', { state: APIProtocolType.privacyAgreement })}
        >
          <div className="text-color-9 flex-1">隐私政策</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div
          className="d-flex-r pv-14 ai-center border-bottom"
          onClick={() => navigate('/protocol', { state: APIProtocolType.userManual })}
        >
          <div className="text-color-9 flex-1">用户使用手册</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="d-flex-r pv-14 ai-center" onClick={() => navigate('/destroyAccount')}>
          <div className="text-color-9 flex-1">账号安全</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
      </div>
      <Button className="mt-50" type="primary" shape="circle" size="md" long onClick={handleLogout}>
        退出登录
      </Button>
    </Page>
  );
}
