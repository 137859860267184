import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiUseFlowCoupon } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import DefaultNoData from '../components/defaultPage/defaultNoData';
import { LoadingMask, showConfirm, showToast } from '../components/dialog';
import Page from '../components/page';
import TabBar from '../components/tabBar';
import main from '../main';
import { couponRequest } from '../redux/couponReducer';
import { AppStore, Coupon } from '../types';
import { CouponStatus, CouponType } from '../utils/dict';
import { dataListStatus, formatDateDiff, modelParseFlow } from '../utils/utils';

export default function CouponListPage() {
  const dispatch = useDispatch();
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const couponList = useSelector((state: AppStore) => state.coupon[currentCard.result?.id ?? '']);
  // 类型
  const [tabValue, setTabValue] = useState(CouponType.getTypeList()[0]);
  // 状态
  const [statusValue, setStatusValue] = useState(CouponStatus.getTypeList()[0]);
  // 数量统计
  const [listLength, setListLength] = useState<{ [key: string]: number }>({});
  const timerRef = useRef<{ timer?: NodeJS.Timeout; time: number }>({ time: 0 });
  // 倒计时,用于刷新页面,各优惠券计算自己的剩余时间
  // @ts-ignore
  const [, setTime] = useState(0);
  useEffect(() => {
    if (currentCard.result?.id) {
      dispatch(couponRequest({ cardId: currentCard.result.id }));
    }
    let t = timerRef.current.timer;
    return () => {
      t && clearInterval(t);
    };
  }, [currentCard]); // eslint-disable-line

  useEffect(() => {
    let _length: { [x: string]: number } = {};
    if (!!couponList?.results?.length) {
      _length[CouponType.all] = couponList.results!.length!;
      couponList.results!.forEach((i) => {
        if (!_length[i.couponType!]) {
          _length[i.couponType!] = 0;
        }
        _length[i.couponType!]++;
      });
      setListLength(_length);
      startTimer();
    }
  }, [couponList]); // eslint-disable-line

  /**
   * 启动计时器,每秒检测一次是否还有未使用的优惠券,有则刷新页面,没有则停
   *
   * @returns
   */
  function startTimer() {
    if (timerRef.current.timer) return;
    timerRef.current.timer = setInterval(() => {
      let unUse = couponList.results!.filter((i) => i.status === CouponStatus.unuse);
      if (unUse.length <= 0) {
        clearInterval(timerRef.current.timer);
        timerRef.current.timer = undefined;
      } else {
        setTime(++timerRef.current.time);
      }
    }, 10000);
  }

  async function handleUseCoupon(item: Coupon) {
    let modalRes = await showConfirm({
      title: '用券说明',
      content: (
        <div className="d-flex-c align-items-start p-10 text-left">
          <div>优惠券类型：流量包券</div>
          <div>预加流量：{modelParseFlow(item.addFlow ?? 0)}GB</div>
          <div>生效方式：立即生效</div>
          <div>备注：流量包立即生效，叠加本月基础包流量，有效期与本期套餐包一致，流量不可跨月。</div>
        </div>
      ),
    });
    if (modalRes !== 1) return;
    try {
      LoadingMask.show('正在使用...');
      var res = await apiUseFlowCoupon(item.id!);
      var data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '使用成功');

        dispatch(couponRequest({ cardId: currentCard.result?.id!, forceRefresh: true }));
      } else {
        showToast(data.msg ?? '使用失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '使用失败');
    } finally {
      LoadingMask.hide();
    }
  }

  let _centerView: any = [];
  let statusView = dataListStatus({ list: couponList });
  if (statusView) {
    _centerView = [<div className="mt-50">{statusView}</div>];
  } else {
    let list: Coupon[] = couponList.results!.filter((i) => {
      if (tabValue === CouponType.all && statusValue === CouponStatus.all) return true;
      if (tabValue === CouponType.all) {
        return statusValue === i.status!;
      } else if (statusValue === CouponStatus.all) {
        return tabValue === i.couponType!;
      }

      return statusValue === i.status! && tabValue === i.couponType!;
    });
    if (list.length <= 0) {
      _centerView = [
        <div className="mt-50">
          <DefaultNoData />
        </div>,
      ];
    } else {
      _centerView = list.map((item) => {
        let timeStr = '';
        if (item.status === CouponStatus.unuse) {
          let diff = dayjs(item.validEndTime).diff(dayjs(), 'second');
          if (diff < 0) {
            dispatch(couponRequest({ cardId: currentCard.result?.id!, forceRefresh: true }));
          } else if (diff < 24 * 3600) {
            let _t = formatDateDiff(dayjs(), dayjs(item.validEndTime), true);
            timeStr = _t ?? '';
          }
        }
        return (
          <div key={item.id} className="d-flex-r bg-color-white mb-10 border-radius-4">
            <div
              className={`w-60 h-60 m-10 border-radius-4 d-flex-c ai-center jc-center bg-color-${
                item.status === CouponStatus.expired
                  ? 'gray'
                  : item.couponType === CouponType.flow
                  ? 'primary-t1'
                  : 'origin-t1'
              }`}
            >
              <img
                className="w-40 h-40"
                src={main.pic(
                  `icon_coupon_${item.couponType}_${item.status === CouponStatus.expired ? 'expired' : 'active'}.png`
                )}
                alt=""
              />
              <div
                className={`font-size-12 ${
                  item.status === CouponStatus.expired
                    ? 'text-color-9'
                    : item.couponType === CouponType.flow
                    ? 'text-color-primary'
                    : 'text-color-origin'
                }`}
              >
                {CouponType.getTypeStr(item.couponType)}
              </div>
            </div>
            <div className="flex-1 pv-10">
              <div className="font-size-14 font-widget-600 omit-text">{item.couponName}</div>
              <div className="text-color-9 mt-5 mb-5">充值卡号:{item.iccid}</div>
              <div className="font-size-10 text-color-9 text-short">
                {dayjs(item.validBeginTime).format('YYYY.MM.DD HH:mm')}-
                {dayjs(item.validEndTime).format('YYYY.MM.DD HH:mm')}
              </div>
              {item.couponType === CouponType.payment && (
                <div className="text-color-9 font-size-10 mt-5 mb-5">{`满减条件：满${item.premiseAmount ?? 0}元减${
                  item.reduceAmount ?? 0
                }元`}</div>
              )}
            </div>
            <div className="d-flex-c h-80 ai-center">
              <div className="bg-color-gray w-10 h-5 border-radius-bl-8 border-radius-br-8"></div>
              <div className="flex-1 w-0 border-left-dashed"></div>
              <div className="bg-color-gray w-10 h-5 border-radius-tl-8 border-radius-tr-8"></div>
            </div>
            <div className="w-90 h-80 d-flex-c ai-center jc-center pt-10 pos-r">
              {
                <div
                  className={`pos-a r-0 t-0 border-radius-tr-4 border-radius-bl-4 pt-2 pb-2 pl-5 pr-5 font-size-12 bg-color-${
                    item.status === CouponStatus.unuse
                      ? 'green-t2 text-color-green'
                      : item.status === CouponStatus.used
                      ? 'red-t2 text-color-red'
                      : 'gray text-color-9'
                  }`}
                >
                  {CouponStatus.getTypeStr(item.status)}
                </div>
              }
              {item.status === CouponStatus.unuse && item.couponType === CouponType.flow && (
                <Button type="primary" outline shape="circle" onClick={() => handleUseCoupon(item)}>
                  立即使用
                </Button>
              )}
              {item.status === CouponStatus.unuse && item.couponType === CouponType.payment && (
                <div className="font-size-12 text-color-9">购买套餐可用</div>
              )}
              {!!timeStr && <div className="text-color-red">{timeStr}后过期</div>}
              {item.status === CouponStatus.used && (
                <div className="font-size-12 text-color-9 text-center">
                  <div>使用日期</div>
                  <div>{dayjs(item.usedTime).format('YYYY-MM-DD')}</div>
                </div>
              )}
              {item.status === CouponStatus.expired && (
                <div className="font-size-12 text-color-9 text-center">
                  <div>过期时间</div>
                  <div>{dayjs(item.validEndTime).format('YYYY-MM-DD')}</div>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
  }
  return (
    <div className="pos-r vw-100 vh-100">
      <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
      <Page
        className="pos-r d-flex-c"
        contentClassName="flex-1 h-1 d-flex-c bg-color-gray overflow-hidden"
        appBar={<AppBar className="bg-color-t0" white title="卡券中心" />}
      >
        <div className="bg-color-white">
          <TabBar
            value={tabValue}
            options={CouponType.getTypeList().map((item) => ({
              label: CouponType.getTypeStr(item) + (!!listLength[item] ? `(${listLength[item]})` : ''),
              value: item,
            }))}
            onChange={(v) => setTabValue(v)}
          />
        </div>
        <div className="p-10 border-top bg-color-white d-flex-r ai-center jc-between">
          {CouponStatus.getTypeList().map((item) => (
            <Button
              key={item}
              type={statusValue === item ? 'primary' : 'default'}
              size="sm"
              shape="circle"
              outline
              onClick={() => setStatusValue(item)}
            >
              <div className="ph-10"> {CouponStatus.getTypeStr(item)}</div>
            </Button>
          ))}
        </div>
        <div className="flex-1 h-1 overflow-y p-10">{_centerView}</div>
      </Page>
    </div>
  );
}
