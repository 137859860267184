import React, { useState } from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import { ComplaintStatus, ComplaintWorkerType } from '../utils/dict';
import RadioBox from '../components/radioBox';
import { useNavigate } from 'react-router-dom';
import main from '../main';
import Button from '../components/button';
import { LoadingMask, showToast } from '../components/dialog';
import { validateTel } from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../types';
import { apiCreateComplaint, apiUploadPic } from '../api';
import { complaintRequest } from '../redux/complaintReducer';

const fileLength = 2;
export default function ComplaintPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const [types, setTypes] = useState<string[]>([]);
  const [workOrderName, setWorkOrderName] = useState<string[]>([]);
  const [complaintText, setComplaintText] = useState('');
  const [workerNum, setWorkerNum] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [fileImgs, setFileImgs] = useState<{ src: string; key: string }[]>([]);
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  function handleSetType(type: string, label: string) {
    if (types.includes(type)) {
      setTypes(types.filter((t) => t !== type));
    } else {
      setTypes([...types, type]);
    }
    if (workOrderName.includes(label)) {
      setWorkOrderName(workOrderName.filter((t) => t !== label));
    } else {
      setWorkOrderName([...workOrderName, label]);
    }
  }

  const handleImageUpload = (event: any) => {
    let filesList = event.target.files;
    let _files: File[] = [...files];
    if (filesList && filesList[0]) {
      _files.push(filesList[0]);
    }
    if (_files.length > fileLength) {
      _files = _files.slice(0, fileLength);
    }
    setFiles(_files);
    for (let i = 0; i < filesList.length; i++) {
      const file = filesList[i];
      // 创建FileReader实例
      const reader = new FileReader();

      reader.onload = (e: any) => {
        // 使用文件名或索引来保证顺序
        setFileImgs((prevImages) => [...prevImages, { src: e.target.result, key: file.name || i }]);
      };

      reader.readAsDataURL(file);
    }
  };
  function handleRemoveImg(idx: number) {
    let t = [...files];
    t.splice(idx, 1);
    setFiles(t);
    let tt = [...fileImgs];
    tt.splice(idx, 1);
    setFileImgs(tt);
  }

  async function handleConfirm() {
    if (!name) {
      return showToast('请输入姓名');
    }
    if (!validateTel(tel)) {
      return showToast('请输入正确的手机号');
    }
    if (!types.length) {
      return showToast('请选择投诉原因');
    }
    if (types.includes('service') && !workerNum) {
      return showToast('请输入客服工号');
    }
    if (!complaintText) {
      return showToast('请描述您的问题');
    }
    try {
      LoadingMask.show('正在提交...');
      let _pics = [];
      if (files.length > 0) {
        for (let p of files) {
          let url = await apiUploadPic(p);
          _pics.push(url);
        }
      }
      let body = {
        cardId: currentCard.result?.id,
        workOrderType: types.join(','),
        workOrderName: workOrderName.join(','),
        workOrderImg: _pics.length > 0 ? _pics.join(';') : '',
        workOrderContent: complaintText,
        iccid: currentCard.result?.iccid,
        name: name,
        phoneNumber: tel,
        status: ComplaintStatus.pending,
        jobNumber: workerNum,
      };
      var res = await apiCreateComplaint(body);
      var data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '提交成功');
        navigate(-1);
        dispatch(complaintRequest({ forceRefresh: true }));
      } else {
        showToast(data.msg ?? '提交失败');
      }
    } catch (e: any) {
      showToast(e.message || '提交失败');
    } finally {
      LoadingMask.hide();
    }
  }

  function buildType(t: string, label: string) {
    return (
      <div
        className="d-flex-r"
        onClick={() => {
          handleSetType(t, label);
        }}
      >
        <RadioBox className="mr-5" value={types.includes(t)} green />
        <div className="font-size-12">{label}</div>
      </div>
    );
  }

  return (
    <Page
      className="bg-white d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c overflow-hidden"
      appBar={<AppBar title="投诉" />}
    >
      <div className="flex-1 h-1 overflow-y p-20">
        <div className="font-size-18 font-widget-600 pv-10 border-bottom">请填写以下信息投诉卖家</div>
        <div className="mt-10 mb-20">
          非常抱歉给您带来不便，我们重视每一位用户的使用体验，请填写信息帮助我们明确问题。
        </div>
        <div className="font-size-16 font-widget-600 mb-10">
          投诉商家原因<span className="font-size-12 text-color-9 ml-10">(多选)</span>
        </div>
        <div className="d-flex-r jc-between mb-10">
          {buildType(ComplaintWorkerType.active, ComplaintWorkerType.getTypeStr('active'))}
          {buildType(ComplaintWorkerType.real, ComplaintWorkerType.getTypeStr('real'))}
          {buildType(ComplaintWorkerType.packOrder, ComplaintWorkerType.getTypeStr('packOrder'))}
        </div>
        <div className="d-flex-r jc-between mb-20">
          {buildType(ComplaintWorkerType.pay, ComplaintWorkerType.getTypeStr('pay'))}
          {buildType(ComplaintWorkerType.speed, ComplaintWorkerType.getTypeStr('speed'))}
          {buildType(ComplaintWorkerType.service, ComplaintWorkerType.getTypeStr('service'))}
        </div>
        {types.includes('service') && (
          <div className="font-size-16 font-widget-600 mb-10">
            客服工号<span className="font-size-12 text-color-9 ml-10">(必填)</span>
          </div>
        )}
        {types.includes('service') && (
          <div className="d-flex-r pv-10 border-bottom mb-10">
            <input
              type="number"
              className="flex-1"
              value={workerNum}
              placeholder="请输入客服工号"
              onChange={(e) => setWorkerNum(e.currentTarget.value)}
            />
          </div>
        )}
        <div className="font-size-16 font-widget-600 mb-10">
          投诉原因<span className="font-size-12 text-color-9 ml-10">(必填)</span>
        </div>
        <div className="pos-r border-bottom mb-20">
          <textarea
            className="bw-100 h-88 pos-r"
            maxLength={100}
            value={complaintText}
            placeholder="请填写10字以上100字以内投诉原因以便我们核实情况"
            onChange={(e) => setComplaintText(e.currentTarget.value)}
          />
          <div className="pos-a r-0 b-2 font-size-12 text-color-9">{complaintText?.length ?? 0}/100</div>
        </div>
        <div className="font-size-16 font-widget-600 mb-10">
          图片证据<span className="font-size-12 text-color-9 ml-10">(选填)</span>
        </div>
        <div className="mb-20">
          请上传与本次投诉相关的图片证据，最多上传两张证据，您所上传的图片商家可见，请注意保护个人隐私
        </div>
        <div className="d-flex-r ai-end mb-20">
          {fileImgs.map((item, idx) => (
            <div key={item.key + idx} className="pos-r w-40 h-40 border-radius-4 mr-10 border">
              <img className="bw-100 bh-100 pos-r" src={item.src} alt="" />
              <img
                className="pos-a t-0 r-0 w-14 h-14"
                src={main.pic('icon_upload_close.png')}
                alt=""
                onClick={() => handleRemoveImg(idx)}
              />
            </div>
          ))}
          {fileImgs.length < fileLength && (
            <div className="pos-r w-40 h-40 border-radius-4 mr-10">
              <img className="bw-100 bh-100 pos-r" src={main.pic('icon_upload.png')} alt="" />
              <input
                className="pos-a l-0 t-0 w-40 h-40 opacity-0"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>
          )}
          <div className="flex-1"></div>
          <div>
            {fileImgs.length}/{fileLength}
          </div>
        </div>
        <div className="font-size-16 font-widget-600 mb-10">
          联系方式<span className="font-size-12 text-color-9 ml-10">(必填)</span>
        </div>
        <div className="border-top border-bottom d-flex-r ai-center pv-10">
          <div className="w-80">联系人姓名</div>
          <input
            type="text"
            className="flex-1"
            value={name}
            placeholder="请输入联系人姓名"
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </div>

        <div className="border-bottom d-flex-r ai-center pv-10 mb-20">
          <div className="w-80">联系电话</div>
          <input
            type="text"
            className="flex-1"
            placeholder="请输入联系电话"
            value={tel}
            maxLength={11}
            onChange={(e) => setTel(e.currentTarget.value)}
          />
        </div>
        <div>点击提交按钮,即代表您同意商家客服获取投诉描述、图片证据和手机号,以便联系您解决问题</div>
      </div>
      <div className="pv-10 ph-20">
        <Button type="green" size="md" long onClick={handleConfirm}>
          提交
        </Button>
      </div>
    </Page>
  );
}
