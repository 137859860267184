import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Page from '../components/page';
import main from '../main';
import { AppStore, CardPack } from '../types';
import { CardStatus } from '../utils/dict';
import PackGridList from './components/packGridList';
import HomeCardInfo from './homeComponents/homeCardInfo';
import HomeMenu from './homeComponents/homeMenu';
import HomeSlider from './homeComponents/homeSlider';
import HomeSurplus from './homeComponents/homeSurplus';
import HomeTips from './homeComponents/homeTips';
import HomeAutoDialog from './homeComponents/homeAutoDialog';
import { showToast } from '../components/dialog';
import HomeCheckSwapCard from './homeComponents/homeCheckSwapCard';

export default function HomePage() {
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const firstActivity = useSelector((state: AppStore) => state.chargeFirst[currentCard?.result?.id ?? '']);
  useEffect(() => {
    if (!main.bindCardNumLoaded) return;
    if (main.bindCardNum <= 0 && !main.jumpBindCard) {
      // 如果从来没有绑定过卡 跳转绑卡
      main.jumpBindCard = true;
      navigate('/cardProcess', { state: { step: 1 } });
    } else if (currentCard.loaded && firstActivity?.loaded) {
      checkCard();
    }
  }, [currentCard, firstActivity, user]); // eslint-disable-line

  /**
   * 检查卡当前的情况
   */
  function checkCard() {
    let card = currentCard.result;
    if (card?.id && firstActivity.result?.forceActivity === 'Y') {
      main.jumpToActivity[card.id] = false;
    }
    // 绑卡数量未加载完成 不处理
    if (!!card?.id && card?.isChargeFirstActive && !main.jumpToActivity[card.id]) {
      // 如果有活动 跳转到活动页
      if (firstActivity.result?.forceActivity !== 'Y') {
        main.jumpToActivity[card!.id!] = true;
      }
      navigate('/chargeFirst');
    } else if (
      // 如果是第一张卡 且未激活 跳转到买套餐
      main.bindCardNum === 1 &&
      card?.id &&
      !card.theMonth &&
      !card.nextMonth &&
      !main.jumpToBuyPack[card!.id!]
    ) {
      main.jumpToBuyPack[card!.id!] = true;
      navigate('/cardProcess', { state: { step: 2, iccid: card.iccid } });
    } else if (
      // 如果是第一张卡 且未实名 跳转到实名
      // 改为每次进首页就弹
      // main.bindCardNum === 1 &&
      !!card?.theMonth &&
      card.cardStatus === CardStatus.preRealName &&
      !!main.jumpToRealName[card!.id!]
    ) {
      navigate('/cardProcess', { state: { step: 3, iccid: card.iccid } });
    }
  }

  if (!currentCard || !currentCard.loaded) {
    return <div className="vw-100 vh-100 d-flex-c ai-center jc-center">正在加载...</div>;
  }

  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1 overflow-y pos-r">
      <img className="w-375 h-325 pos-a z-1" style={{ top: '-30px' }} src={main.pic('pic_home_bg.png', true)} alt="" />
      <div className="pos-r z-10 pv-20 ph-12">
        {/* 顶部卡号和功能 */}
        <HomeCardInfo currentCard={currentCard} />
        {/*  顶部功能菜单 */}
        <HomeMenu card={currentCard.result} />
        {/* 轮播图 */}
        <HomeSlider card={currentCard.result} />
        {/* 余量 流量 余额  */}
        <HomeSurplus currentCard={currentCard} />
        {!!currentCard.result?.id && <HomeTips card={currentCard.result} />}
      </div>
      {/* 套餐列表 */}
      {!!currentCard.result?.id && (
        <PackGridList
          card={currentCard.result}
          onClick={(pack: CardPack, dateType: string) => {
            if (user?.result?.payLimit === 'Y') {
              return showToast('当前账户存在风险，禁止交易');
            }
            navigate(`/packOrderConfirm?packId=${pack.id!}&dateType=${dateType}`, { state: { canBack: true } });
          }}
        />
      )}
      {/* 自动弹出的弹窗逻辑 */}
      {!!currentCard?.result?.id && <HomeAutoDialog card={currentCard.result} />}
      <HomeCheckSwapCard />
    </Page>
  );
}
