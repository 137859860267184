import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import DefaultNoData from '../../components/defaultPage/defaultNoData';
import PackTabBar from '../../components/packTabBar';
import main from '../../main';
import { cardPackRequest } from '../../redux/cardPackReducer';
import { AppStore, Card, CardPack, CurrentCard } from '../../types';
import { CardStatus, PackTimeType, PackageType } from '../../utils/dict';
import { useNavigate } from 'react-router-dom';
import { rpx } from '../../utils/utils';
interface Props {
  card?: CurrentCard | Card;
  hidePackTimeType?: boolean;
  monthType?: string;
  onClick: (pack: CardPack, dateType: string) => void;
}
export default function PackGridList({ card, onClick, monthType, hidePackTimeType }: Props) {
  const [tabValue, setTabValue] = useState(monthType ?? 'currMonth');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardPackList = useSelector((state: AppStore) => state.cardPack[card?.id ?? '']);
  //选中的 按钮类型
  const [packTimeType, setPackTimeType] = useState('month');
  // 所有的 按钮类型
  const [packTimeTypes, setPackTimeTypes] = useState<string[]>([]);
  // 按类型能显示的套餐列表
  const [showList, setShowList] = useState<CardPack[]>([]);
  // 展开 折叠
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  useEffect(() => {
    if (card?.id || !cardPackList) {
      dispatch(cardPackRequest({ cardId: card?.id ?? '' }));
    }
  }, [card?.id]); // eslint-disable-line
  useEffect(() => {
    if (card?.id && cardPackList?.results) {
      let _list: CardPack[] = [];
      let _packTimeType: string = packTimeType;
      let _packTimeTypes: string[] = [];
      for (let item of cardPackList?.results) {
        if (tabValue === 'currMonth') {
          // 当月列表内 待激活的卡或当月为空只能获取基础包
          if (card?.cardStatus === CardStatus.waitactivation || !card.theMonth) {
            if (item.packageType === PackageType.basicpackage) {
              if (!_packTimeTypes.includes(item.packTimeType!)) {
                _packTimeTypes.push(item.packTimeType!);
              }
              _list.push(item);
            }
          } else {
            // 当月列表内 非待激活的卡获取非基础包    fuelpack=> 加油包
            if (item.packageType !== PackageType.basicpackage) {
              if (!_packTimeTypes.includes(item.packTimeType!)) {
                _packTimeTypes.push(item.packTimeType!);
              }
              _list.push(item);
            }
          }
        } else {
          // 次月列表内 只能获取基础包
          if (item.packageType === PackageType.basicpackage) {
            if (!_packTimeTypes.includes(item.packTimeType!)) {
              _packTimeTypes.push(item.packTimeType!);
            }
            _list.push(item);
          }
        }
      }

      // 检查当前类型在不在要显示的类型列表内
      if (!_packTimeTypes.includes(packTimeType) && _packTimeTypes) {
        _packTimeType = _packTimeTypes[0];
      }
      if (card.cardStatus === CardStatus.soldout || card.cardStatus === CardStatus.unknown) {
        _packTimeTypes = [];
        _list = [];
      }
      if (_packTimeType !== packTimeType) {
        setPackTimeType(_packTimeType);
      }
      setPackTimeTypes(_packTimeTypes);
      setShowList(_list);
    }
  }, [cardPackList, packTimeType, tabValue]); //eslint-disable-line

  if (!card?.id) {
    return (
      <div className="d-flex-c ai-center pt-30">
        <DefaultNoData tips="还未绑定卡片" />
        <Button
          className="mt-20"
          type="primary"
          size="sm"
          shape="circle"
          onClick={() => navigate('/cardProcess', { state: { step: 1 } })}
        >
          <div className="w-100">立即绑卡</div>
        </Button>
      </div>
    );
  }

  // 排序 首页套餐包按照年包、月包、半年包、季包展示，同时是平铺展示；【任务 2410】
  let _sortPackTimeTypes: string[] = [];
  if (packTimeTypes.includes(PackTimeType.years)) {
    _sortPackTimeTypes.push(PackTimeType.years);
  }
  if (packTimeTypes.includes(PackTimeType.month)) {
    _sortPackTimeTypes.push(PackTimeType.month);
  }
  if (packTimeTypes.includes(PackTimeType.halfYear)) {
    _sortPackTimeTypes.push(PackTimeType.halfYear);
  }
  if (packTimeTypes.includes(PackTimeType.season)) {
    _sortPackTimeTypes.push(PackTimeType.season);
  }
  packTimeTypes.forEach((i) => {
    if (!_sortPackTimeTypes.includes(i)) {
      _sortPackTimeTypes.push(i);
    }
  });

  return (
    <>
      <PackTabBar
        options={[
          { label: '本期套餐', value: 'currMonth' },
          { label: '下期套餐', value: 'nextMonth' },
        ]}
        onChange={(value) => {
          setTabValue(value);
        }}
        value={tabValue}
      />
      {!hidePackTimeType && (
        <div className="d-flex-r ph-20 pt-20">
          {_sortPackTimeTypes.map((i, idx) => (
            <Button
              key={i + idx}
              className="mr-10"
              type={i === packTimeType ? 'primary' : 'default'}
              bgPic
              size="xs"
              outline
              onClick={() => {
                document.getElementById(i)?.scrollIntoView({ behavior: 'smooth' });
                setPackTimeType(i);
              }}
            >
              {PackTimeType.getTypeStr(i)}
            </Button>
          ))}
        </div>
      )}

      <div style={{ maxHeight: window.innerHeight - rpx(85) + 'px' }} className="overflow-y">
        {!!_sortPackTimeTypes.length ? (
          _sortPackTimeTypes.map((type) => {
            // if (type !== packTimeType) {
            //   //
            //   return null;
            // }
            let _list = showList.filter((i) => i.packTimeType === type);

            let _showList = _list;
            if (!!expanded[type]) {
              _showList = _list.slice(0, 3);
            }
            return (
              <div id={type} key={type} className="d-shadow p-10 m-10 mh-20 border-radius-4">
                <div className="d-flex-r ai-center">
                  <div className="gradient-asc-primary-t2 w-6 h-20 border-radius-6 mr-10"></div>
                  <div className="font-size-14 font-widget-600 mr-10">{PackTimeType.getTypeStr(type)}</div>
                </div>
                <div className="d-flex-r flex-wrap">
                  {_showList.map((pack, idx) => {
                    // 日期描述
                    let dayStr = '';

                    if (pack.packageType === PackageType.fuelpack) {
                      dayStr = '本期有效';
                    } else if (pack.packTimeType === PackTimeType.day) {
                      dayStr = `${pack.flowCalcTimeValue ?? 0}天有效`;
                    } else {
                      dayStr =
                        dayjs(tabValue === 'currMonth' ? pack.endEffectTime : pack.nextEndEffectTime).format(
                          'YY.MM.DD'
                        ) + '前有效';
                    }

                    // let startAt = dayjs(tabValue === 'currMonth' ? pack.startEffectTime : pack.nextStartEffectTime);
                    // let endAt = dayjs(tabValue === 'currMonth' ? pack.endEffectTime : pack.nextEndEffectTime);

                    // let diff = endAt.diff(startAt, 'day') + 1;
                    let hasCoupon = !!pack.discountPrice && pack.discountPrice < pack.salePrice!;
                    let isUnLimit = (pack.totalFlow ?? 0) > main.showUnlimited;
                    return (
                      <div
                        key={pack.id! + idx}
                        className={`w-90 h-70 mh-7 bg-color-gray mt-7 d-flex-c ai-center jc-evenly border-radius-4 pos-r ${
                          pack.isDefault === 'Y' ? 'border border-color-primary' : ''
                        }`}
                        onClick={() => onClick(pack, tabValue)}
                      >
                        {isUnLimit ? (
                          <div className="pos-r z-2 font-size-16 font-widget-600">不限量</div>
                        ) : (
                          <div className="pos-r z-2 font-size-16 font-widget-600">{pack.totalFlow ?? 0}GB</div>
                        )}
                        <div className="pos-r z-2 text-color-9 font-size-10">{dayStr}</div>
                        <div className="pos-r z-2 text-color-primary">
                          售价
                          <span className="pos-r z-2 font-size-14">
                            {pack.discountPrice ? pack.discountPrice : pack.salePrice}
                          </span>
                          元
                        </div>

                        {!!hasCoupon && (
                          <img className="pos-a z-0 r-0 t-0 w-32" src={main.pic('icon_coupon_flag.png', true)} alt="" />
                        )}
                      </div>
                    );
                  })}
                  {_list.length > 3 && (
                    <div
                      className="d-flex-r ai-center jc-center p-10 bw-100"
                      onClick={() =>
                        setExpanded(Object.assign({}, expanded, { [type]: expanded[type] ? false : true }))
                      }
                    >
                      <div className="mr-5">{expanded[type] ? '展开' : '收起'}</div>
                      <img
                        className="icon-10"
                        src={main.pic(`icon_expand_${expanded[type] ? 'down' : 'up'}.png`)}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="mt-50">
            <DefaultNoData tips="暂无可用套餐" />
          </div>
        )}
      </div>
    </>
  );
}
