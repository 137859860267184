import { useEffect, useState } from 'react';
import main from '../../main';
interface Props {
  value: boolean;
  className?: string;
  green?: boolean;
  onChange?: (v?: boolean) => void;
}
export default function Radiobox({ value = false, onChange, green, className }: Props) {
  const [select, setSelect] = useState(value);
  useEffect(() => {
    if (value !== select) {
      setSelect(value);
    }
  }, [value]); // eslint-disable-line
  function handleChange() {
    if (onChange) {
      setSelect(!select);
      onChange(!select);
    }
  }
  return (
    <div className={`m-checkbox ${className}`} onClick={handleChange}>
      <img
        className="m-size"
        src={
          select
            ? green
              ? main.pic('icon_radio_green.png')
              : main.pic('icon_radio_checked.png', true)
            : main.pic('icon_radio_default.png', true)
        }
        alt=""
      />
    </div>
  );
}
