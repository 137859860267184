import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainPage from './mainPage';
import HomePage from './pages/homePage';
import LoginCardPage from './pages/loginCardPage';
import LoginCodePage from './pages/loginCodePage';
import LoginPage from './pages/loginPage';
import ProtocolPage from './pages/protocolPage';
import SettingPage from './pages/settingPage';
import CardListPage from './pages/cardListPage';
import ApplySwitchCardPage from './pages/applySwitchCardPage';
import AddressListPage from './pages/addressListPage';
import AddressEditPage from './pages/addressEditPage';
import CardProcessPage from './pages/cardProcessPage';
import PasswordEditPage from './pages/passwordEditPage';
import OrderListPage from './pages/orderListPage';
import OrderDetailPage from './pages/orderDetailPage';
import IFramePage from './pages/iframePage';
import IncomeListPage from './pages/incomeListPage';
import CardDetailPage from './pages/cardDetailPage';
import BuyPackLogListPage from './pages/buyPackLogListPage';
import ChargePage from './pages/chargePage';
import CouponListPage from './pages/couponListPage';
import PackOrderConfirmPage from './pages/packOrderConfirmPage';
import CardCheckPage from './pages/cardCheckPage';
import CardTransferPage from './pages/cardTransferPage';
import CardTransferDetailPage from './pages/cardTransferDetailPage';
import ChangePhonePage from './pages/chargePhonePage';
import CustomerPage from './pages/customerPage';
import DestroyAccountPage from './pages/destroyAccountPage';
import ComplaintIndexPage from './pages/complaintIndexPage';
import ComplaintPage from './pages/complaintPage';
import ComplaintListPage from './pages/complaintListPage';
import ComplaintDetailPage from './pages/complaintDetailPage';
import ChargeFirstActivePage from './pages/chargeFirstActivePage';
import BindTelProcessPage from './pages/bindTelProcessPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route path="/" element={<HomePage />} />
          <Route path="protocol" element={<ProtocolPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="bindTel" element={<BindTelProcessPage />} />
          <Route path="loginCode" element={<LoginCodePage />} />
          <Route path="loginCard" element={<LoginCardPage />} />
          <Route path="setting" element={<SettingPage />} />
          <Route path="cardList" element={<CardListPage />} />
          <Route path="cardProcess" element={<CardProcessPage />} />
          <Route path="applySwitchCard" element={<ApplySwitchCardPage />} />
          <Route path="address" element={<AddressListPage />} />
          <Route path="addressEdit" element={<AddressEditPage />} />
          <Route path="passwordEdit" element={<PasswordEditPage />} />
          <Route path="orderList" element={<OrderListPage />} />
          <Route path="orderDetail/:id" element={<OrderDetailPage />} />
          <Route path="iframe" element={<IFramePage />} />
          <Route path="income" element={<IncomeListPage />} />
          <Route path="cardDetail" element={<CardDetailPage />} />
          <Route path="buyPackLog" element={<BuyPackLogListPage />} />
          <Route path="charge" element={<ChargePage />} />
          <Route path="coupon" element={<CouponListPage />} />
          <Route path="packOrderConfirm" element={<PackOrderConfirmPage />} />
          <Route path="cardCheck" element={<CardCheckPage />} />
          <Route path="cardTransfer" element={<CardTransferPage />} />
          <Route path="cardTransfer/:id" element={<CardTransferDetailPage />} />
          <Route path="changePhone" element={<ChangePhonePage />} />
          <Route path="customer" element={<CustomerPage />} />
          <Route path="destroyAccount" element={<DestroyAccountPage />} />
          <Route path="complaintIndex" element={<ComplaintIndexPage />} />
          <Route path="complaint" element={<ComplaintPage />} />
          <Route path="complaintList" element={<ComplaintListPage />} />
          <Route path="complaintDetail/:id" element={<ComplaintDetailPage />} />
          <Route path="chargeFirst" element={<ChargeFirstActivePage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
