export enum APIProtocolType {
  /** 隐私协议 */
  privacyAgreement = 'privacyAgreement',

  /** 用户协议 */
  userAgreement = 'userAgreement',

  /** 使用协议 */
  userManual = 'userManual',

  /** 注销需知 */
  destroyAccount = 'destroyAccount',
}

/** 套餐类型 */
export class PackageType {
  static fuelpack = 'fuelpack';
  static basicpackage = 'basicpackage';
  static getTypeStr(_type?: string) {
    if (_type === PackageType.fuelpack) {
      return '加油包';
    } else if (_type === PackageType.basicpackage) {
      return '基础套餐';
    }
    return '未知';
  }
}

/** 套餐包类型 */
export class PackTimeType {
  static day = 'day';
  static years = 'years';
  static month = 'month';
  static halfYear = 'halfYear';
  static season = 'season';
  static fuelpack = 'fuelPack';
  static twoYears = 'twoYears';
  static threeYears = 'threeYears';

  static getTypeStr(_type?: string) {
    if (_type === PackTimeType.day) {
      return '日包';
    } else if (_type === PackTimeType.month) {
      return '月包';
    } else if (_type === PackTimeType.season) {
      return '季包';
    } else if (_type === PackTimeType.halfYear) {
      return '半年包';
    } else if (_type === PackTimeType.years) {
      return '年包';
    } else if (_type === PackTimeType.twoYears) {
      return '2年包';
    } else if (_type === PackTimeType.threeYears) {
      return '3年包';
    } else if (_type === PackTimeType.fuelpack) {
      return '加油包';
    }

    return '月包';
  }
}

/** 卡状态 */
export class CardStatus {
  /** 待激活 */
  static waitactivation = 'waitactivation';

  /**  待实名 */
  static preRealName = 'preRealName';

  /**  已激活 */
  static activated = 'activated';

  /**  已停机 */
  static stop = 'stop';

  /**  预销户 */
  static prestop = 'prestop';

  /**  未知 */
  static unknown = 'unknown';

  /**  已销户 */
  static soldout = 'soldout';

  /**  异常终端 */
  static error = 'error';
  static getTypeStr(type?: string) {
    const _cardStatus = {
      [CardStatus.waitactivation]: '待激活',
      [CardStatus.preRealName]: '待实名',
      [CardStatus.activated]: '已激活',
      [CardStatus.stop]: '已停机',
      [CardStatus.prestop]: '预销户',
      [CardStatus.unknown]: '未知',
      [CardStatus.soldout]: '已销户',
      [CardStatus.error]: '异常终端',
    };
    return _cardStatus[type ?? ''] ?? '未知';
  }
}
/** 运营商类型 */
export class OperatorType {
  /**  联通 */
  static lianTong = 'lt';

  /**  电信 */
  static dianXin = 'dx';

  /**  移动 */
  static yiDong = 'yd';

  /**  广电 */
  static guangDian = 'gd';

  static getTypeStr(type?: string) {
    if (type === OperatorType.lianTong) {
      return '联通';
    }
    if (type === OperatorType.yiDong) {
      return '移动';
    }
    if (type === OperatorType.dianXin) {
      return '电信';
    }
    if (type === OperatorType.guangDian) {
      return '广电';
    }
    return '未知';
  }
  static getTypeIcon(type?: string) {
    if (type === OperatorType.lianTong) {
      return 'icon_op_lt';
    }
    if (type === OperatorType.yiDong) {
      return 'icon_op_yd';
    }
    if (type === OperatorType.dianXin) {
      return 'icon_op_dx';
    }
    if (type === OperatorType.guangDian) {
      return 'icon_op_gd';
    }
  }
}

/** 转卡状态 */
export class CardTransferStatus {
  /**  审核中 */
  static audit = 'audit';

  /**  待发货 */
  static waitShipped = 'waitShipped';

  /**  待签收 */
  static waitSignFor = 'waitSignFor';

  /**  待转卡 */
  static waitTransferCard = 'waitTransferCard';

  /**  转卡中 */
  static completed = 'completed';

  /**  审核失败 */
  static auditFailure = 'auditFailure';

  /**  转卡失败 */
  static transferCardFailure = 'transferCardFailure';

  /**  转卡失败 */
  static taskFailure = 'taskFailure';

  /**  转卡成功 */
  static taskSuccess = 'taskSuccess';
  static getTypeStr(type?: string) {
    const _status = {
      [CardTransferStatus.audit]: '审核中',
      [CardTransferStatus.auditFailure]: '审核失败',
      [CardTransferStatus.waitShipped]: '待发货',
      [CardTransferStatus.waitSignFor]: '待签收',
      [CardTransferStatus.waitTransferCard]: '待转卡',
      [CardTransferStatus.completed]: '转卡中',
      [CardTransferStatus.taskSuccess]: '转卡成功',
      [CardTransferStatus.taskFailure]: '转卡失败',
      [CardTransferStatus.transferCardFailure]: '转卡失败',
    };
    return _status[type ?? ''] ?? '未知';
  }

  static getTypeIcon(type?: string) {
    const _status = {
      [CardTransferStatus.audit]: 'icon_check_audit.png',
      [CardTransferStatus.auditFailure]: 'icon_check_error.png',
      [CardTransferStatus.waitShipped]: 'icon_check_ship.png',
      [CardTransferStatus.waitSignFor]: 'icon_check_shipping.png',
      [CardTransferStatus.waitTransferCard]: 'icon_check_wait_transfer.png',
      [CardTransferStatus.completed]: 'icon_check_transfering.png',
      [CardTransferStatus.taskSuccess]: 'icon_check_success.png',
      [CardTransferStatus.taskFailure]: 'icon_check_error.png',
      [CardTransferStatus.transferCardFailure]: 'icon_check_error.png',
    };
    return _status[type ?? ''] ?? 'icon_check_info.png';
  }
  static getTypeColor(type?: string) {
    const _status = {
      [CardTransferStatus.audit]: 'blue',
      [CardTransferStatus.auditFailure]: 'red',
      [CardTransferStatus.waitShipped]: 'blue',
      [CardTransferStatus.waitSignFor]: 'origin',
      [CardTransferStatus.waitTransferCard]: 'origin',
      [CardTransferStatus.completed]: 'blue',
      [CardTransferStatus.taskSuccess]: 'green',
      [CardTransferStatus.taskFailure]: 'red',
      [CardTransferStatus.transferCardFailure]: 'red',
    };
    return _status[type ?? ''] ?? '6';
  }
}
/** 充值类型 */
export enum RechargeType {
  /** 首充 */
  first = 'first',

  /** 直充 */
  direct = 'direct',

  /** 续充 */
  renewal = 'renewal',
}

/** 支付类型 */
export class PayType {
  /**  余额支付 */
  static balance = 'balancePay';

  /**  官方微信支付 */
  static weixin = 'wechatPay';

  /**  官方支付宝支付 */
  static alipay = 'aliPay';

  /**  官方支付宝H5支付 */
  static aliPage = 'aliPage';

  /**  云闪付 */
  static unionpay = 'union_pay';

  /**  HWC微信支付 拉起小程序，在小程序内拉起微信支付 */
  static gatherpayWechat = 'gatherPayWechat';

  /**  HWC支付宝扫码支付 得到二维码内容后跳转 */
  static gatherpayAli = 'gatherPayAli';

  /**  HWC公众号支付 */
  static gatherPayJsWechat = 'gatherPayJsWechat';
  /**  官方公众号支付 */
  static wechatJsPay = 'wechatJsPay';
  /**  银联快捷 */
  static sdbPayFast = 'sdbPayFast';
  /**  银联快捷 */
  static sdbPayFastWeb = 'sdbPayFastWeb';
  /**  瑞生意支付 第四方 微信支付 */
  static gmeJsPay = 'gmeJsPay';
  /**  富友公众号支付 第四方 微信支付 */
  static fyH5Pay = 'fyH5Pay';
  /**  惠收米公众号支付 第四方微信支付 */
  static hsmWxJsPay = 'hsmWxJsPay';
  static typeStr(type?: string) {
    if (type === PayType.balance) {
      return '余额支付';
    } else if (type === PayType.unionpay) {
      return '云闪付';
    } else if (
      type === PayType.weixin ||
      type === PayType.gatherpayWechat ||
      type === PayType.gatherPayJsWechat ||
      type === PayType.wechatJsPay ||
      type === PayType.fyH5Pay ||
      type === PayType.gmeJsPay ||
      type === PayType.hsmWxJsPay
    ) {
      return '微信支付';
    } else if (type === PayType.alipay || type === PayType.gatherpayAli || type === PayType.aliPage) {
      return '支付宝支付';
    } else if (type === PayType.sdbPayFast || type === PayType.sdbPayFastWeb) {
      return '银联快捷';
    }
    return '';
  }

  static typeIcon(type?: string) {
    if (type === PayType.balance) {
      return 'icon_pay_balance.png';
    } else if (type === PayType.unionpay) {
      return 'icon_pay_union.png';
    } else if (type === PayType.sdbPayFast || type === PayType.sdbPayFastWeb) {
      return 'icon_pay_yinlian.png';
    } else if (
      type === PayType.weixin ||
      type === PayType.gatherpayWechat ||
      type === PayType.gatherPayJsWechat ||
      type === PayType.wechatJsPay ||
      type === PayType.fyH5Pay ||
      type === PayType.gmeJsPay ||
      type === PayType.hsmWxJsPay
    ) {
      return 'icon_pay_wx.png';
    } else if (type === PayType.alipay || type === PayType.gatherpayAli || type === PayType.aliPage) {
      return 'icon_pay_zfb.png';
    }
    return 'icon_pay_balance.png';
  }
}

/** 优惠券失效类型 */
export class CouponExpiredType {
  static instant = 'instant';
  static fix = 'fix';
  static getTypeStr(type?: string) {
    const _status = {
      [CouponExpiredType.instant]: '领券后生效',
      [CouponExpiredType.fix]: '固定时间生效',
    };
    return _status[type ?? ''] ?? '未知类型';
  }
}

/** 订单状态 */
export class OrderStatus {
  static all = 'all';
  static wait = 'wait';
  static partialRefund = 'partial_refund';
  static success = 'success';
  static close = 'close';
  static getTypeList() {
    return [OrderStatus.all, OrderStatus.success, OrderStatus.close];
  }

  static getTypeStr(type?: string) {
    const _status = {
      [OrderStatus.all]: '全部',
      [OrderStatus.wait]: '待支付',
      [OrderStatus.success]: '支付成功',
      [OrderStatus.partialRefund]: '退款',
      [OrderStatus.close]: '订单关闭',
    };
    return _status[type ?? ''] ?? '未知类型';
  }
  static getTypeColor(type?: string) {
    const _status = {
      [OrderStatus.wait]: 'origin',
      [OrderStatus.success]: 'green',
      [OrderStatus.partialRefund]: 'red',
      [OrderStatus.close]: '9',
    };
    return _status[type ?? ''] ?? 'primary';
  }
}

/** 订单日期类型 */
export class OrderDateType {
  static next = 'next';
  static now = 'now';
  static getTypeStr(type?: string) {
    const _status = {
      [OrderDateType.next]: '下期',
      [OrderDateType.now]: '本期',
    };
    return _status[type ?? ''] ?? '未知类型';
  }
}

export class PackBuyLogStatus {
  /**   : '生效中', */
  static using = 'Y';

  /**   : '待生效', */
  static unUse = 'N';

  /**  : '已失效', */
  static close = 'close';

  /**  : '已退订', */
  static refund = 'return';

  /**  : '生效异常', */
  static error = 'error';
  static getTypeList() {
    return [PackBuyLogStatus.using, PackBuyLogStatus.unUse, PackBuyLogStatus.close, PackBuyLogStatus.error];
  }

  static getTypeStr(type?: string) {
    const _status = {
      [PackBuyLogStatus.using]: '生效中',
      [PackBuyLogStatus.unUse]: '待生效',
      [PackBuyLogStatus.close]: '已结算',
      [PackBuyLogStatus.refund]: '已退订',
      [PackBuyLogStatus.error]: '生效异常',
    };
    return _status[type ?? ''] ?? '未知';
  }
}

/** 优惠券状态 */
export class CouponStatus {
  static all = 'all';
  static unuse = 'N';
  static used = 'Y';
  static expired = 'expired';
  static getTypeList() {
    return [CouponStatus.all, CouponStatus.unuse, CouponStatus.used, CouponStatus.expired];
  }

  static getTypeStr(type?: string) {
    const _status = {
      [CouponStatus.all]: '全部',
      [CouponStatus.unuse]: '未使用',
      [CouponStatus.used]: '已使用',
      [CouponStatus.expired]: '已过期',
    };
    return _status[type ?? ''] ?? '未知状态';
  }
}
/** 优惠券类型 */
export class CouponType {
  static all = 'all';
  static payment = 'payment';
  static flow = 'flow';

  static getTypeList() {
    return [CouponType.all, CouponType.payment, CouponType.flow];
  }

  static getTypeStr(type?: string) {
    const _status = {
      [CouponStatus.all]: '全部',
      [CouponType.payment]: '抵扣券',
      [CouponType.flow]: '流量券',
    };
    return _status[type ?? ''] ?? '未知状态';
  }
}

/** 客服链接类型 */
export class CustomServiceType {
  /**  异常卡组 */
  static errorGroup = 'errorGroup';

  /**  访客组 */
  static visitorGroup = 'visitorGroup';

  /**  新用户组 */
  static newGroup = 'newGroup';

  /**  老用户组 */
  static oldGroup = 'oldGroup';
  static getTypeStr(type?: string) {
    const _status = {
      [CustomServiceType.errorGroup]: '异常卡组',
      [CustomServiceType.visitorGroup]: '访客组',
      [CustomServiceType.newGroup]: '新用户组 ',
      [CustomServiceType.oldGroup]: '老用户组',
    };
    return _status[type ?? ''] ?? '未知类型';
  }
}

/** 投诉建议反馈类型 */
export class ComplaintWorkerType {
  static pay = 'pay';
  static packOrder = 'packOrder';
  static active = 'active';
  static speed = 'speed';
  static real = 'real';
  static service = 'service';
  static getTypeStr(type?: string) {
    var _type = {
      [ComplaintWorkerType.pay]: '充值问题',
      [ComplaintWorkerType.packOrder]: '套餐订单问题',
      [ComplaintWorkerType.active]: '激活问题',
      [ComplaintWorkerType.speed]: '网速问题',
      [ComplaintWorkerType.real]: '实名问题',
      [ComplaintWorkerType.service]: '客服服务问题',
    };
    return _type[type ?? ''] ?? '未知类型';
  }

  static getList() {
    return [
      ComplaintWorkerType.pay,
      ComplaintWorkerType.packOrder,
      ComplaintWorkerType.active,
      ComplaintWorkerType.speed,
      ComplaintWorkerType.real,
      ComplaintWorkerType.service,
    ];
  }
}

/**  投诉建议状态 */
export class ComplaintStatus {
  static pending = 'wait';
  static cancel = 'cancel';
  static processed = 'process';
  static completed = 'completed';
  static getTypeStr(type?: string) {
    var _status = {
      [ComplaintStatus.pending]: '待处理',
      [ComplaintStatus.cancel]: '已取消',
      [ComplaintStatus.processed]: '处理中',
      [ComplaintStatus.completed]: '已完成',
    };
    return _status[type ?? ''] ?? '未知状态';
  }
  static getTypeColor(type?: string) {
    var _status = {
      [ComplaintStatus.pending]: 'origin',
      [ComplaintStatus.cancel]: '9',
      [ComplaintStatus.processed]: 'blue',
      [ComplaintStatus.completed]: 'green',
    };
    return _status[type ?? ''] ?? 'red';
  }
}

export class CardSliderType {
  /** 外部链接  */
  static outLink = 'outLink';
  /** 内部链接  */
  static inLink = 'inLink';
  /** 充值链接  */
  static payLink = 'payLink';
  /** 充值活动  */
  static payActivity = 'payActivity';
}

/** 卡标签 固定标签 做固定操作 */
export class CardTagType {
  /**  直充活动 */
  static rechargeActivity = '直充活动';
  /**  死卡 */
  static died = '死卡';

  /**  亏损换同资费 */
  static changeProduct = '亏损换同资费';

  /** 亏损换号卡 */
  static chargeCard = '亏损换号卡';

  /** 系统升级 */
  static update = '系统升级';
}
