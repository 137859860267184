import React from 'react';
import { CurrentCard, DetailRecord } from '../../types';
import Button from '../../components/button';
import { CardStatus, CardTransferStatus } from '../../utils/dict';
import main from '../../main';
import { formatNumber } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { LoadingMask, showConfirm, showCustom, showToast } from '../../components/dialog';
import ScanCardDialog from '../transferCardComponents/scanCardDialog';
import { apiUpdateCardTransferLogStatus } from '../../api';
import { useDispatch } from 'react-redux';
import { cardRequest } from '../../redux/cardReducer';
import { cardTransferRequest } from '../../redux/cardTransferReducer';
import { currentCardRequest } from '../../redux/currentCardReducer';
interface Props {
  currentCard: DetailRecord<CurrentCard>;
}
export default function HomeSurplus({ currentCard }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /**
   * 签收
   * @returns
   */
  async function handleSignFor() {
    let item = currentCard.result!;
    let modalRes = await showConfirm({ title: '签收提示', content: '确认已经收到新卡了吗？' });
    if (modalRes !== 1) return;
    try {
      LoadingMask.show('正在签收...');
      let res = await apiUpdateCardTransferLogStatus(item!.transferId!, CardTransferStatus.waitTransferCard);
      if (res.data.code === 200) {
        showToast(res.data.msg ?? '签收成功');
        dispatch(cardTransferRequest({ forceRefresh: true }));
        dispatch(currentCardRequest({ forceRefresh: true }));
        dispatch(cardRequest({ forceRefresh: true }));
      } else {
        showToast(res.data.msg || '签收失败');
      }
    } catch (e: any) {
      showToast(e.message || '签收失败');
    } finally {
      LoadingMask.hide();
    }
  }
  /**
   * 扫码换卡
   * @param item
   */
  async function handleTransferCard() {
    let item = currentCard.result!;
    let modalRes = await showCustom({ buildContent: (onOk) => <ScanCardDialog onOk={onOk} title="请识别新卡号" /> });
    if (modalRes.res === 0) return;
    if (modalRes.res !== 1) return showToast(modalRes.msg);
    let niccid = modalRes.msg;
    try {
      LoadingMask.show('正在转卡...');
      let res = await apiUpdateCardTransferLogStatus(item!.transferId!, CardTransferStatus.completed, niccid as string);
      if (res.data.code === 200) {
        showToast(res.data.msg ?? '转卡成功');
        dispatch(cardRequest({ forceRefresh: true }));
        dispatch(cardTransferRequest({ forceRefresh: true }));
        dispatch(currentCardRequest({ forceRefresh: true }));
      } else {
        showToast(res.data.msg || '转卡失败');
      }
    } catch (e: any) {
      showToast(e.message || '转卡失败');
    } finally {
      LoadingMask.hide();
    }
  }

  const hasCard = !!currentCard.result?.id;
  let tips = '';
  let btn;
  if (!hasCard) {
    tips = '请先绑定卡片';
  } else if (currentCard.result?.transferId && currentCard.result?.transferStatus === CardTransferStatus.waitSignFor) {
    tips = '新卡已发出，请在收到后确认签收！';
    btn = (
      <Button type="default" size="xs" shape="circle" onClick={handleSignFor}>
        <div className="w-80 d-flex-r ai-center jc-center text-color-9">
          <img className="w-14 h-14" src={main.pic('icon_check_warning.png')} alt="" />
          <div className="mt-1 h-22 flex-1">立即签收</div>
          <img className="h-10 ml-5" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
      </Button>
    );
  } else if (
    currentCard.result?.transferId &&
    currentCard.result?.transferStatus === CardTransferStatus.waitTransferCard
  ) {
    tips = '请按照指引将老卡信息转移到新卡！';
    btn = (
      <Button type="default" size="xs" shape="circle" onClick={handleTransferCard}>
        <div className="w-80 d-flex-r ai-center jc-center text-color-9">
          <img className="w-14 h-14" src={main.pic('icon_check_warning.png')} alt="" />
          <div className="mt-1 h-22 flex-1">立即转卡</div>
          <img className="h-10 ml-5" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
      </Button>
    );
  } else if (!currentCard.result?.theMonth) {
    tips = '还未订购本月套餐,请在下方选择订购！';
    // btn = (
    //   <Button type="default" size="xs" shape="circle">
    //     <div className="w-80 d-flex-r ai-center jc-center text-color-9">
    //       <div className="lh-20 mt-3">订购套餐</div>
    //       <img className="h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
    //     </div>
    //   </Button>
    // );
  } else if (currentCard.result?.cardStatus === CardStatus.preRealName) {
    tips = '还未实名认证,网络不可用！';
    btn = (
      <Button
        type="default"
        size="xs"
        shape="circle"
        onClick={() => navigate('/cardProcess', { state: { step: 3, iccid: currentCard.result!.iccid } })}
      >
        <div className="w-80 d-flex-r ai-center jc-center text-color-9">
          <img className="w-14 h-14" src={main.pic('icon_check_warning.png')} alt="" />
          <div className="mt-1 h-22 flex-1">实名认证</div>
          <img className="h-10 ml-5" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
      </Button>
    );
  }

  // 是否显示不限量
  var theMonthUnlimit = (currentCard?.result?.theMonth?.totalFlow ?? 0) > main.showUnlimited;
  var nextMonthUnlimit = (currentCard?.result?.nextMonth?.totalFlow ?? 0) > main.showUnlimited;
  return (
    <div className="d-flex-c d-shadow overflow-hidden bg-color-white pb-20 border-radius-8 mt-20">
      {!!tips && (
        <div className="d-flex-r ai-center pv-10 ph-10 home-card-info-tips">
          <div className="flex-1 font-size-14">{tips}</div>
          {btn}
        </div>
      )}
      <div
        className="d-flex-r ai-center jc-around mt-20"
        onClick={() => {
          if (!hasCard) {
            navigate('/cardProcess', { state: { step: 1 } });
            return showToast('请先绑定卡片');
          }
          navigate('/cardDetail');
        }}
      >
        <div className="text-center">
          <div className="d-flex-r ai-end jc-center mb-5">
            <div className="font-size-18 font-widget-600 lh-16 mr-2">
              {hasCard && !!currentCard.result?.theMonth
                ? theMonthUnlimit
                  ? '不限量'
                  : formatNumber(currentCard.result?.theMonth?.surplusFlow ?? 0)
                : '--'}
            </div>
            {!theMonthUnlimit && <div className="font-size-12">GB</div>}
          </div>
          <div>本期流量剩余</div>
        </div>
        <div className="text-center">
          <div className="d-flex-r ai-center jc-center mb-5">
            <div className="font-size-18 font-widget-600 lh-16 mr-2">
              {hasCard && !!currentCard.result?.theMonth
                ? formatNumber(currentCard.result?.theMonth?.usedFlow ?? 0)
                : '--'}
            </div>
            <div className="font-size-12">GB</div>
          </div>
          <div>已用流量</div>
        </div>
        <div className="text-center">
          <div className="d-flex-r ai-center jc-center mb-5">
            <div className="font-size-18 font-widget-600 lh-16 mr-2">
              {hasCard && !!currentCard.result?.nextMonth
                ? nextMonthUnlimit
                  ? '不限量'
                  : formatNumber(currentCard.result?.nextMonth?.totalFlow ?? 0)
                : '--'}
            </div>
            {!nextMonthUnlimit && <div className="font-size-12">GB</div>}
          </div>
          <div>下期流量</div>
        </div>
        <div className="text-center">
          <div className="d-flex-r ai-center jc-center mb-5">
            <div className="font-size-18 font-widget-600 lh-16 mr-2">
              {hasCard ? formatNumber(currentCard.result?.cardBalance ?? 0) : '--'}
            </div>
            <div className="font-size-10">元</div>
          </div>
          <div>余额</div>
        </div>
      </div>
    </div>
  );
}
