import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetChargeRenewalList } from '../api';
import { ChargeRenewal, AppStore, RecordList } from '../types';
import { modelParsePrice } from '../utils/utils';

interface RequestParams {
  cardId: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  cardId: string;
  results: Array<ChargeRenewal>;
}

interface FailureParams {
  cardId: string;
  error: string;
}

type ChargeRenewalMap = {
  [key: string]: RecordList<ChargeRenewal>;
};

const chargeRenewalSlice = createSlice({
  name: 'chargeRenewal',
  initialState: {} as ChargeRenewalMap,
  reducers: {
    chargeRenewalRequest: (state: ChargeRenewalMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = true;
      return state;
    },
    chargeRenewalSuccess: (state: ChargeRenewalMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].results = action.payload.results!;
      return state;
    },
    chargeRenewalFailure: (state: ChargeRenewalMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].error = action.payload.error;
      return state;
    },
    chargeRenewalClear: (state: ChargeRenewalMap) => {
      state = {};
      return state;
    },
  },
});

export const { chargeRenewalRequest, chargeRenewalSuccess, chargeRenewalFailure, chargeRenewalClear } =
  chargeRenewalSlice.actions;
export default chargeRenewalSlice.reducer;

export const chargeRenewalEffect = {
  'chargeRenewal/clear': (store: ToolkitStore<AppStore>) => store.dispatch(chargeRenewalClear()),
  'chargeRenewal/chargeRenewalRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams>) => {
    var chargeRenewalList = store.getState().chargeRenewal;
    if (
      chargeRenewalList?.[action.payload.cardId]?.loaded === true &&
      !chargeRenewalList?.[action.payload.cardId]?.error &&
      action?.payload?.forceRefresh !== true
    ) {
      store.dispatch(
        chargeRenewalSuccess({
          cardId: action.payload.cardId,
          results: chargeRenewalList![action.payload.cardId].results!,
        })
      );
    } else {
      try {
        let res = await apiGetChargeRenewalList(action.payload.cardId);
        let data = res.data;
        if (data.code === 200) {
          let list: ChargeRenewal[] = [];
          (data.data ?? []).forEach((i: any) => {
            list.push(
              Object.assign({}, i, { amount: modelParsePrice(i.amount), payAmount: modelParsePrice(i.payAmount) })
            );
          });
          store.dispatch(chargeRenewalSuccess({ cardId: action.payload.cardId, results: list }));
        } else {
          store.dispatch(chargeRenewalFailure({ cardId: action.payload.cardId, error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(chargeRenewalFailure({ cardId: action.payload.cardId, error: e.toString() || '获取失败' }));
      }
    }
  },
};
