import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetAvailableCouponList } from '../api';
import { AppStore, Coupon, RecordList } from '../types';

interface RequestParams {
  cardId: string;
  packDetailId: string;
  number: number;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams extends RequestParams {
  results: Array<Coupon>;
}

interface FailureParams extends RequestParams {
  error: string;
}

type CouponMap = { [x: string]: RecordList<Coupon> };

const availableCouponSlice = createSlice({
  name: 'availableCoupon',
  initialState: {} as CouponMap,
  reducers: {
    availableCouponRequest: (state: CouponMap, action: PayloadAction<RequestParams>) => {
      if (!state[createAvailableCouponKey(action.payload)]) {
        state[createAvailableCouponKey(action.payload)] = { fetching: false, loaded: false };
      }
      state[createAvailableCouponKey(action.payload)].fetching = true;
      return state;
    },
    availableCouponSuccess: (state: CouponMap, action: PayloadAction<SuccessParams>) => {
      if (!state[createAvailableCouponKey(action.payload)]) {
        state[createAvailableCouponKey(action.payload)] = { fetching: false, loaded: false };
      }
      state[createAvailableCouponKey(action.payload)].fetching = false;
      state[createAvailableCouponKey(action.payload)].loaded = true;
      state[createAvailableCouponKey(action.payload)].results = action.payload.results!;
      return state;
    },
    availableCouponFailure: (state: CouponMap, action: PayloadAction<FailureParams>) => {
      if (!state[createAvailableCouponKey(action.payload)]) {
        state[createAvailableCouponKey(action.payload)] = { fetching: false, loaded: false };
      }
      state[createAvailableCouponKey(action.payload)].fetching = false;
      state[createAvailableCouponKey(action.payload)].loaded = true;
      state[createAvailableCouponKey(action.payload)].error = action.payload.error;
      return state;
    },
    availableCouponClear: (state: CouponMap) => {
      state = {};
      return state;
    },
  },
});

export const { availableCouponRequest, availableCouponSuccess, availableCouponFailure, availableCouponClear } =
  availableCouponSlice.actions;
export default availableCouponSlice.reducer;

export const availableCouponEffect = {
  'availableCoupon/clear': (store: ToolkitStore<AppStore>) => store.dispatch(availableCouponClear()),
  'availableCoupon/availableCouponRequest': async (
    store: ToolkitStore<AppStore>,
    action: PayloadAction<RequestParams>
  ) => {
    var couponList = store.getState().availableCoupon[createAvailableCouponKey(action.payload)];
    if (couponList?.loaded === true && !couponList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(availableCouponSuccess({ ...action.payload, results: couponList!.results! }));
    } else {
      try {
        let res = await apiGetAvailableCouponList(
          action.payload.cardId,
          action.payload.packDetailId,
          action.payload.number
        );
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(availableCouponSuccess({ ...action.payload, results: data.data ?? [] }));
        } else {
          store.dispatch(availableCouponFailure({ ...action.payload, error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(availableCouponFailure({ ...action.payload, error: e.toString() || '获取失败' }));
      }
    }
  },
};

export function createAvailableCouponKey(c: RequestParams) {
  return `${c.cardId}_${c.packDetailId}_${c.number}`;
}
