import { useState } from 'react';
import { Card, CardPack } from '../../types';
import PackGridList from '../components/packGridList';
import BuyPackDialog from './buyPackDialog';
interface Props {
  card: Card;
  monthType?: string;
  onOk: () => void;
}
export default function BuyPack({ card, monthType, onOk }: Props) {
  const [pack, setPack] = useState<CardPack>();
  const [dateType, setDateType] = useState(monthType ?? '');

  return (
    <>
      <PackGridList
        card={card}
        monthType={monthType}
        onClick={(v, t) => {
          setPack(v);
          setDateType(t);
        }}
        hidePackTimeType
      />
      {!!pack && (
        <BuyPackDialog
          dateType={dateType}
          card={card}
          pack={pack}
          onOk={onOk}
          onCancel={() => {
            setPack(undefined);
          }}
        />
      )}
    </>
  );
}
