import ReactDOM from 'react-dom/client';
import { delay } from '../../utils/utils';
import main from '../../main';
import { useEffect, useRef, useState } from 'react';

interface CustomProps {
  maskHide?: boolean;
  buildContent: (onOk: (value: unknown) => void) => React.ReactNode;
}

function BottomBox({
  content,
  center,
  maskHide,
  onOk,
}: {
  onOk: (value: unknown) => void;
  content: React.ReactNode;
  center?: boolean;
  maskHide?: boolean;
}) {
  return (
    <div className="bottom-box">
      <div className="mask" onClick={maskHide ? () => onOk(0) : undefined}></div>
      <div className="flex-1"></div>
      <div className="content bw-100">{content}</div>
      {center && <div className="flex-1"></div>}
    </div>
  );
}
export function showBottom({ maskHide, buildContent }: CustomProps): Promise<any> {
  return new Promise((resolve) => {
    let div = document.createElement('div');
    document.body.appendChild(div);
    let root = ReactDOM.createRoot(div);

    document.body.style.overflow = 'hidden';
    const onOk = (v: any) => {
      if (root) {
        root.unmount();
        if (document.body.contains(div)) {
          document.body.removeChild(div);
        }
      }
      document.body.style.overflow = '';
      resolve(v);
    };
    root.render(<BottomBox content={buildContent(onOk)} maskHide={maskHide} onOk={onOk} />);
  });
}

export function showCustom({ buildContent, maskHide }: CustomProps): Promise<any> {
  return new Promise((resolve) => {
    let div = document.createElement('div');
    document.body.appendChild(div);
    let root = ReactDOM.createRoot(div);

    document.body.style.overflow = 'hidden';
    const onOk = (v: any) => {
      if (root) {
        root.unmount();
        if (document.body.contains(div)) {
          document.body.removeChild(div);
        }
      }
      document.body.style.overflow = '';
      resolve(v);
    };
    root.render(<BottomBox content={buildContent(onOk)} center maskHide={maskHide} onOk={onOk} />);
  });
}

interface ConfirmBoxProps {
  title?: string;
  content: React.ReactNode;
  hideConfirmBtn?: boolean;
  hideCancelBtn?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  waitingTime?: number;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface DialogTitleProps {
  title?: string;
  onCancel?: () => void;
}

export function DialogTitle({ title, onCancel }: DialogTitleProps) {
  return (
    <div className="p-10 font-widget-600 bw-100 font-size-16 d-flex-r ai-center">
      <img className="icon-18 opacity-0" src={main.pic('icon_close_black.png')} alt="" />
      <div className="flex-1 text-center">{title ?? '提示'}</div>
      <img className="icon-18" src={main.pic('icon_close_black.png')} alt="" onClick={onCancel} />
    </div>
  );
}

function ConfirmBox({
  title,
  content,
  hideConfirmBtn,
  hideCancelBtn,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  onCancel,
  waitingTime,
}: ConfirmBoxProps) {
  let ref = useRef<{ time: number; timer?: NodeJS.Timer }>({ time: waitingTime ?? 0 });
  let [time, setTime] = useState(waitingTime ?? 0);
  useEffect(() => {
    if (time > 0) {
      startTimer();
    }
    let _timer = ref.current?.timer;
    return () => {
      if (_timer) {
        clearInterval(_timer);
      }
    };
  }, []); // eslint-disable-line

  function startTimer() {
    if (ref.current.timer) {
      return;
    }
    ref.current.timer = setInterval(() => {
      ref.current.time--;
      setTime(ref.current.time);
      if (ref.current.time <= 0) {
        clearInterval(ref.current.timer);
        ref.current.timer = undefined;
      }
    }, 1000);
  }

  function handleAction(idx: number) {
    if (time > 0) return;
    if (idx === 2 && onConfirm) onConfirm();
    else if (onCancel) onCancel();
  }
  return (
    <div className="confirm-box">
      <div className="content">
        <DialogTitle title={title} onCancel={() => handleAction(1)} />
        <div className="text bw-100">{content}</div>
        <div className="d-flex-r border-top bw-100">
          {!hideCancelBtn && (
            <div className="flex-1 p-10 text-center border-right" onClick={() => handleAction(1)}>
              {cancelBtnText ?? '取消'}
            </div>
          )}
          {!hideConfirmBtn && (
            <div
              className={`flex-1 p-10 text-center text-color-${time > 0 ? '9' : 'primary'}`}
              onClick={() => handleAction(2)}
            >
              {confirmBtnText ?? '确定'}
              {time > 0 ? `(${time}s)` : ''}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export async function showConfirm({
  title,
  content,
  hideConfirmBtn,
  hideCancelBtn,
  confirmBtnText,
  cancelBtnText,
  waitingTime,
}: ConfirmBoxProps): Promise<any> {
  return new Promise((resolve) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = ReactDOM.createRoot(div);
    document.body.style.overflow = 'hidden';
    const onOk = (v: any) => {
      if (root) {
        root.unmount();
        if (document.body.contains(div)) {
          document.body.removeChild(div);
        }
        document.body.style.overflow = '';
        resolve(v);
      }
    };
    root.render(
      <ConfirmBox
        title={title}
        content={content}
        hideConfirmBtn={hideConfirmBtn}
        hideCancelBtn={hideCancelBtn}
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        waitingTime={waitingTime}
        onConfirm={() => onOk(1)}
        onCancel={() => onOk(0)}
      />
    );
  });
}

interface ToastBoxProps {
  msg: string;
}

function ToastBox({ msg }: ToastBoxProps) {
  return (
    <div className="toast-box">
      <div className="toast">{msg}</div>
    </div>
  );
}
type ToastClose = () => void;
export function showToast(msg: string): ToastClose {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(<ToastBox msg={msg} />);
  let ref: { root?: ReactDOM.Root; div: HTMLDivElement } = { root, div };

  setTimeout(() => {
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  }, 2000);
  return () => {
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  };
}

export class LoadingMask {
  private static hideList: Array<() => void> = [];
  static hide = () => {
    for (let fn of LoadingMask.hideList) {
      fn();
      LoadingMask.hideList.shift();
    }
  };
  static show = (tips?: string) => {
    let fn = loadingMaskShow(tips ?? '正在加载...');
    LoadingMask.hideList.push(fn);
  };
}

function loadingMaskShow(tips: string) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  document.body.style.overflow = 'hidden';
  root.render(
    <div className="loading-mask">
      <div className="loading-mask-center">
        <img className="loading-pic rotating" src={main.pic('icon_loading.png')} alt="" />
        <div className="loading-tips">{tips}</div>
      </div>
    </div>
  );
  let ref: { root?: ReactDOM.Root; div: HTMLDivElement } = { root, div };
  return () => {
    document.body.style.overflow = '';
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  };
}

export async function copyToClipboard(text: string, onSuccess?: () => void) {
  if (!text) {
    return showToast('内容为空,不可复制');
  }
  let div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(
    <div className={`copy-to-clipboard`}>
      <textarea id="copyToClipboard" defaultValue={text} className="text-area"></textarea>
    </div>
  );
  await delay(200);
  let ta: HTMLTextAreaElement | undefined = document.getElementById('copyToClipboard') as HTMLTextAreaElement;
  if (ta) {
    ta.select();
    let res = document.execCommand('copy');
    if (res) {
      if (onSuccess) {
        onSuccess();
      } else {
        showToast('复制成功');
      }
    }
  }
  await delay(500);
  root.unmount();
  document.body.removeChild(div);
}
