import React from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import main from '../main';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../types';
import { APIProtocolType } from '../utils/dict';
import { LoadingMask, showConfirm, showToast } from '../components/dialog';
import { apiDestroyAccount } from '../api';
import { useNavigate } from 'react-router-dom';
import { logoutRequest } from '../redux/logoutReducer';

export default function DestroyAccountPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const protocol = useSelector((state: AppStore) => state.protocol[APIProtocolType.destroyAccount]);

  async function handleDestroy() {
    let d = '';
    let modalRes = await showConfirm({
      title: '警告',
      content: (
        <div className="p-10 text-left">
          <div>确定要注销账号吗?注销后所有信息都将被销毁且不可找回。</div>
          <div>
            在下方输入“<span className="text-color-red">立即注销</span>”，并点击确定后，将执行注销操作。
          </div>
          <div className="bw-100 border-radius-20 p-10 border mt-20">
            <input className="bw-100" type="text" placeholder="立即注销" onChange={(e) => (d = e.target.value)} />
          </div>
        </div>
      ),
    });
    if (!modalRes || d !== '立即注销') return;

    try {
      LoadingMask.show('正在注销...');

      let res = await apiDestroyAccount();
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '注销成功');
        dispatch(logoutRequest());
        navigate('/login', { replace: true });
      } else {
        showToast(data.msg ?? '注销失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '注销失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page
      className="d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c overflow-hidden bg-color-gray p-20"
      appBar={<AppBar className="bg-color-gray" title="账号安全" />}
    >
      <div className="bg-color-white ph-10 border-radius-4">
        <div className="d-flex-r pv-14 ai-center" onClick={handleDestroy}>
          <div className="text-color-9 flex-1 text-color-red">账号注销</div>
          <img className="w-7 h-12 ml-10" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
      </div>
      {!!protocol.result?.textContent && <div className="font-size-16 font-widget-600 mt-100">注销须知</div>}
      {!!protocol.result?.textContent && (
        <div
          className="flex-1 overflow-y mt-10"
          dangerouslySetInnerHTML={{ __html: protocol.result?.textContent }}
        ></div>
      )}
    </Page>
  );
}
