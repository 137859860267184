import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiGetBalance, apiPreOrder, apiUnifiedPay } from '../../api';
import Button from '../../components/button';
import { LoadingMask, showBottom, showConfirm, showToast } from '../../components/dialog';
import main from '../../main';
import { availableCouponClear } from '../../redux/availableCouponReducer';
import { buyPackLogRequest } from '../../redux/buyPackLogReducer';
import { cardRequest } from '../../redux/cardReducer';
import { couponRequest } from '../../redux/couponReducer';
import { currentCardRequest } from '../../redux/currentCardReducer';
import { incomeRequest } from '../../redux/incomeReducer';
import { orderRequest } from '../../redux/orderReducer';
import { AppStore, Card, CardPack, ChargeRenewal } from '../../types';
import { PackageType, PayType } from '../../utils/dict';
import showPayPage from '../../utils/pay';
import { modelParsePrice } from '../../utils/utils';
import ChargeRenewalSelect from '../components/chargePremoney';
import CouponSelect from '../components/couponSelect';
import PayTypeSelect from '../components/payTypeSelect';
import ValidatePasswordDialog from '../components/validatePasswordDialog';
interface Props {
  card: Card;
  pack: CardPack;
  dateType: string;
  onCancel: () => void;
  onOk: () => void;
}
export default function BuyPackDialog({ card, pack, dateType, onCancel, onOk }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payTypes = useSelector((state: AppStore) => state.payTypeConfig);
  const [payType, setPayType] = useState('');
  const [preMoney, setPreMoney] = useState<ChargeRenewal>();
  const [balance, setBalance] = useState(0);
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  const [ifPwd, setIfPwd] = useState(false);
  const [coupons, setCoupons] = useState<string[]>([]);
  const [orderInfo, setOrderInfo] = useState<any>();
  const [wxReady, setWxReady] = useState(false);
  // 预存金额获取
  const renewalList = useSelector((state: AppStore) => state.chargeRenewal?.[card.id!]);
  // 是否允许不足月购买
  const isFull = pack?.isFull === 'Y' && pack.packageType === PackageType.basicpackage && dateType === 'currMonth';

  useEffect(() => {
    loadUserBalance();
    document.body.style.overflow = 'hidden';

    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => setWxReady(true), false);
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => setWxReady(true));
      }
    } else {
      setWxReady(true);
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!payType && (payTypes.results?.length ?? 0) > 0) {
      setPayType(payTypes.results![0].payType!);
    }
  }, [payTypes]); // eslint-disable-line

  useEffect(() => {
    preOrder();
  }, [coupons]); // eslint-disable-line

  async function loadUserBalance() {
    try {
      var res = await apiGetBalance(card.id!);
      var data = res.data;
      if (data.code === 200) {
        var _data = data.data;
        setBalance(modelParsePrice(_data.availableAmount));
        setBalanceLoaded(true);
        setIfPwd(_data.isPayPassword === true);
      }
    } catch (e) {}
  }
  async function preOrder() {
    try {
      var res = await apiPreOrder(
        pack.id!,
        card.id!,
        coupons,
        dateType === 'currMonth' ? 'now' : 'next',
        isFull ? 2 : 1
      );
      let data = res.data;
      if (data.code === 200) {
        let _orderInfo = data.data;
        _orderInfo.salePrice = modelParsePrice(_orderInfo.salePrice);
        _orderInfo.orderPrice = modelParsePrice(_orderInfo.orderPrice);
        _orderInfo.realPrice = modelParsePrice(_orderInfo.realPrice);
        _orderInfo.couponPrice = modelParsePrice(_orderInfo.couponPrice);
        setOrderInfo(_orderInfo);
      } else {
        showToast(data.msg ?? '处理失败，请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '处理失败，请重试');
    }
  }

  async function handlePay() {
    if (!main.openId) {
      return showToast('授权失败,请在微信内打开');
    }
    if (!balanceLoaded) {
      return showToast('获取余额失败,请重新进入');
    }

    if (!renewalList?.loaded) {
      return showToast('正在获取预存数据,请重试');
    }

    if (
      renewalList?.results?.length &&
      payType !== PayType.balance &&
      (preMoney?.amount ?? 0) < (orderInfo?.realPrice ?? 0)
    ) {
      return showToast('预存费用不能低于套餐金额');
    }

    let pwd: string = '';
    if (payType === PayType.balance) {
      if (!ifPwd) {
        let res = await showConfirm({
          title: '提示',
          content: '请设置操作密码',
          confirmBtnText: '去设置',
        });
        if (res !== 1) return;
        navigate('/passwordEdit');
        return;
      }
      let res = await showBottom({
        maskHide: true,
        buildContent: (onOk) => <ValidatePasswordDialog price={orderInfo.realPrice} onOk={onOk} />,
      });
      if (!res) return;
      pwd = res;
    }
    let price =
      (payType === PayType.balance
        ? orderInfo?.realPrice
        : preMoney?.payAmount ?? preMoney?.amount ?? orderInfo?.realPrice) ?? 0;
    try {
      LoadingMask.show('正在提交...');

      var body: any = {
        cardId: card.id!,
        cardPackDetailId: pack.id!,
        couponCodeIds: coupons,
        dateType: dateType === 'currMonth' ? 'now' : 'next',
        payType: price > 0 ? payType : PayType.balance,
        openId: main.openId,
        appId: main.appId,
        number: isFull ? 2 : 1,
      };
      if (payType === PayType.balance) {
        body.payPassword = pwd;
      } else {
        body.cardPayDetailId = preMoney?.id;
      }

      let res = await apiUnifiedPay(body);
      let data = res.data;
      if (data.code === 200) {
        if (payType === PayType.balance || price === 0) {
          paySuccess();
          onOk();
        } else if (payType === PayType.hsmWxJsPay || payType === PayType.aliPage) {
          //  惠收米 收银台支付 第一章
          let _data = data.data;
          let payRes = await showPayPage(_data, payType);
          if (payRes.code === 300) {
            if (payRes.url) {
              LoadingMask.hide();
              window.location.href = payRes.url;
              // await showConfirm({
              //   title: '提示',
              //   content: '是否支付完成?',
              //   confirmBtnText: '支付完成',
              //   cancelBtnText: '未完成',
              // });
              // paySuccess();
              // navigate('/pack-order-list', { replace: true });
            }
            showToast(payRes.msg);
          } else if (payRes.code === 500) {
            showToast(payRes.msg);
          } else {
            showToast('数据还在准备,请稍后重试');
          }
        } else {
          if (wxReady) {
            let _data = data.data;
            let payRes = await showPayPage(_data, payType);

            if (payRes.code === 200) {
              paySuccess();
              onOk();
            } else if (payRes.code === 300) {
              if (payRes.url) {
                LoadingMask.hide();
                navigate('/iframe', { state: { url: payRes.url, title: '银联支付', pay: true } });
              }
              showToast(payRes.msg);
            } else if (payRes.code === 500) {
              showToast(payRes.msg);
            }
          } else {
            showToast('数据还在准备,请稍后重试');
          }
        }
      } else {
        showToast(data.msg ?? '下单失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '下单失败');
    } finally {
      LoadingMask.hide();
    }
  }

  function paySuccess() {
    dispatch(currentCardRequest({ forceRefresh: true }));
    dispatch(cardRequest({ forceRefresh: true }));
    dispatch(orderRequest({ cardId: card.id!, forceRefresh: true }));
    dispatch(buyPackLogRequest({ forceRefresh: true }));
    dispatch(incomeRequest({ forceRefresh: true }));
    dispatch(availableCouponClear());
    dispatch(couponRequest({ cardId: card.id!, forceRefresh: true }));
  }

  let startAt = dayjs(pack.startEffectTime);
  let endAt = dayjs(pack.endEffectTime);
  let diff = endAt.diff(startAt, 'day');

  let price =
    (payType === PayType.balance
      ? orderInfo?.realPrice
      : preMoney?.payAmount ?? preMoney?.amount ?? orderInfo?.realPrice) ?? 0;
  return (
    <div className="pos-f l-0 r-0 t-0 b-0 z-300 d-flex-c">
      <div className="pos-a l-0 t-0 r-0 b-0 z-0 bg-color-3-t3" onClick={onCancel}></div>
      <div className="flex-1"></div>
      <div className="pos-r z-10 vh-80 overflow-hidden bg-color-white border-radius-tl-18 border-radius-tr-18 p-10 d-flex-c">
        <div className="d-flex-r">
          <img className="w-60 mr-10" src={main.pic('icon_pack.png', true)} alt="" />
          <div className="flex-1 h-60 d-flex-c jc-around">
            <div className="font-size-14 font-widget-600">{pack.name}</div>
            <div>
              <span className="font-size-14">{diff}</span>
              <span className="mr-10">天有效</span>
              <span>售价</span>
              <span className="font-size-14">{pack.discountPrice ? pack.discountPrice : pack.salePrice}</span>元
            </div>
          </div>
        </div>
        <div className="flex-1 h-1 overflow-y">
          <CouponSelect
            className="mt-10 ph-14"
            cardId={card.id!}
            packDetailId={pack.id!}
            number={1}
            values={coupons}
            onChange={(v) => setCoupons(v)}
          />
          {renewalList?.results?.length && <div className="font-size-14 font-widget-600 mt-10">请选择充值面额</div>}
          <div className="pl-10">
            <ChargeRenewalSelect
              disabled={payType === PayType.balance}
              cardId={card.id!}
              value={preMoney?.id}
              minPrice={orderInfo?.orderPrice ?? pack.discountPrice ?? pack.salePrice!}
              onChange={(v) => {
                setPreMoney(v);
              }}
            />
          </div>
          <div className="font-size-14 font-widget-600 mt-10">请选择支付方式</div>
          <PayTypeSelect
            className="mh-10"
            useBalance
            balance={balance}
            value={payType}
            onChange={(v) => setPayType(v)}
            cardId={card.id!}
          />
        </div>
        <div className="ph-10 pt-10 ">
          <Button type="primary" size="md" shape="circle" long onClick={handlePay}>
            立即支付{`￥${price}`}
          </Button>
        </div>
      </div>
    </div>
  );
}
