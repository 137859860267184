interface Option {
  label: string;
  value: string;
}
interface Props {
  options: Option[];
  value: string;
  white?: boolean;
  onChange: (value: string) => void;
}
export default function TabBar({ options, value, white, onChange }: Props) {
  return (
    <div className={`tab-bar-block${white ? '-white' : ''}`}>
      {options.map((i, idx) => (
        <div
          key={i.value + idx}
          className={`tab-bar-item ${i.value === value ? 'active' : ''}`}
          onClick={() => onChange(i.value)}
        >
          <div className="label">{i.label}</div>
          <div className="active-line" />
        </div>
      ))}
    </div>
  );
}
