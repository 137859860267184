import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiGetCommonCaptcha, apiLogin } from '../api';
import AppBar from '../components/appBar';
import CaptchaInput from '../components/captchaInput';
import Page from '../components/page';
import main, { AppLocalKey } from '../main';
import { userInfoRequest } from '../redux/userInfoReducer';
import { delay, validateTel } from '../utils/utils';
import { LoadingMask, showToast } from '../components/dialog';

const time = 60;

export default function LoginCodePage() {
  // test 测试用，不会真的调发验证码接口，只走流程
  const { username, test } = useLocation().state;
  const timerRef = useRef<{ timer?: NodeJS.Timeout; time: number }>({ time: 0 });
  const [showTime, setShowTime] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!validateTel(username)) {
      showToast('请输入正确的手机号');
      navigate(-1);
    } else {
      let toNext = localStorage.getItem(AppLocalKey.codePageToNext);
      if (toNext !== 'true') {
        handleReSend();
      } else {
        timerRef.current.time = parseInt(localStorage.getItem(AppLocalKey.codePageTime) ?? '0');
        if (timerRef.current.time > 0) {
          startTimer();
        }
      }
      localStorage.removeItem(AppLocalKey.codePageTime);
      localStorage.removeItem(AppLocalKey.codePageToNext);
    }
    const timerRefs = timerRef.current;
    return () => {
      if (timerRefs.timer) {
        clearTimeout(timerRefs.timer);
      }
    };
  }, []); // eslint-disable-line

  function startTimer() {
    if (timerRef.current.timer) {
      return;
    }
    timerRef.current.timer = setInterval(() => {
      timerRef.current.time -= 1;
      setShowTime(timerRef.current.time);
      if (timerRef.current.time <= 0) {
        clearInterval(timerRef.current.timer);
        timerRef.current.timer = undefined;
        return;
      }
    }, 1000);
  }

  async function handleReSend() {
    if (showTime > 0) return;
    if (test) {
      timerRef.current.time = time;
      startTimer();
      showToast('发送成功');
      return;
    }
    try {
      LoadingMask.show();
      let res = await apiGetCommonCaptcha(username);
      let data = res.data;
      if (data.code === 200) {
        timerRef.current.time = time;
        startTimer();
      } else {
        showToast(data.msg || '发送失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '验证码发送失败,请稍后再试');
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleChange(v: string) {
    if (v.length === 4) {
      try {
        LoadingMask.show('正在登录...');
        let res: AxiosResponse<any, any> = await apiLogin(username, test ? '36987' : v);

        let data = res.data;

        if (data.code === 200) {
          let token = data.data.access_token;
          main.loginToken = token;
          localStorage.setItem(AppLocalKey.loginToken, token);
          localStorage.setItem(AppLocalKey.loginUsername, username);
          dispatch(userInfoRequest());
          await delay(1000);
          let historyPhone = localStorage.getItem(AppLocalKey.historyPhone) ?? '';
          if (!!historyPhone) {
            if (!historyPhone.includes(username)) {
              historyPhone = `${username},` + historyPhone;
            }
          } else {
            historyPhone = username;
          }
          historyPhone = historyPhone.split(',').splice(0, 5).join(',');
          localStorage.setItem(AppLocalKey.historyPhone, historyPhone);
          showToast(data.msg ?? '登录成功');
          navigate('/', { replace: true });
        } else {
          showToast(data.msg || '登录失败');
        }
      } catch (e: any) {
        showToast(e.message ?? '登录失败');
      } finally {
        LoadingMask.hide();
      }
    }
  }

  function handleToCustomer() {
    localStorage.setItem(AppLocalKey.codePageToNext, 'true');
    localStorage.setItem(AppLocalKey.codePageTime, timerRef.current.time + '');
    navigate('/customer', { state: { tel: username, from: '登录' } });
  }

  function handleCardLogin() {
    localStorage.setItem(AppLocalKey.codePageToNext, 'true');
    localStorage.setItem(AppLocalKey.codePageTime, timerRef.current.time + '');
    navigate('/loginCard', { state: { username } });
  }

  return (
    <Page className="bg-color-white" contentClassName="bw-100 bh-100 d-flex-c p-32" appBar={<AppBar title="" />}>
      <div className="flex-1"></div>
      <div className="font-size-18 mb-20">安全验证</div>
      <div className="text-color-9 mb-5">验证码已通过短信发送至：</div>
      <div className="mb-50">{username.replace(/^(\d{3})(\d{4})(\d{4,})$/, '$1****$3')}</div>
      <div className="d-flex-r mb-10">
        <div className="flex-1 text-color-9">请输入获取的验证码</div>
        <div className={showTime > 0 ? 'text-color-9' : 'text-color-primary'} onClick={handleReSend}>
          再次发送{showTime > 0 && <span className="text-color-primary">{`(${showTime}s)`}</span>}
        </div>
      </div>
      <CaptchaInput onChange={handleChange} />
      <div className="mt-50">
        收不到验证码怎么办？
        <span className="text-color-primary mr-5" onClick={handleToCustomer}>
          联系客服
        </span>
        或者
        <span className="text-color-primary ml-5" onClick={handleCardLogin}>
          卡号登录
        </span>
      </div>
      <div className="flex-5"></div>
    </Page>
  );
}
