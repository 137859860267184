import React, { useState } from 'react';
import PayTypeSelect from './payTypeSelect';
import Button from '../../components/button';
interface Props {
  cardId: string;
  onOk: (payType?: string) => void;
}
export default function PayTypeDialog({ cardId, onOk }: Props) {
  const [selected, setSelected] = useState('');
  return (
    <div className="pos-f l-0 r-0 t-0 b-0 z-300 d-flex-c">
      <div className="pos-a l-0 t-0 r-0 b-0 z-0 bg-color-3-t3" onClick={() => onOk()}></div>
      <div className="flex-1"></div>
      <div className="pos-r z-10 overflow-hidden bg-color-white border-radius-tl-18 border-radius-tr-18 p-10 d-flex-c">
        <div className="text-center p-10 font-size-16">选择支付方式</div>
        <PayTypeSelect cardId={cardId} value={selected} useBalance={false} onChange={setSelected} />
        <div className="ph-20 pv-10 mt-20">
          <Button type="primary" size="md" shape="circle" long onClick={() => onOk(selected)}>
            确定
          </Button>
        </div>
      </div>
    </div>
  );
}
