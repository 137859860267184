import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetAddressList } from '../api';
import { Address, AppStore, RecordList } from '../types';

interface RequestParams {
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  results: Array<Address>;
}

interface FailureParams {
  error: string;
}

const addressSlice = createSlice({
  name: 'address',
  initialState: { fetching: false, loaded: false } as RecordList<Address>,
  reducers: {
    addressRequest: (state: RecordList<Address>, action: PayloadAction<RequestParams | undefined>) => {
      state.fetching = true;
      return state;
    },
    addressSuccess: (state: RecordList<Address>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.results!;
      return state;
    },
    addressFailure: (state: RecordList<Address>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    addressClear: (state: RecordList<Address>) => {
      state = { fetching: false, loaded: false };
      return state;
    },
  },
});

export const { addressRequest, addressSuccess, addressFailure, addressClear } = addressSlice.actions;
export default addressSlice.reducer;

export const addressEffect = {
  'address/clear': (store: ToolkitStore<AppStore>) => store.dispatch(addressClear()),
  'address/addressRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams | undefined>) => {
    var addressList = store.getState().address;
    if (addressList?.loaded === true && !addressList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(addressSuccess({ results: addressList!.results! }));
    } else {
      try {
        let res = await apiGetAddressList();
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(addressSuccess({ results: data.rows ?? [] }));
        } else {
          store.dispatch(addressFailure({ error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(addressFailure({ error: e.toString() || '获取失败' }));
      }
    }
  },
};
