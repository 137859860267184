import React, { useEffect, useRef, useState } from 'react';
import Button from '../button';
import Slider from 'react-slick';
import main from '../../main';
interface Props {
  onOk: (v: number) => void;
  steps: Array<{ pic: string; title: string }>;
  disableTimer?: boolean;
}

const time = 3;
export default function RealNameGrideDialog({ onOk, steps, disableTimer }: Props) {
  const ref = useRef<{ timer?: NodeJS.Timeout; time: number }>({ time });
  const [showTime, setShowTime] = useState(time);
  useEffect(() => {
    if (!disableTimer) {
      startTimer();
    } else {
      ref.current.time = 0;
      setShowTime(0);
    }
  }, []); // eslint-disable-line

  function startTimer() {
    if (!ref.current.timer) {
      ref.current.timer = setInterval(() => {
        if (ref.current.time > 0) {
          ref.current.time--;
          setShowTime(ref.current.time);
        } else {
          clearInterval(ref.current.timer);
        }
      }, 1000);
    }
  }

  function handleClose() {
    if (showTime > 0) return;
    onOk(0);
  }
  return (
    <div className="realname-gride-dialog">
      <div className="flex-1"></div>
      <div className="text-right pr-10 mb-20">
        <img className="icon-24" src={main.pic('icon_close_white.png')} alt="" onClick={handleClose} />
      </div>
      <div className="step-img-block bg-color-white border-radius-10 d-flex-c">
        <div className="font-size-16 pl-10 pt-10 text-center">实名流程</div>
        <Slider autoplay dots={false} arrows={false} infinite speed={500} touchMove>
          {steps.map((item, idx: number) => (
            <div key={item.pic + idx} className="slider-img-block">
              <div className="h-40 mt-10 mb-10">
                {item.title.split(',').map((item: any, index: number) => (
                  <div key={item + index}>
                    {item}
                    <br />
                  </div>
                ))}
              </div>
              <img src={main.pic(item.pic)} alt="" className="slider-img" />
              <div className="slider-index">
                {idx + 1} / {steps.length}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="vw-100 d-flex-c ai-center mt-10">
        <Button type={showTime > 0 ? 'gray' : 'primary'} size="md" onClick={handleClose}>
          <div className="w-140">{showTime > 0 ? `关闭${showTime}s` : `关闭`}</div>
        </Button>
      </div>
      <div className="flex-1"></div>
    </div>
  );
}
