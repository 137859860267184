import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { AppStore, CardSlider, CurrentCard } from '../../types';
import { CardSliderType } from '../../utils/dict';
import { dataListStatus } from '../../utils/utils';
import { showToast } from '../../components/dialog';

interface Props {
  card?: CurrentCard;
}
export default function HomeSlider({ card }: Props) {
  const navigate = useNavigate();
  let cardSliderList = useSelector((state: AppStore) => state.cardSlider);

  function handleItemClick(item: CardSlider) {
    if (item.jumpType === CardSliderType.outLink && item.jumpUrl?.startsWith('http')) {
      window.open(item.jumpUrl);
    } else if (item.jumpType === CardSliderType.inLink && !!item.jumpUrl) {
      navigate(item.jumpUrl!, { state: { canBack: true } });
    } else if (item.jumpType === CardSliderType.payLink) {
      if (!!card?.id) {
        navigate(`/charge?id=${card?.id}`, { state: { canBack: true } });
      } else {
        showToast('当前暂无卡片');
      }
    } else if (item.jumpType === CardSliderType.payActivity) {
      navigate('/chargeFirst');
    }
  }

  let el = dataListStatus({ list: cardSliderList });
  if (el) {
    return null;
  }

  let _list = [...cardSliderList.results!].sort((a, b) => (a.orderNum ?? 0) - (b.orderNum ?? 0));

  if (_list.length === 1) {
    return (
      <img
        className="w-350 h-150 border-radius-8 mt-30"
        src={_list[0].imageUrl!}
        alt=""
        onClick={() => handleItemClick(_list[0])}
      />
    );
  }

  return (
    <Slider
      className="w-350 h-150 overflow-hidden mt-30"
      autoplay
      dots={false}
      arrows={false}
      infinite
      speed={1000}
      touchMove
    >
      {_list!.map((i, idx) => (
        <img
          key={i.id + idx}
          className="w-350 h-150 border-radius-8"
          src={i.imageUrl!}
          alt=""
          onClick={() => handleItemClick(i)}
        />
      ))}
    </Slider>
  );
}
