import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '../components/appBar';
import Page from '../components/page';
import TabBar from '../components/tabBar';
import { orderRequest } from '../redux/orderReducer';
import { AppStore, Order } from '../types';
import { OrderStatus } from '../utils/dict';
import { dataListStatus, formatNumber } from '../utils/utils';
import DefaultNoData from '../components/defaultPage/defaultNoData';
import main from '../main';
import { useNavigate } from 'react-router-dom';

export default function OrderListPage() {
  const dispatch = useDispatch();
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const orderList = useSelector((state: AppStore) => state.order[currentCard?.result?.id ?? '']);
  const [tabValue, setTabValue] = useState(OrderStatus.all);
  useEffect(() => {
    if (currentCard.result?.id) {
      dispatch(orderRequest({ cardId: currentCard.result.id }));
    }
  }, [currentCard]); // eslint-disable-line

  let _centerView: any = [];
  let statusView = dataListStatus({ list: orderList });
  if (statusView) {
    _centerView = [statusView];
  } else {
    let list: Order[] = orderList.results!.filter((i) => {
      if (tabValue === 'all') return true;

      return tabValue === i.status!;
    });
    if (list.length <= 0) {
      _centerView = [<DefaultNoData />];
    } else {
      _centerView = list.map((item) => <PackOrderItem key={item.id} item={item} />);
    }
  }
  return (
    <Page
      className="bg-color-gray d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={<AppBar title="订单管理" className="bg-color-gray" />}
    >
      <TabBar
        value={tabValue}
        options={OrderStatus.getTypeList().map((k) => ({ label: OrderStatus.getTypeStr(k), value: k }))}
        onChange={(v) => setTabValue(v)}
      />
      <div className="flex-1 h-1 overflow-y">{_centerView}</div>
    </Page>
  );
}

function PackOrderItem({ item }: { item: Order }) {
  const navigate = useNavigate();
  return (
    <div
      className="mv-10 mh-20 bg-color-white border-radius-10 d-shadow p-10"
      onClick={() => navigate('/orderDetail/' + item.id, { state: { canBack: true } })}
    >
      <div className="d-flex-r ai-center mb-10">
        <div className="text-color-9 flex-1">订单号:{item.id}</div>
        <div className={`text-color-${OrderStatus.getTypeColor(item.status)}`}>
          {OrderStatus.getTypeStr(item.status)}
        </div>
      </div>
      <div className="d-flex-r">
        <img className="w-40 mr-10" src={main.pic('icon_pack.png', true)} alt="" />
        <div className="flex-1 d-flex-c jc-around">
          <div>{item.packDetailName}</div>
          <div className="d-flex-r ai-center">
            <div className="flex-1">￥{formatNumber(item.totalPrice)}</div>
            <div className="text-color-9">X1</div>
          </div>
        </div>
      </div>
      <div className="d-flex-r mt-10">
        <div className="flex-1 text-color-9">卡号:{item.iccid}</div>
        <div className={item.status === OrderStatus.success ? 'text-color-green' : 'text-color-origin'}>
          {item.status === OrderStatus.success ? '实' : '侍'}付款￥{formatNumber(item.realPrice)}
        </div>
      </div>
    </div>
  );
}
