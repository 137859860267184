import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetPayTypeConfigList } from '../api';
import { AppStore, PayTypeConfig, RecordList } from '../types';

// 请求成功时传送的参数
interface SuccessParams {
  list: Array<PayTypeConfig>;
}
// 请求失败时传送的参数
interface FailureParams {
  error: string;
}

const payTypeConfigSlice = createSlice({
  name: 'payTypeConfig',
  initialState: { fetching: false, loaded: false } as RecordList<PayTypeConfig>,
  reducers: {
    payTypeConfigRequest: (state: RecordList<PayTypeConfig>, action: PayloadAction) => {
      state.fetching = true;
      return state;
    },
    payTypeConfigSuccess: (state: RecordList<PayTypeConfig>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.list;
      return state;
    },
    payTypeConfigFailure: (state: RecordList<PayTypeConfig>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    payTypeConfigClear: (state: RecordList<PayTypeConfig>) => {
      state = { fetching: false, loaded: false } as RecordList<PayTypeConfig>;
      return state;
    },
  },
});

export const { payTypeConfigRequest, payTypeConfigSuccess, payTypeConfigFailure, payTypeConfigClear } =
  payTypeConfigSlice.actions;
export default payTypeConfigSlice.reducer;

export const payTypeConfigEffect = {
  'payTypeConfig/clear': (store: ToolkitStore<AppStore>) => store.dispatch(payTypeConfigClear()),
  'payTypeConfig/payTypeConfigRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction) => {
    try {
      let res = await apiGetPayTypeConfigList();
      let data = res.data;
      if (data.code === 200) {
        store.dispatch(payTypeConfigSuccess({ list: data.rows ?? [] }));
      } else {
        store.dispatch(payTypeConfigFailure({ error: data.msg || '获取失败' }));
      }
    } catch (e: any) {
      store.dispatch(payTypeConfigFailure({ error: e.toString() || '获取失败' }));
    }
  },
};
