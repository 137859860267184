import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetFirstActivityList } from '../api';
import { AppStore, ChargeFirst, DetailRecord } from '../types';
import { modelParsePrice } from '../utils/utils';

interface RequestParams {
  cardId: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  cardId: string;
  result: ChargeFirst;
}

interface FailureParams {
  cardId: string;
  error: string;
}

type ChargeFirstMap = {
  [key: string]: DetailRecord<ChargeFirst>;
};

const chargeFirstSlice = createSlice({
  name: 'chargeFirst',
  initialState: {} as ChargeFirstMap,
  reducers: {
    chargeFirstRequest: (state: ChargeFirstMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = true;
      return state;
    },
    chargeFirstSuccess: (state: ChargeFirstMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].result = action.payload.result!;
      return state;
    },
    chargeFirstFailure: (state: ChargeFirstMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].error = action.payload.error;
      return state;
    },
    chargeFirstClear: (state: ChargeFirstMap) => {
      state = {};
      return state;
    },
  },
});

export const { chargeFirstRequest, chargeFirstSuccess, chargeFirstFailure, chargeFirstClear } =
  chargeFirstSlice.actions;
export default chargeFirstSlice.reducer;

export const chargeFirstEffect = {
  'chargeFirst/clear': (store: ToolkitStore<AppStore>) => store.dispatch(chargeFirstClear()),
  'chargeFirst/chargeFirstRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams>) => {
    var chargeFirstList = store.getState().chargeFirst;
    if (
      chargeFirstList?.[action.payload.cardId]?.loaded === true &&
      !chargeFirstList?.[action.payload.cardId]?.error &&
      action?.payload?.forceRefresh !== true
    ) {
      store.dispatch(
        chargeFirstSuccess({
          cardId: action.payload.cardId,
          result: chargeFirstList![action.payload.cardId].result!,
        })
      );
    } else {
      try {
        let res = await apiGetFirstActivityList(action.payload.cardId);
        let data = res.data;
        if (data.code === 200) {
          let first: ChargeFirst = data.data ?? {};
          first.payDetails = (first.payDetails ?? []).map((i) => {
            return Object.assign({}, i, {
              amount: modelParsePrice(i.amount?.toString() ?? ''),
              price: modelParsePrice(i.price?.toString() ?? ''),
            });
          });
          store.dispatch(chargeFirstSuccess({ cardId: action.payload.cardId, result: first }));
        } else {
          store.dispatch(chargeFirstFailure({ cardId: action.payload.cardId, error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(chargeFirstFailure({ cardId: action.payload.cardId, error: e.toString() || '获取失败' }));
      }
    }
  },
};
