import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetCardInfo, apiIsActive } from '../api';
import { AppStore, CurrentCard, DetailRecord } from '../types';
import { modelParseFlow, modelParsePrice } from '../utils/utils';
import main from '../main';
import { setTheme } from './themeReducer';
import { chargeFirstRequest } from './chargeFirstReducer';
import { RechargeType } from '../utils/dict';

interface RequestParams {
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  record: CurrentCard;
}

interface FailureParams {
  error: string;
}

const currentCardSlice = createSlice({
  name: 'currentCard',
  initialState: { fetching: false, loaded: false } as DetailRecord<CurrentCard>,
  reducers: {
    currentCardRequest: (state: DetailRecord<CurrentCard>, action: PayloadAction<RequestParams | undefined>) => {
      state.fetching = true;
      return state;
    },
    currentCardSuccess: (state: DetailRecord<CurrentCard>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.result = action.payload.record!;
      return state;
    },
    currentCardFailure: (state: DetailRecord<CurrentCard>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    currentCardClear: (state: DetailRecord<CurrentCard>) => {
      state = { fetching: false, loaded: false } as DetailRecord<CurrentCard>;
      return state;
    },
  },
});

export const { currentCardRequest, currentCardSuccess, currentCardFailure, currentCardClear } =
  currentCardSlice.actions;
export default currentCardSlice.reducer;

export const currentCardEffect = {
  'currentCard/clear': (store: ToolkitStore<AppStore>) => store.dispatch(currentCardClear()),
  'currentCard/currentCardRequest': async (
    store: ToolkitStore<AppStore>,
    action: PayloadAction<RequestParams | undefined>
  ) => {
    var card = store.getState().currentCard;
    if (card?.loaded === true && !card?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(currentCardSuccess({ record: card!.result! }));
    } else {
      try {
        let currentCard: CurrentCard = {} as CurrentCard;
        let res = await apiGetCardInfo();
        let data = res.data;
        if (data.code === 200) {
          let _c = data.data;
          if (_c) {
            currentCard = Object.assign({}, _c, {
              cardBalance: modelParsePrice(_c.cardDetailWalletVo?.availableAmount ?? 0),
            });
            if (_c.cardPackDetailVos) {
              let cp = _c.cardPackDetailVos.find((i: any) => i.status === 'Y');
              currentCard!.theMonth = cp;
              if (currentCard!.theMonth) {
                currentCard!.theMonth.packTotalFlow = modelParseFlow((cp.packTotalFlow ?? 0) * 1);
                currentCard!.theMonth.surplusFlow = modelParseFlow((cp.surplusFlow ?? 0) * 1);
                currentCard!.theMonth.totalFlow = modelParseFlow((cp.totalFlow ?? 0) * 1);
                currentCard!.theMonth.usedFlow = modelParseFlow((cp.usedFlow ?? 0) * 1);
                currentCard!.theMonth.realPrice = modelParseFlow((cp.realPrice ?? 0) * 1);
              }
              let np = _c.cardPackDetailVos.find((i: any) => i.status === 'N');
              currentCard!.nextMonth = np;
              if (currentCard!.nextMonth) {
                currentCard!.nextMonth.packTotalFlow = modelParseFlow((np.packTotalFlow ?? 0) * 1);
                currentCard!.nextMonth.surplusFlow = modelParseFlow((np.surplusFlow ?? 0) * 1);
                currentCard!.nextMonth.totalFlow = modelParseFlow((np.totalFlow ?? 0) * 1);
                currentCard!.nextMonth.usedFlow = modelParseFlow((np.usedFlow ?? 0) * 1);
                currentCard!.nextMonth.realPrice = modelParseFlow((np.realPrice ?? 0) * 1);
              }
            }
            currentCard.isChargeFirstActive = false;
            currentCard.isChargeDirectActive = false;
            try {
              let res = await apiIsActive(currentCard.id!, RechargeType.first);
              let data = res.data;
              if (data.code === 200) {
                currentCard.isChargeFirstActive = data.data === true;
              }
            } catch (e) {}
            try {
              let res = await apiIsActive(currentCard.id!, RechargeType.direct);
              let data = res.data;
              if (data.code === 200) {
                currentCard.isChargeDirectActive = data.data === true;
              }
            } catch (e) {}
          }
          if (!!currentCard.operatorType && main.currentTheme !== currentCard.operatorType) {
            store.dispatch(setTheme({ type: currentCard.operatorType }));
          }
          store.dispatch(currentCardSuccess({ record: currentCard }));
          if (currentCard.id) {
            store.dispatch(chargeFirstRequest({ cardId: currentCard.id!, forceRefresh: true }));
          }
        } else {
          store.dispatch(currentCardFailure({ error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(currentCardFailure({ error: e.toString() || '获取失败' }));
      }
    }
  },
};
