import React, { useRef, useState } from 'react';
import { delay } from '../../utils/utils';
export interface PopMenuItem {
  label: string;
  value: string;
}
interface Props {
  className?: string;
  menuClassName?: string;
  children?: React.ReactNode;
  options?: PopMenuItem[];
  value?: string;
  onClick: (value: string) => void;
}

export default function PopMenu({ className, children, value, options, onClick }: Props) {
  const [show, setShow] = useState(false);
  const [twoShow, setTwoShow] = useState(false);
  const [documentOverflow, setDocumentOverflow] = useState('');
  const childrenRef = useRef<HTMLDivElement | undefined | null>();
  const menuRef = useRef<HTMLDivElement | undefined | null>();

  if (show && !twoShow) {
    delay(20).then(() => {
      setTwoShow(true);
    });
  }

  function handleShow() {
    setShow(true);

    setDocumentOverflow(document.body.style.overflow);
    document.body.style.overflow = 'hidden';
  }
  function handleHide() {
    setShow(false);
    setTwoShow(false);
    document.body.style.overflow = documentOverflow;
  }

  function handleClick(value: string) {
    onClick(value);
    handleHide();
  }

  let childrenPos = childrenRef.current?.getBoundingClientRect() || ({} as DOMRect);
  let menuPos = menuRef.current?.getBoundingClientRect() || ({} as DOMRect);

  let top = (childrenPos.y || 20) - (menuPos.height || 0) - 10;
  let isUp = true;
  if (top < 10) {
    isUp = false;
    top = (childrenPos.y || 20) + (childrenPos.height || 0) + 10;
  }

  let left = childrenPos.x || 10;
  if (left + menuPos.width > window.innerWidth) {
    left = window.innerWidth - menuPos.width - 10;
  }

  return (
    <div className={`pos-r ${className}`}>
      <div
        className="pos-r z-1"
        onClick={handleShow}
        ref={(v) => (childrenRef.current = v)}
        style={{ width: 'max-content' }}
      >
        {children}
      </div>
      <div className={`pos-f z-300 ${show ? '' : 'd-none'} l-0 t-0 b-0 r-0`}>
        <div className="pos-a z-1 l-0 t-0 r-0 b-0 bg-color-white-t1 opacity-0" onClick={handleHide}></div>
        <div
          className="pos-a d-shadow"
          style={{ top, left, transition: 'all 0.1s ease', opacity: show && !twoShow ? 0 : 1 }}
          ref={(v) => (menuRef.current = v)}
        >
          <div className="border-radius-6 bg-color-white pos-r z-10 maxh-400 overflow-y">
            {options?.map((item) => (
              <div
                className={`ph-20 pv-10 ${value === item.value ? 'text-color-primary' : ''}`}
                key={item.value}
                onClick={() => handleClick(item.value)}
              >
                {item.label}
              </div>
            ))}
          </div>
          <div className={`${isUp ? 'up' : 'down'}-triangle`}></div>
        </div>
      </div>
    </div>
  );
}
