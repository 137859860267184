import React, { useEffect, useState } from 'react';
import { AppStore, Income } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { incomeRequest } from '../redux/incomeReducer';
import { cardRequest } from '../redux/cardReducer';
import { dataListStatus } from '../utils/utils';
import dayjs from 'dayjs';
import DefaultNoData from '../components/defaultPage/defaultNoData';
import PopMenu, { PopMenuItem } from '../components/popMenu';
import Page from '../components/page';
import AppBar from '../components/appBar';
import main from '../main';
import { PayType } from '../utils/dict';

interface DataMap {
  [x: string]: Income[];
}
export default function IncomeListPage() {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStore) => state.user);
  const currCard = useSelector((state: AppStore) => state.currentCard);
  const incomeList = useSelector((state: AppStore) => state.income);
  const cardList = useSelector((state: AppStore) => state.card);
  const [filterCard, setFilterCard] = useState(currCard?.result?.iccid ?? 'all');
  const [filterTime, setFilterTime] = useState('all');
  const [isHide, setHide] = useState<string[]>([]);

  useEffect(() => {
    if (currCard?.result?.iccid && (!filterCard || filterCard === 'all')) {
      setFilterCard(currCard?.result?.iccid);
    }
  }, [currCard]); // eslint-disable-line

  useEffect(() => {
    if (user.result?.userId) {
      dispatch(incomeRequest());
      dispatch(cardRequest());
    }
  }, [user]); // eslint-disable-line

  // 展开/隐藏
  function handleUnExpansion(key: string) {
    let arr = [...isHide];
    if (arr.includes(key)) {
      arr.splice(arr.indexOf(key), 1);
    } else {
      arr.push(key);
    }
    setHide(arr);
  }
  let _centerView: any = [];
  let statusView = dataListStatus({ list: incomeList });
  if (statusView) {
    _centerView = [<div className="mt-50">{statusView}</div>];
  } else {
    let t: DataMap = {};
    let k: string[] = [];
    let useList = [];
    if (filterCard === 'all' && filterTime === 'all') {
      useList = incomeList!.results!;
    } else if (filterCard === 'all') {
      useList = incomeList!.results!.filter((i) => dayjs(i.createTime!).format('YYYY年MM月') === filterTime);
    } else if (filterTime === 'all') {
      useList = incomeList!.results!.filter((i) => i.iccid === filterCard);
    } else {
      useList = incomeList!.results!.filter(
        (i) => i.iccid === filterCard && dayjs(i.createTime!).format('YYYY年MM月') === filterTime
      );
    }
    if (!useList.length) {
      _centerView = <DefaultNoData />;
    } else {
      useList.forEach((item) => {
        let ca = dayjs(item.createTime!).format('YYYY年MM月');
        if (!t[ca]) {
          t[ca] = [];
          k.push(ca);
          // @ts-ignore
          k = k.sort().reverse();
        }
        t[ca].push(item);
      });
      _centerView = k.map((key: string) => {
        let dlist = t[key];

        return (
          <div key={key} className="mv-10">
            <div className="d-flex-r ai-center pv-10 ph-20" onClick={() => handleUnExpansion(key)}>
              <div className="flex-1">{key}</div>
              <div className="text-color-9 mr-5">{isHide.includes(key) ? '收起' : '展开'}</div>
              <img className="w-14" src={main.pic(`icon_arrow_${isHide.includes(key) ? 'down' : 'up'}.png`)} alt="" />
            </div>
            {!isHide.includes(key) && (
              <div className="bg-color-white ph-20">
                {dlist.map((item, index) => (
                  <div
                    key={item.id! + index}
                    className={`d-flex-r ai-center pv-10 ${index + 1 === dlist.length ? '' : 'border-bottom'}`}
                  >
                    <img className="h-30 mr-10" src={main.pic(PayType.typeIcon(item.payType))} alt="" />
                    <div className="flex-1 h-40 d-flex-c jc-around">
                      <div className="d-flex-r ai-center jc-between">
                        <div>{item.businessTip}</div>
                        <div
                          className={`font-widget-600 ${
                            (item.availableAmountOperation ?? 0) > 0 ? 'text-color-origin' : 'text-color-9'
                          }`}
                        >
                          <span className="font-size-14">{item.availableAmountOperation}</span>元
                        </div>
                      </div>
                      <div className="d-flex-r ai-center jc-between text-color-9">
                        <div>{item.createTime}</div>
                        <div>{item.iccid}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      });
    }
  }

  let cardSelectOptions: PopMenuItem[] =
    (cardList?.results?.length ?? 0) > 0 ? cardList!.results!.map((i) => ({ label: i.iccid!, value: i.iccid! })) : [];
  cardSelectOptions.unshift({ label: '全部', value: 'all' });
  let cardSelectItem: PopMenuItem | undefined = cardSelectOptions.find((i) => i.value === filterCard);

  let timeSelectOptions: PopMenuItem[] = [];
  (incomeList?.results ?? []).forEach((i) => {
    let ca = dayjs(i.createTime!).format('YYYY年MM月');
    if (!timeSelectOptions.find((i) => i.value === ca)) {
      timeSelectOptions.push({ label: ca, value: ca });
    }
  });
  timeSelectOptions.sort().reverse();
  timeSelectOptions.unshift({ label: '全部', value: 'all' });
  let timeSelectItem: PopMenuItem | undefined = timeSelectOptions.find((i) => i.value === filterTime);

  return (
    <Page
      className="bg-color-gray d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={<AppBar title="充消记录" className="bg-color-gray" />}
    >
      <div className="d-flex-r bg-color-white">
        <PopMenu
          className="flex-1"
          options={cardSelectOptions}
          value={cardSelectItem?.value}
          onClick={(v) => setFilterCard(v)}
        >
          <div className="w-187 d-flex-r ai-center ph-20 pv-10">
            <div className="flex-1">卡号：{cardSelectItem?.label}</div>
            <img className="w-14" src={main.pic('icon_arrow_down.png')} alt="" />
          </div>
        </PopMenu>
        <PopMenu
          className="flex-1"
          options={timeSelectOptions}
          value={timeSelectItem?.value}
          onClick={(v) => setFilterTime(v)}
        >
          <div className="w-187 d-flex-r ai-center ph-20 pv-10">
            <div className="flex-1">时间：{timeSelectItem?.label}</div>
            <img className="w-14" src={main.pic('icon_arrow_down.png')} alt="" />
          </div>
        </PopMenu>
      </div>
      <div className="flex-1 h-1 overflow-y">{_centerView}</div>
    </Page>
  );
}
