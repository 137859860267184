import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetPayConfigReduction } from '../api';
import { AppStore, PayDeduction } from '../types';

interface RequestParams {
  cardId: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  cardId: string;
  results: Array<PayDeduction>;
}

interface FailureParams {
  cardId: string;
  error: string;
}

type PayDeductionMap = {
  [key: string]: { [y: string]: PayDeduction };
};

const payDeductionSlice = createSlice({
  name: 'payDeduction',
  initialState: {} as PayDeductionMap,
  reducers: {
    payDeductionRequest: (state: PayDeductionMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = {};
      }
      return state;
    },
    payDeductionSuccess: (state: PayDeductionMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = {};
      }
      action.payload.results.forEach((i) => {
        state[action.payload.cardId][i.payType!] = i;
      });
      return state;
    },
    payDeductionFailure: (state: PayDeductionMap, action: PayloadAction<FailureParams>) => {
      return state;
    },
    payDeductionClear: (state: PayDeductionMap) => {
      state = {};
      return state;
    },
  },
});

export const { payDeductionRequest, payDeductionSuccess, payDeductionFailure, payDeductionClear } =
  payDeductionSlice.actions;
export default payDeductionSlice.reducer;

export const payDeductionEffect = {
  'payDeduction/clear': (store: ToolkitStore<AppStore>) => store.dispatch(payDeductionClear()),
  'payDeduction/payDeductionRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams>) => {
    var payDeductionList = store.getState().payDeduction ?? {};
    if (Object.keys(payDeductionList?.[action.payload.cardId] || {}).length > 0) {
      store.dispatch(
        payDeductionSuccess({
          cardId: action.payload.cardId,
          results: Object.values(payDeductionList![action.payload.cardId]),
        })
      );
    } else {
      try {
        let res = await apiGetPayConfigReduction(action.payload.cardId);
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(payDeductionSuccess({ cardId: action.payload.cardId, results: data.data ?? [] }));
        } else {
          store.dispatch(payDeductionFailure({ cardId: action.payload.cardId, error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(payDeductionFailure({ cardId: action.payload.cardId, error: e.toString() || '获取失败' }));
      }
    }
  },
};
