import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import DefaultNoData from '../../components/defaultPage/defaultNoData';
import PackTabBar from '../../components/packTabBar';
import { cardPackRequest } from '../../redux/cardPackReducer';
import { AppStore, Card, CardPack, CurrentCard } from '../../types';
import { CardStatus, PackTimeType, PackageType } from '../../utils/dict';
import { formatNumber } from '../../utils/utils';
import { buyPackLogRequest } from '../../redux/buyPackLogReducer';
import { useNavigate } from 'react-router-dom';
interface Props {
  card?: CurrentCard | Card;
  showBuyedFlag?: boolean;
  hidePackGagType?: boolean;
  onClick: (pack: CardPack, dateType: string) => void;
}
export default function PackRowList({ card, onClick, showBuyedFlag, hidePackGagType }: Props) {
  const [tabValue, setTabValue] = useState('currMonth');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardPackList = useSelector((state: AppStore) => state.cardPack[card?.id ?? '']);
  const buyPackLogList = useSelector((state: AppStore) => state.buyPackLog);
  //选中的 按钮类型
  const [packGagType, setPackGagType] = useState('month');
  // 所有的 按钮类型
  const [packGagTypes, setPackGagTypes] = useState<string[]>([]);
  // 按类型能显示的套餐列表
  const [showList, setShowList] = useState<CardPack[]>([]);
  useEffect(() => {
    if (card?.id || !cardPackList) {
      dispatch(cardPackRequest({ cardId: card?.id ?? '' }));
    }
    if (showBuyedFlag) {
      dispatch(buyPackLogRequest());
    }
  }, [card?.id]); // eslint-disable-line

  useEffect(() => {
    if (card?.id && cardPackList?.results) {
      let _list: CardPack[] = [];
      let _packGagType: string = packGagType;
      let _packGagTypes: string[] = [];
      for (let item of cardPackList?.results) {
        if (tabValue === 'currMonth') {
          // 当月列表内 待激活的卡或当月为空只能获取基础包
          if (card?.cardStatus === CardStatus.waitactivation || !card.theMonth) {
            if (item.packageType === PackageType.basicpackage) {
              if (!_packGagTypes.includes(item.packTimeType!)) {
                _packGagTypes.push(item.packTimeType!);
              }
              _list.push(item);
            }
          } else {
            // 当月列表内 非待激活的卡获取非基础包    fuelpack=> 加油包
            if (item.packageType !== PackageType.basicpackage) {
              if (!_packGagTypes.includes(item.packTimeType!)) {
                _packGagTypes.push(item.packTimeType!);
              }
              _list.push(item);
            }
          }
        } else {
          // 次月列表内 只能获取基础包
          if (item.packageType === PackageType.basicpackage) {
            if (!_packGagTypes.includes(item.packTimeType!)) {
              _packGagTypes.push(item.packTimeType!);
            }
            _list.push(item);
          }
        }
      }

      // 检查当前类型在不在要显示的类型列表内
      if (!_packGagTypes.includes(packGagType) && _packGagTypes) {
        _packGagType = _packGagTypes[0];
      }
      if (card.cardStatus === CardStatus.soldout || card.cardStatus === CardStatus.unknown) {
        _packGagTypes = [];
        _list = [];
      }
      if (_packGagType !== packGagType) {
        setPackGagType(_packGagType);
      }
      setPackGagTypes(_packGagTypes);
      setShowList(_list);
    }
  }, [cardPackList, packGagType, tabValue]); //eslint-disable-line

  if (!card?.id) {
    return (
      <div className="d-flex-c ai-center pt-30">
        <DefaultNoData tips="还未绑定卡片" />
        <Button
          className="mt-20"
          type="primary"
          size="sm"
          shape="circle"
          onClick={() => navigate('/cardProcess', { state: { step: 1 } })}
        >
          <div className="w-100">立即绑卡</div>
        </Button>
      </div>
    );
  }
  let _list = showList;
  if (!hidePackGagType) {
    _list = _list.filter((item) => item.packTimeType === packGagType);
  }

  let _buyLogList = (buyPackLogList.results ?? []).map((ilog) => ilog.packDetailId);
  return (
    <>
      <PackTabBar
        options={[
          {
            label: card.cardStatus === CardStatus.waitactivation || !card.theMonth ? '本期套餐' : '加油包',
            value: 'currMonth',
          },
          { label: '下期套餐', value: 'nextMonth' },
        ]}
        onChange={(value) => {
          setTabValue(value);
        }}
        value={tabValue}
      />
      {!hidePackGagType && (
        <div className="d-flex-r ph-20 pt-20">
          {packGagTypes.map((i, idx) => (
            <Button
              key={i + idx}
              className="mr-10"
              type={i === packGagType ? 'primary' : 'default'}
              bgPic
              size="xs"
              outline
              onClick={() => setPackGagType(i)}
            >
              {PackTimeType.getTypeStr(i)}
            </Button>
          ))}
        </div>
      )}
      <div className="bg-color-white pt-10">
        {_list.map((i) => {
          // 日期描述
          let dayStr = '';

          if (i.packageType === PackageType.fuelpack) {
            dayStr = '本期有效';
          } else if (i.packTimeType === PackTimeType.day) {
            dayStr = `${i.flowCalcTimeValue ?? 0}天有效`;
          } else {
            dayStr =
              dayjs(tabValue === 'currMonth' ? i.endEffectTime : i.nextEndEffectTime).format('YY.MM.DD') + '前有效';
          }

          // let startAt = dayjs(tabValue === 'currMonth' ? i.startEffectTime : i.nextStartEffectTime);
          // let endAt = dayjs(tabValue === 'currMonth' ? i.endEffectTime : i.nextEndEffectTime);
          // let diff = endAt.diff(startAt, 'day');
          // 是否是当前套餐
          let isCurr = card?.theMonth?.packDetailId === i.id;
          // 是否买过
          let isBuyed = _buyLogList.includes(i.id);
          return (
            <div
              key={i.name}
              className="mh-10 mb-10 bg-color-white border-radius-8 overflow-hidden pos-r d-shadow ph-10 pv-20 d-flex-r ai-center"
            >
              <div className="flex-1">
                <div className="font-size-14 font-widget-600 mb-5">{i.name}</div>
                <div className="text-color-9">{dayStr}</div>
              </div>
              <div className="mr-10">
                <div className="font-size-16 font-widget-600 text-color-red">
                  <span className="font-size-14">￥</span>
                  {formatNumber(i.discountPrice ?? i.salePrice)}
                </div>
                {!!i.discountPrice && <div className="text-color-9 text-delete-line">{formatNumber(i.salePrice)}</div>}
              </div>
              <Button type="primary" size="sm" onClick={() => onClick(i, tabValue)}>
                订购
              </Button>
              {showBuyedFlag && isCurr && (
                <div className="h-18 lh-18 ph-10 pos-a l-0 t-0 border-radius-tl-8 border-radius-br-8 bg-color-primary text-color-white">
                  当前套餐
                </div>
              )}
              {showBuyedFlag && !isCurr && isBuyed && (
                <div className="h-18 lh-18 ph-10 pos-a l-0 t-0 border-radius-tl-8 border-radius-br-8 bg-color-primary-t2 text-color-primary">
                  购买过
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
