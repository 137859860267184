import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetConfigList } from '../api';
import { AppStore, RecordList, SystemConfig } from '../types';

// 请求成功时传送的参数
interface SuccessParams {
  list: Array<SystemConfig>;
}
// 请求失败时传送的参数
interface FailureParams {
  error: string;
}

const systemConfigSlice = createSlice({
  name: 'systemConfig',
  initialState: { fetching: false, loaded: false } as RecordList<SystemConfig>,
  reducers: {
    systemConfigRequest: (state: RecordList<SystemConfig>, action: PayloadAction) => {
      state.fetching = true;
      return state;
    },
    systemConfigSuccess: (state: RecordList<SystemConfig>, action: PayloadAction<SuccessParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.results = action.payload.list;
      return state;
    },
    systemConfigFailure: (state: RecordList<SystemConfig>, action: PayloadAction<FailureParams>) => {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload.error;
      return state;
    },
    systemConfigClear: (state: RecordList<SystemConfig>) => {
      state = { fetching: false, loaded: false } as RecordList<SystemConfig>;
      return state;
    },
  },
});

export const { systemConfigRequest, systemConfigSuccess, systemConfigFailure, systemConfigClear } =
  systemConfigSlice.actions;
export default systemConfigSlice.reducer;

export const systemConfigEffect = {
  'systemConfig/clear': (store: ToolkitStore<AppStore>) => store.dispatch(systemConfigClear()),
  'systemConfig/systemConfigRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction) => {
    try {
      let res = await apiGetConfigList();
      let data = res.data;
      if (data.code === 200) {
        store.dispatch(systemConfigSuccess({ list: data.data }));
      } else {
        store.dispatch(systemConfigFailure({ error: data.msg || '获取失败' }));
      }
    } catch (e: any) {
      store.dispatch(systemConfigFailure({ error: e.toString() || '获取失败' }));
    }
  },
};
