import React, { useState } from 'react';

import Switch from 'react-switch';
import { useLocation, useNavigate } from 'react-router-dom';
import { Address } from '../types';
import Page from '../components/page';
import AppBar from '../components/appBar';
import main from '../main';
import Button from '../components/button';
import { LoadingMask, showBottom, showToast } from '../components/dialog';
import CitySelectDialog from '../components/cityPicker';
import { validateChina, validateTel } from '../utils/utils';
import { apiEditAddress } from '../api';
import { useDispatch } from 'react-redux';
import { addressRequest } from '../redux/addressReducer';

export default function AddressEditPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state: item }: { state?: Address } = useLocation();
  const [name, setName] = useState(item?.receiverName || ''); //item存在与否
  const [telPhone, setTelPhone] = useState(item?.receiverPhone || '');
  const [province, setProvince] = useState(item?.provinceName || '');
  const [city, setCity] = useState(item?.cityName || '');
  const [area, setArea] = useState(item?.countyName || '');
  const [detail, setDetail] = useState(item?.receiverAddress || '');
  const [isDefault, setIsDefault] = useState(item?.status === 'Y' ? true : false);

  async function handleSelectCity() {
    let res = await showBottom({
      buildContent: (onOk) => (
        <CitySelectDialog province={province} city={city} area={area} onConfirm={onOk} onCancel={() => onOk(0)} />
      ),
    });
    if (!res) return;
    setProvince(res[0]);
    setCity(res[1]);
    setArea(res[2]);
  }

  async function handleSave() {
    let t = item === null ? '创建' : '保存';

    if (!name) {
      return showToast('姓名不能为空');
    }
    if (!validateChina(name)) {
      return showToast('姓名不能有特殊符号');
    }
    if (!telPhone) {
      return showToast('手机号码不能为空');
    }
    if (!validateTel(telPhone)) {
      return showToast('手机号码格式不正确');
    }
    if (!province) {
      return showToast('所在地区不能为空');
    }
    if (!detail) {
      return showToast('详细地址不能为空');
    }
    if (!validateChina(detail)) {
      return showToast('详细地址不能有特殊符号');
    }

    let tmp = Object.assign({}, item, {
      receiverName: name || item?.receiverName,
      receiverPhone: telPhone || item?.receiverPhone,
      provinceName: province || item?.provinceName,
      cityName: city || item?.cityName,
      countyName: area || item?.countyName,
      receiverAddress: detail || item?.receiverAddress,
      status: isDefault === true ? 'Y' : 'N',
    });
    try {
      LoadingMask.show(`正在${t}...`);
      let res = await apiEditAddress(tmp);
      let data = res.data;
      if (data.code === 200) {
        showToast(`${t}成功`);
        dispatch(addressRequest({ forceRefresh: true }));
        navigate(-1);
      } else {
        showToast(data.msg || `${t}失败`);
      }
    } catch (e: any) {
      showToast(e.message || `${t}失败`);
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page
      className="d-flex-c bg-color-gray"
      contentClassName="flex-1 h-1 overflow-y p-20"
      appBar={<AppBar title={!!item ? '编辑地址' : '添加地址'} className="bg-color-gray" />}
    >
      <div className="font-size-14 mb-10">填写信息</div>
      <div className="bg-color-white border-radius-8 p-10">
        <div className="border-bottom pv-10 font-size-14">
          <input
            type="text"
            className="bw-100"
            placeholder="请输入姓名"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="border-bottom pv-10 font-size-14">
          <input
            type="text"
            className="bw-100"
            placeholder="请输入手机号"
            value={telPhone}
            onChange={(e) => setTelPhone(e.target.value)}
          />
        </div>
        <div className="border-bottom pv-10 mt-10 d-flex-r ai-center" onClick={handleSelectCity}>
          <div className={`flex-1 ${!province ? 'text-color-6' : ''} omit-text`}>
            {!province ? '请选择地区' : `${province}${city}${area}`}
          </div>
          <img className="h-12" src={main.pic('icon_right_xs.png')} alt="" />
        </div>
        <div className="border-bottom pv-10 mt-10">
          <textarea
            className="bw-100 h-24"
            placeholder="请输入详细地址"
            value={detail}
            onChange={(e) => setDetail(e.currentTarget.value)}
          />
        </div>
        <div className="border-bottom pv-10 mt-10 d-flex-r ai-center">
          <div className="flex-1">设为默认地址</div>
          <Switch
            width={40}
            height={20}
            checked={isDefault}
            onChange={(v) => setIsDefault(v)}
            offColor="#E0E0E0"
            onColor={main.getThemeColor()}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow=""
            activeBoxShadow=""
          />
        </div>
      </div>
      <Button className="mt-30" type="primary" shape="circle" long size="md" onClick={handleSave}>
        保存
      </Button>
    </Page>
  );
}
