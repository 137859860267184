import React from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import Button from '../components/button';
import main from '../main';
import { useNavigate } from 'react-router-dom';

export default function ComplaintIndexPage() {
  const navigate = useNavigate();
  return (
    <Page className="d-flex-c" contentClassName="p-20" appBar={<AppBar title="投诉" />}>
      <div className="font-size-18 font-widget-600 pv-10 border-bottom">投诉举报</div>
      <div className="mt-10 mb-40">
        若您遇到其他无法和商家沟通解决的问题，您可点击下方“投诉商家”，我们会协助您解决问题。
      </div>
      <div className="d-flex-r jc-center mb-40">
        <Button type="green" size="md" onClick={() => navigate('/complaint')}>
          <div className="ph-20">投诉商家</div>
        </Button>
      </div>
      <div className="d-flex-r ai-center border-bottom border-top pv-10" onClick={() => navigate('/complaintList')}>
        <div className="flex-1">投诉历史</div>
        <img className="h-12" src={main.pic('icon_right_xs.png')} alt="" />
      </div>
    </Page>
  );
}
