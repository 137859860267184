import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import main from '../../main';
import { AppStore } from '../../types';
interface Props {
  onClick?: () => void;
  onComplaint?: () => void;
}

const itemWidth = 34;
const itemHeight = 100;
const space = 10;
export default function FloatCustomer({ onClick, onComplaint }: Props) {
  const divRef = useRef<any>();

  const theme = useSelector((state: AppStore) => state.theme);
  // 加这个输出是为了检测theme变化时组件刷新，但又用不到theme变量
  console.log('当前皮肤:', theme);
  const [left, setLeft] = useState<number>(window.innerWidth - itemWidth - space); // 向左移动的距离
  const [top, setTop] = useState<number>(window.innerHeight - itemHeight - 300); // 向上移动的距离

  useEffect(() => {
    if (main.customerPositionWrite) {
      setLeft(main.customerLeft);
      setTop(main.customerTop);
      main.customerPositionWrite = false;
    }
  }, []);

  useEffect(() => {
    main.customerPositionWrite = true;
    main.customerLeft = left;
    main.customerTop = top;
  }, [left, top]);

  function handleClick(e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick!();
    }
  }
  function handleComplaint(e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onComplaint!();
    }
  }

  //开始拖拽
  function handleDragStart() {
    divRef!.current!.style.transition = 'none';
    document.body.style.overflow = 'hidden';
  }
  //触摸移动
  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (e.targetTouches.length === 1) {
      let touch = e.targetTouches[0];
      let left = touch.clientX - itemWidth / 2;
      let top = touch.clientY - itemHeight / 2;
      if (left < space) {
        left = space;
      }
      if (left > window.innerWidth - itemWidth - space) {
        left = window.innerWidth - itemWidth - space;
      }
      if (top < space) {
        top = space;
      }
      if (top > window.innerHeight - itemHeight - space) {
        top = window.innerHeight - itemHeight - space;
      }
      setLeft(left);
      setTop(top);
    }
  }
  function hanldeTouchEnd() {
    document.body.style.overflow = '';
  }

  // shake-animation 抖动动画
  return (
    <div
      className="bg-color-white d-shadow pos-f d-flex-c ai-center jc-center border-radius-40 z-300"
      ref={divRef}
      style={{
        width: `${itemWidth}px`,
        height: `${itemHeight}px`,
        left: `${left}px`,
        top: `${top}px`,
      }}
      onTouchStart={(e) => handleDragStart()}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => hanldeTouchEnd()}
    >
      <div className="d-flex-c mt-5 text-color-p ai-center" onClick={handleClick}>
        <img className="icon-20" src={main.pic('icon_customer.png', true)} alt="" />
        <span className="font-size-12 text-color-primary">客服</span>
      </div>
      <div className="d-flex-c mt-5 text-color-p ai-center" onClick={handleComplaint}>
        <img className="icon-20" src={main.pic('icon_complaint.png', true)} alt="" />
        <span className="font-size-12 text-color-primary">投诉</span>
      </div>
    </div>
  );
}
