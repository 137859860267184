import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppStore, Card } from '../../types';
import { cardTransferRequest } from '../../redux/cardTransferReducer';
import { LoadingMask, showConfirm, showToast } from '../../components/dialog';
import { CardTransferStatus } from '../../utils/dict';
import {
  apiEditCard,
  apiGetCardListForIccid,
  apiGetSwapCardLogDetail,
  apiGetWeChatSignature,
  apiUpdateCardTransferLogStatus,
} from '../../api';
import { delay, findCustomerUrl } from '../../utils/utils';
import main from '../../main';

export default function HomeCheckSwapCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 0 未准备 1可以使用  2 客户端不支持
  const [wxReady, setWxReady] = useState(0);
  const user = useSelector((state: AppStore) => state.user);
  const card = useSelector((state: AppStore) => state.currentCard);
  const swapCardList = useSelector((state: AppStore) => state.cardTransfer);
  const customServiceList = useSelector((state: AppStore) => state.customerUrl);
  console.log('1111');
  useEffect(() => {
    console.log('微信正在准备');
    wxConfig();
  }, []); // eslint-disable-line
  useEffect(() => {
    if (card?.result?.id) {
      console.log('当前有卡，加载转卡列表');
      dispatch(cardTransferRequest());
    }
  }, [card]); // eslint-disable-line

  useEffect(() => {
    // TODO: wxReady === 1
    if (swapCardList?.results?.length && wxReady === 1 && card?.result?.id) {
      console.log('转卡列表加载完毕，微信api准备完毕，当前卡有ID');
      handleCheckSwapCard();
    }
  }, [swapCardList, wxReady]); // eslint-disable-line

  function scanQRCode(): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log('开始扫码');
      // @ts-ignore
      let wx = window.wx;
      // 调起企业微信扫一扫接口
      wx.scanQRCode({
        desc: 'scanQRCode desc',
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res: any) {
          var result: string = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          if (result.includes(',')) {
            result = result.split(',')[1];
          }

          console.log('扫码成功 有结果了', result);
          resolve(result);
        },
        error: function (res: any) {
          if (res.errMsg.indexOf('function_not_exist') > 0) {
            showToast('微信版本过低请升级');
          }
          console.log('扫码失败');
          reject(false);
        },
      }); //wx.scanQRcode结束
    });
  }

  async function handleCheckSwapCard() {
    let item = swapCardList.results!.find((item) => item.cardId === card?.result?.id);
    console.log('查找转卡记录');
    if (item?.status === CardTransferStatus.waitSignFor) {
      console.log('有符合的记录');
      let scanCardTipsRes = await showConfirm({
        title: '换卡提示',
        content: (
          <div className="p-10">
            您的卡片已发出，收到卡片后，<span className="text-color-red">请扫描新卡背面条形码</span>，操作转移老卡信息
          </div>
        ),
        hideCancelBtn: true,
        confirmBtnText: '扫描新卡并转卡',
      });
      if (scanCardTipsRes !== 1) return;
      try {
        let scanCardRes = await scanQRCode();
        if (scanCardRes === item.newIccid) {
          LoadingMask.show('正在转卡...');
          let res = await apiUpdateCardTransferLogStatus(item!.id!, CardTransferStatus.completed, scanCardRes);
          if (res.data.code === 200) {
            // 轮训次数
            let forNum = 0;
            // 轮训结果 1 没结果 后台1分钟还没跑完  2 成功 3 失败
            let forRes = 1;
            while (forNum < 6) {
              await delay(5000);
              let itemListRes = await apiGetSwapCardLogDetail(item.id);
              let itemData = itemListRes.data;
              forNum++;
              if (itemData.code === 200 && itemData.data?.id) {
                let findItem = itemData.data;
                if (findItem.status === CardTransferStatus.taskSuccess) {
                  // 转卡成功
                  forRes = 2;
                  forNum = 6;
                } else if (
                  findItem.status === CardTransferStatus.taskFailure ||
                  findItem.status === CardTransferStatus.transferCardFailure
                ) {
                  // 转卡失败
                  forRes = 3;
                  forNum = 6;
                }
              }
              await delay(5000);
            }
            LoadingMask.hide();
            if (forRes === 1) {
              let toCustomerRes = await showConfirm({
                title: '换卡提示',
                content: '任务还在执行中，请稍等片刻',
                hideCancelBtn: true,
                confirmBtnText: '联系客服',
              });
              if (toCustomerRes !== 1) return;
              let _customerUrl = findCustomerUrl(customServiceList);
              navigate('/customer', {
                state: {
                  url: _customerUrl,
                  tel: user?.result?.phonenumber,
                  cardId: card?.result?.iccid,
                },
              });
            } else if (forRes === 2) {
              let toRealNameRes = await showConfirm({
                title: '转卡实名',
                content: (
                  <div className="text-color-9 text-left p-10">
                    老卡号<span className="text-color-3">{card?.result?.iccid ?? ''}</span>信息已全部转移至卡号：
                    <span className="text-color-3">{item.newIccid ?? ''}</span>
                    <div>完成实名后即可上网！</div>
                  </div>
                ),
                hideCancelBtn: true,
                confirmBtnText: '确认',
              });
              if (toRealNameRes !== 1) return;
              let cardListRes = await apiGetCardListForIccid(item.newIccid);
              let cardData = cardListRes.data;
              if (cardData.code === 200 && cardData.rows.length) {
                let ncard: Card = cardData.rows[0];

                if (ncard != null) {
                  let defaultRes = await apiEditCard(ncard.id!, ncard!.userRemark, true);
                  let defaultData = defaultRes.data;
                  if (defaultData.code === 200) {
                    showToast(defaultData.msg ?? '修改成功');
                    window.location.reload();
                  } else {
                    showToast(defaultData.msg || '修改失败');
                  }
                } else {
                  // 接口成功但没有查到新卡信息
                  showToast('获取新卡信息失败');
                }
              } else {
                // 没有获取到新卡信息 或者接口失败
                showToast('获取新卡信息失败');
              }
            } else {
              let toCustomerRes = await showConfirm({
                title: '换卡提示',
                content: '转卡失败，是否联系客服？',
                hideCancelBtn: true,
                confirmBtnText: '联系客服',
              });
              if (toCustomerRes !== 1) return;
              let _customerUrl = findCustomerUrl(customServiceList);
              navigate('/customer', {
                state: {
                  url: _customerUrl,
                  tel: user?.result?.phonenumber,
                  cardId: card?.result?.iccid,
                },
              });
            }
            // let item = res.data.data;
            // let editRes = await apiEditCard(item.id!, item.remark, true);
            // let data = editRes.data;
            // if (data.code === 200) {
            //   showToast(data.msg ?? '修改成功');
            //   dispatch(cardListRequest({}));
            //   dispatch(userRequest({}));
            // }
          } else {
            showToast(res.data.msg || '转卡失败');
          }
        } else {
          let toCustomerRes = await showConfirm({
            title: '换卡提示',
            content: '您的新卡卡号与后台登记不一致，请联系客服处理！',
            hideCancelBtn: true,
            confirmBtnText: '联系客服',
          });
          if (toCustomerRes !== 1) return;
          let _customerUrl = findCustomerUrl(customServiceList);
          navigate('/customer', {
            state: {
              url: _customerUrl,
              tel: user?.result?.phonenumber,
              cardId: card?.result?.iccid,
            },
          });
        }
      } catch (e: any) {
        showToast(e.message ?? '操作失败,请刷新重试');
      } finally {
        LoadingMask.hide();
      }
    }
  }

  async function wxConfig() {
    // @ts-ignore
    let wx = window.wx;
    let res = await apiGetWeChatSignature(window.location.href.split('#')[0], main.appId);
    let data = res.data;

    if (data.code === 200) {
      let _data = data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'scanQRCode'], // 必填，需要使用的JS接口列表
      });
    }

    wx.ready(() => {
      wx.checkJsApi({
        //判断当前客户端版本是否支持指定JS接口
        jsApiList: ['scanQRCode'],
        success: function (res: any) {
          // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
          if (res.checkResult.scanQRCode !== true) {
            showToast('抱歉，当前客户端版本不支持扫一扫');
            setWxReady(2);
          } else {
            setWxReady(1);
          }
        },
      });
    });
  }
  return (
    <div>
      {/* {toRealName && newCard && (
        <RealNameDialog
          cardId={newCard.id!}
          msisdn={newCard.imsi!}
          operatorType={newCard.guangDian === true ? OperatorType.guangDian : newCard.operatorType!}
          handleClose={() => {
            setToRealName(false);
          }}
        />
      )} */}
    </div>
  );
}
