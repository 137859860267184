import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppBar from '../components/appBar';
import Page from '../components/page';
import { complaintDetailRequest } from '../redux/complaintDetailReducer';
import { AppStore } from '../types';
import { ComplaintStatus, ComplaintWorkerType } from '../utils/dict';
import { dataDetailStatus } from '../utils/utils';
import { showCustom } from '../components/dialog';

export default function ComplaintDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { canBack } = useLocation().state ?? {};
  const user = useSelector((state: AppStore) => state.user);
  const complaintDetail = useSelector((state: AppStore) => state.complaintDetail[id ?? '']);

  useEffect(() => {
    if (user.result?.userId) {
      if (id) {
        dispatch(complaintDetailRequest({ id }));
      } else {
        if (canBack) {
          navigate(-1);
        } else {
          navigate('/', { replace: true });
        }
      }
    }
  }, [user]); // eslint-disable-line

  async function handleShowBig(img: string) {
    await showCustom({
      maskHide: true,
      buildContent: (onOk) => <img src={img} className="bw-100" alt="" onClick={() => onOk(0)} />,
    });
  }

  function handleToCustomer() {
    navigate('/customer', { state: { from: '投诉记录详情' } });
  }

  let el = dataDetailStatus({ detail: complaintDetail });
  let record = complaintDetail?.result;
  return (
    <Page
      className="bg-color-white d-flex-c"
      contentClassName="flex-1 h-1 overflow-y p-20"
      appBar={<AppBar title="投诉记录详情" />}
    >
      {!!el ? (
        el
      ) : (
        <div>
          <div className="d-flex-r ai-center pv-10 border-bottom">
            <div className="flex-1 text-color-9">卡号</div>
            <div>{record?.iccid}</div>
          </div>
          <div className="d-flex-r ai-center pv-10 border-bottom">
            <div className="flex-1 text-color-9">联系人姓名</div>
            <div>{record?.name}</div>
          </div>
          <div className="d-flex-r ai-center pv-10 border-bottom">
            <div className="flex-1 text-color-9">联系电话</div>
            <div>{record?.phoneNumber}</div>
          </div>
          <div className="d-flex-r ai-center pv-10 border-bottom">
            <div className="flex-1 text-color-9">投诉类型</div>
            <div>
              {record?.workOrderType
                ?.split(',')
                .map((i) => ComplaintWorkerType.getTypeStr(i))
                .join('/')}
            </div>
          </div>
          <div className="d-flex-r ai-start pv-10 border-bottom">
            <div className="flex-1 text-color-9 minw-80">投诉原因</div>
            <div>{record?.workOrderContent}</div>
          </div>

          {!!record?.workOrderImg && (
            <div className="d-flex-r ai-start pv-10 border-bottom">
              <div className="flex-1 text-color-9 minw-80">图片证据</div>
              <div>
                {record?.workOrderImg.split(';').map((i, idx) => (
                  <img
                    key={i + idx}
                    className="w-40 h-40 border-radius-4 ml-10"
                    src={i}
                    alt=""
                    onClick={() => handleShowBig(i)}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="d-flex-r ai-center pv-10 border-bottom">
            <div className="flex-1 text-color-9">投诉时间</div>
            <div>{record?.createTime}</div>
          </div>
          <div className="d-flex-r ai-start pv-10 border-bottom">
            <div className="flex-1 text-color-9 minw-80">处理状态</div>
            <div className={`text-color-${ComplaintStatus.getTypeColor(record?.status)}`}>
              {ComplaintStatus.getTypeStr(record?.status)}
            </div>
          </div>
          <div className="d-flex-r ai-start pv-10 border-bottom">
            <div className="flex-1 text-color-9 minw-80">处理结果</div>
            <div>{record?.dealResult}</div>
          </div>
          {!!record?.dealImg && (
            <div className="d-flex-r ai-start pv-10 border-bottom">
              <div className="flex-1 text-color-9 minw-80">回复图片</div>
              <div>
                {record?.dealImg.split(';').map((i, idx) => (
                  <img key={i + idx} className="w-40 h-40 border-radius-4" src={i} alt="" />
                ))}
              </div>
            </div>
          )}
          <div className="p-20 text-center">
            问题未解决？
            <span className="text-color-primary" onClick={handleToCustomer}>
              联系客服
            </span>
          </div>
        </div>
      )}
    </Page>
  );
}
