import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import main, { Main } from '../main';
import { AppStore } from '../types';

interface ActionParams {
  type: string;
  force?: boolean;
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: Main.yd,
  reducers: {
    setTheme: (state: string, action: PayloadAction<ActionParams>) => {
      state = action.payload.type || Main.yd;
      return state;
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;

export const themeEffect = {
  'theme/setTheme': async (store: ToolkitStore<AppStore>, action: PayloadAction<ActionParams>) => {
    main.setTheme(action.payload.type || Main.def, action.payload.force);
  },
};
