import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { apiCodeToOpenId } from './api';
import { showConfirm, showToast } from './components/dialog';
import main, { AppLocalKey } from './main';
import FloatCustomer from './pages/components/floatCustomer';
import LoginFloatCustomer from './pages/components/loginFloatCustomer';
import { setTheme } from './redux/themeReducer';
import { userInfoRequest } from './redux/userInfoReducer';
import { AppStore } from './types';
import { parseLocationParams, validateTel } from './utils/utils';

let isWeixin = false;
export default function MainPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((s: AppStore) => s.user);
  const [showLoginCustomer, setShowLoginCustomer] = useState(false);
  // 设置登录后的悬浮客服是否显示
  const [showCustomer, setShowCustomer] = useState(false);
  // 页面在刚打开时检查用户信息,需要触发useEffect,再之后就不用触发了,所以标记over
  const [over, setOver] = useState(false);
  useEffect(() => {
    let { op, code } = parseLocationParams(location.search);
    let ua = window.navigator.userAgent.toLowerCase();

    isWeixin = ua.includes('micromessenger');
    let openId = localStorage.getItem(main.appId);
    let uri = window.location.href;
    let redirect_uri = encodeURIComponent(uri);
    if (isWeixin && !code && !openId) {
      // 微信环境下啥都没有 跳转去授权
      document.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${main.appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      );
    } else if (isWeixin && code && !openId) {
      getOpenId(code);
    }
    if (openId) {
      main.openId = openId;
    }
    dispatch(setTheme({ type: op, force: !!op }));

    checkLoginKey();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!over) {
      checkLoginState();
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    // 客服悬浮按钮 部分页面不显示
    const hidePage = ['/protocol', '/customer', '/complaintIndex', '/complaint', '/complaintList', '/complaintDetail'];
    const currPath = '/' + location.pathname.split('/')[1];
    let hide = hidePage.includes(currPath);
    if (!!user.result?.userId && !hide) {
      setShowCustomer(true);
    } else {
      setShowCustomer(false);
    }

    const loginCustomerHidePage = ['/protocol', '/customer'];

    let loginHide = loginCustomerHidePage.includes(currPath);
    if (!user.result?.userId) {
      if (loginHide) {
        setShowLoginCustomer(false);
      } else {
        setShowLoginCustomer(true);
      }
    } else {
      setShowLoginCustomer(false);
    }
  }, [location, user]); // eslint-disable-line

  function checkLoginKey() {
    if (main.isIos14) {
      main.enterUrl = window.location.href;
    }
    let key = localStorage.getItem(AppLocalKey.loginToken);
    if (!key) {
      routeToPage('/login');
    } else {
      main.loginToken = key;
      dispatch(userInfoRequest());
    }
  }

  /**
   * 获取openId
   * @param code
   */
  async function getOpenId(code: string) {
    try {
      let res = await apiCodeToOpenId(code, main.appId);
      let data = res.data;
      if (data.code === 200) {
        main.openId = data.data;
        localStorage.setItem(main.appId, main.openId ?? '');
      }
    } catch (e) {}
  }

  function checkLoginState() {
    if (user.loaded) {
      setOver(true);
      if (!user.result?.userId) {
        routeToPage('/login');
      } else {
        if (!isWeixin) {
          showToast('请在微信环境内浏览，避免使用故障');
        }
      }
    }
  }
  function routeToPage(page: string) {
    if (!isWeixin) {
      showToast('请在微信环境内浏览，避免使用故障');
    }
    navigate(page, { replace: true });
  }

  async function handleLoginCustomer() {
    let tel = main.loginPhone ?? '';
    let modalRes = await showConfirm({
      title: '联系客服',
      content: (
        <div className="ph-20">
          <div>请输入手机号</div>
          <div className="border border-radius-20 p-10 mt-10">
            <input
              className="bw-100"
              defaultValue={tel}
              maxLength={11}
              placeholder="请输入手机号"
              type="text"
              onChange={(e) => (tel = e.target.value)}
            />
          </div>
        </div>
      ),
    });
    if (!modalRes) return;
    if (!validateTel(tel)) {
      return showToast('请输入正确的手机号');
    }
    navigate('/customer', { state: { tel, from: '登录页' } });
  }

  return (
    <div>
      <Outlet />
      {!!showCustomer && (
        <FloatCustomer onClick={() => navigate('/customer')} onComplaint={() => navigate('/complaintIndex')} />
      )}
      {!!showLoginCustomer && <LoginFloatCustomer onClick={handleLoginCustomer} />}
    </div>
  );
}
