import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiCreateFirstActivityOrder, apiPayFirstActivityOrder } from '../api';
import AppBar from '../components/appBar';
import DefaultNoData from '../components/defaultPage/defaultNoData';
import { LoadingMask, showConfirm, showToast } from '../components/dialog';
import Page from '../components/page';
import main from '../main';
import { cardRequest } from '../redux/cardReducer';
import { chargeFirstRequest } from '../redux/chargeFirstReducer';
import { currentCardRequest } from '../redux/currentCardReducer';
import { AppStore, ChargeFirstPayDetail } from '../types';
import { PayType } from '../utils/dict';
import showPayPage from '../utils/pay';
import { dataDetailStatus, delay } from '../utils/utils';
import PayTypeDialog from './components/payTypeDialog';

export default function ChargeFirstActivePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const activityData = useSelector((state: AppStore) => state.chargeFirst[currentCard?.result?.id ?? '']);
  const [payTypeDialogShow, setPayTypeDialogShow] = useState<ChargeFirstPayDetail>();
  const [wxReady, setWxReady] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => setWxReady(true), false);
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => setWxReady(true));
      }
    } else {
      setWxReady(true);
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, []); // eslint-disable-line
  useEffect(() => {
    if (currentCard.result?.id) {
      dispatch(chargeFirstRequest({ cardId: currentCard.result?.id }));
    }
  }, [currentCard]); // eslint-disable-line

  useEffect(() => {
    if (activityData?.loaded && !activityData?.result?.payDetails?.length) {
      showToast('暂无可用活动');
      navigate(-1);
    }
  }, [activityData]); // eslint-disable-line

  /**
   * 弹窗选择了支付方式后
   * @param payType 支付类型
   * @returns
   */
  async function handleBuy(payType?: string) {
    let item = payTypeDialogShow;
    setPayTypeDialogShow(undefined);
    if (!payType) return;

    // main.openId = 'oVLsF6Q1Vr1wxaPk18nS7VafSg2w';
    if (!main.openId) {
      return showToast('授权失败,请在微信内打开');
    }
    try {
      LoadingMask.show('正在参与...');
      let res = await apiCreateFirstActivityOrder(currentCard.result!.id!, item!.payDetailId!);
      let data = res.data;
      if (data.code === 200) {
        let orderId = data.data.orderId;
        if (orderId) {
          let payRes = await apiPayFirstActivityOrder(orderId, payType, main.openId!, main.appId!);
          let payData = payRes.data;
          if (payData.code === 200) {
            let _data = payData.data;
            if (payType === PayType.hsmWxJsPay || payType === PayType.aliPage) {
              //  惠收米 收银台支付 第一章
              let showPayRes = await showPayPage(_data, payType);

              if (showPayRes.code === 300) {
                if (showPayRes.url) {
                  LoadingMask.hide();
                  window.location.href = showPayRes.url;
                  await showConfirm({
                    title: '提示',
                    content: '是否支付完成?',
                    confirmBtnText: '支付完成',
                    cancelBtnText: '未完成',
                  });
                  showToast('参与成功');
                  dispatch(currentCardRequest({ forceRefresh: true }));
                  dispatch(cardRequest({ forceRefresh: true }));
                  await delay(1000);
                  navigate(-1);
                }
              } else if (showPayRes.code === 500) {
                showToast(showPayRes.msg);
              } else {
                showToast('数据还在准备,请稍后重试');
              }
            } else if (wxReady) {
              let payRes = await showPayPage(_data, payType);
              if (payRes.code === 200) {
                showToast('参与成功');
                dispatch(currentCardRequest({ forceRefresh: true }));
                dispatch(cardRequest({ forceRefresh: true }));
                await delay(1000);
                navigate(-1);
              } else if (payRes.code === 300) {
                if (payRes.url) {
                  LoadingMask.hide();
                  navigate('/iframe', { state: { url: payRes.url, title: '银联支付', pay: true, back: true } });
                }
              } else if (payRes.code === 500) {
                showToast(payRes.msg);
              }
            } else {
              showToast('数据还在准备,请稍后重试');
            }
          } else {
            showToast(data.msg ?? '参与失败');
          }
        }
      } else {
        showToast(data.msg ?? '参与失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '参与失败');
    } finally {
      LoadingMask.hide();
    }
  }

  let el: React.ReactNode = dataDetailStatus({ detail: activityData });
  if (!el && !activityData?.result?.payDetails?.length) {
    el = <DefaultNoData />;
  }

  if (el) {
    return (
      <Page
        className="d-flex-c bg-color-gray"
        contentClassName="flex-1 pt-100 d-flex-c ai-center"
        appBar={<AppBar title="首充活动" className="bg-color-gray" />}
      >
        {el}
      </Page>
    );
  }

  let activity = activityData.result!;
  return (
    <div className="vw-100 vh-100 pos-r">
      <img className="h-310 vw-100 pos-a z-0 l-0 t-0" src={activity.activeImg} alt="" />
      <Page
        className="d-flex-c pos-a"
        contentClassName="flex-1 h-1 overflow-hidden d-flex-c"
        appBar={activity.forceActivity === 'Y' ? undefined : <AppBar className="bg-color-gray-t5" title="首充活动" />}
      >
        <div className="h-250"></div>
        <div className="flex-1 h-1 overflow-hidden bg-color-cyan border-radius-tl-20 border-radius-tr-20 pt-10">
          <div className="bh-100 d-flex-c bg-color-blue border-radius-tl-20 border-radius-tr-20 overflow-hidden pv-20">
            <div className="flex-1 h-1 overflow-y ph-20">
              <img className="w-335 d-block" src={main.pic('pic_activity_top.png')} alt="" />
              <div className="w-335 pos-r">
                <img
                  className="pos-a z-0 t-0 l-0 bw-100 bh-100 d-block"
                  src={main.pic('pic_activity_center.png')}
                  alt=""
                />
                <div className="pos-r z-10 mh-20 border-radius-10 bg-color-white p-10">
                  {activity.payDetails!.map((item, index) => {
                    let t = Math.floor(Math.random() * 9999);
                    return (
                      <div
                        key={item.payDetailId! + index}
                        className="pos-r mb-10"
                        onClick={() => setPayTypeDialogShow(item)}
                      >
                        <img
                          className="pos-a z-0 l-0 t-0 w-275 h-70"
                          src={main.pic('pic_activity_coupon.png')}
                          alt=""
                        />
                        <div className="pos-r z-10 w-275 h-70 d-flex-r ai-center">
                          <div className="flex-1 pl-14">
                            <div>
                              充
                              <span className="font-size-24 font-widget-800 ph-3 text-color-origin-t6">
                                {item.amount}
                              </span>
                              得
                              <span className="font-size-24 font-widget-800 ph-3 text-color-origin-t6">
                                {item.price}
                              </span>
                              元
                            </div>
                            <div className="text-color-origin-t6">已有{t}人参与</div>
                          </div>
                          <div className="text-color-white w-90 text-center font-size-16 font-widget-600">参加活动</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <img className="w-335 d-block" src={main.pic('pic_activity_bottom.png')} alt="" />
              <div className="mt-30 text-color-white font-size-14 font-widget-600">活动说明</div>
              <div className="mt-10 text-color-white word-wrap">{activity.activeDesc}</div>
              <div className="mt-10 text-color-white font-size-14 font-widget-600">活动详情</div>
              <div className="mt-10 text-color-white word-wrap">{activity.activeDetail}</div>
            </div>
          </div>
        </div>
      </Page>
      {!!payTypeDialogShow && <PayTypeDialog cardId={currentCard.result!.id!} onOk={handleBuy} />}
    </div>
  );
}
