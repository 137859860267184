import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetPackOrderList } from '../api';
import { Order, AppStore, RecordList } from '../types';
import { orderDetailSuccess } from './orderDetailReducer';

interface RequestParams {
  cardId: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams extends RequestParams {
  results: Array<Order>;
}

interface FailureParams extends RequestParams {
  error: string;
}
interface UpdateParams {
  result: Order;
}

type OrderMap = { [x: string]: RecordList<Order> };

const orderSlice = createSlice({
  name: 'order',
  initialState: {} as OrderMap,
  reducers: {
    orderRequest: (state: OrderMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = true;
      return state;
    },
    orderSuccess: (state: OrderMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].results = action.payload.results!;
      return state;
    },
    orderFailure: (state: OrderMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].error = action.payload.error;
      return state;
    },
    orderUpdate: (state: OrderMap, action: PayloadAction<UpdateParams>) => {
      if (!state[action.payload.result.cardId!]) {
        state[action.payload.result.cardId!] = { fetching: false, loaded: false };
      }
      state[action.payload.result.cardId!].fetching = false;
      state[action.payload.result.cardId!].loaded = true;
      let _list =
        state[action.payload.result.cardId!].results?.filter((item: any) => item.id !== action.payload.result.id) ?? [];
      _list.unshift(action.payload.result);
      state[action.payload.result.cardId!].results = _list;
      return state;
    },
    orderClear: (state: OrderMap) => {
      state = {};
      return state;
    },
  },
});

export const { orderRequest, orderSuccess, orderFailure, orderClear, orderUpdate } = orderSlice.actions;
export default orderSlice.reducer;

export const orderEffect = {
  'order/clear': (store: ToolkitStore<AppStore>) => store.dispatch(orderClear()),
  'order/orderRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams | undefined>) => {
    var orderList = store.getState().order[action.payload?.cardId || ''];
    if (orderList?.loaded === true && !orderList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(orderSuccess({ cardId: action.payload?.cardId ?? '', results: orderList!.results! }));
    } else {
      try {
        let res = await apiGetPackOrderList(action.payload?.cardId ?? '');
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(orderSuccess({ cardId: action.payload?.cardId ?? '', results: data.rows ?? [] }));
          if (data.rows) {
            data.rows.forEach((i: Order) => {
              store.dispatch(orderDetailSuccess({ id: i.id!, result: i }));
            });
          }
        } else {
          store.dispatch(orderFailure({ cardId: action.payload?.cardId ?? '', error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(orderFailure({ cardId: action.payload?.cardId ?? '', error: e.toString() || '获取失败' }));
      }
    }
  },
};
