import React from 'react';
import { useNavigate } from 'react-router-dom';
import main from '../../main';
interface Props {
  title: string;
  showBack?: boolean;
  onBack?: () => void;
  action?: React.ReactNode;
  className?: string;
  white?: boolean;
}
export default function AppBar({ className, title, showBack = true, onBack, action, white }: Props) {
  const navigate = useNavigate();
  function handleBack() {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  }
  return (
    <div className={`app-bar ${className}`}>
      <div className={`app-bar-title font-size-14 ${white ? 'text-color-white' : ''}`}>{title}</div>
      {showBack && (
        <img
          className="app-bar-back"
          src={main.pic(`icon_back${white ? '_white' : ''}.png`)}
          alt=""
          onClick={handleBack}
        />
      )}
      <div className="app-bar-action">{action}</div>
    </div>
  );
}
