import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apiUpdateCardTransferLogStatus } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import DefaultNoData from '../components/defaultPage/defaultNoData';
import { LoadingMask, showConfirm, showCustom, showToast } from '../components/dialog';
import Page from '../components/page';
import main from '../main';
import { cardTransferRequest } from '../redux/cardTransferReducer';
import { AppStore, CardTransfer } from '../types';
import { CardTransferStatus } from '../utils/dict';
import { dataListStatus } from '../utils/utils';
import ScanCardDialog from './transferCardComponents/scanCardDialog';

export default function CardTransferDetailPage() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: AppStore) => state.user);
  const transferList = useSelector((state: AppStore) => state.cardTransfer);
  const [record, setRecord] = useState<CardTransfer>();

  useEffect(() => {
    if (!id) {
      if (location.state?.canBack) {
        navigate(-1);
      } else {
        navigate('/', { replace: true });
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (user.result?.userId) {
      dispatch(cardTransferRequest());
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (transferList.results?.length) {
      setRecord(transferList.results.find((item) => item.id === id));
    }
  }, [transferList]); // eslint-disable-line

  async function handleSignFor() {
    let modalRes = await showConfirm({ title: '签收提示', content: '确认已经收到新卡了吗？' });
    if (modalRes !== 1) return;
    try {
      LoadingMask.show('正在签收...');
      let res = await apiUpdateCardTransferLogStatus(record!.id!, CardTransferStatus.waitTransferCard);
      if (res.data.code === 200) {
        showToast(res.data.msg ?? '签收成功');
        dispatch(cardTransferRequest({ forceRefresh: true }));
      } else {
        showToast(res.data.msg || '签收失败');
      }
    } catch (e: any) {
      showToast(e.message || '签收失败');
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleTransferCard() {
    let modalRes = await showCustom({ buildContent: (onOk) => <ScanCardDialog onOk={onOk} title="请识别新卡号" /> });
    if (modalRes.res === 0) return;
    if (modalRes.res !== 1) return showToast(modalRes.msg);
    let niccid = modalRes.msg;

    try {
      LoadingMask.show('正在转卡...');
      let res = await apiUpdateCardTransferLogStatus(record!.id!, CardTransferStatus.completed, niccid as string);
      if (res.data.code === 200) {
        showToast(res.data.msg ?? '转卡成功');
        dispatch(cardTransferRequest({ forceRefresh: true }));
      } else {
        showToast(res.data.msg || '转卡失败');
      }
    } catch (e: any) {
      showToast(e.message || '转卡失败');
    } finally {
      LoadingMask.hide();
    }
  }

  let el = dataListStatus({ list: transferList });
  if (!record && !el) {
    el = <DefaultNoData />;
  }
  return (
    <div className="vw-100 vh-100 pos-r">
      <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
      <Page
        className="d-flex-c pos-a"
        contentClassName="flex-1 h-1 overflow-hidden d-flex-c"
        appBar={<AppBar white className="bg-color-t0" title="换卡记录详情" />}
      >
        {!!el ? (
          <div className="mt-50">{el}</div>
        ) : (
          <div className="flex-1 h-1 p-10 overflow-y">
            <div className="bg-color-white border-radius-8 p-10 d-shadow">
              <div className="d-flex-r ai-center">
                <img className="h-18 mr-10" src={main.pic('icon_address.png')} alt="" />
                <div className="font-size-14 font-widget-600 mr-10">{record!.receiverName}</div>
                <div className="font-size-16">{record!.receiverPhone}</div>
              </div>
              <div className="mt-10">
                {record!.provinceName}
                {record!.cityName}
                {record!.countyName}
                {record!.receiverAddress}
              </div>
            </div>
            <div className="bg-color-white border-radius-8 p-14 mt-14 d-shadow">
              <div className="d-flex-r ai-center pv-14 border-bottom">
                <div className="text-color-9 flex-1">旧卡号</div>
                <div className="font-size-14">{record!.iccid}</div>
              </div>
              <div className="d-flex-r ai-center pv-14 border-bottom">
                <div className="text-color-9 flex-1">新卡号</div>
                <div className="font-size-14">{record!.newIccid ?? '暂无'}</div>
              </div>
              <div className="d-flex-r ai-center pv-14 border-bottom">
                <div className="text-color-9 flex-1">状态</div>
                <img className="h-16 mr-10" src={main.pic(CardTransferStatus.getTypeIcon(record!.status))} alt="" />
                <div className={`text-color-${CardTransferStatus.getTypeColor(record!.status)}`}>
                  {CardTransferStatus.getTypeStr(record!.status)}
                </div>
              </div>
              <div className="d-flex-r ai-center pv-14 border-bottom">
                <div className="text-color-9 flex-1">申请时间</div>
                <div className="font-size-14">{record!.createTime}</div>
              </div>
              <div className="d-flex-r ai-start pt-14">
                <div className="text-color-9 flex-1 minw-80">换卡说明</div>
                <div>{record!.customerDesc}</div>
              </div>
            </div>
            <div className="bg-color-white border-radius-8 p-14 mt-14 d-shadow">
              <div className="d-flex-r ai-center pv-14 border-bottom">
                <div className="text-color-9 flex-1">物流单号</div>
                <div className="font-size-14">{record!.expressNo ?? '暂无'}</div>
              </div>
              <div className="d-flex-r ai-center pt-14">
                <div className="text-color-9 flex-1">物流名称</div>
                <div>{record!.expressName ?? '暂无'}</div>
              </div>
            </div>
            <div className="bg-color-white border-radius-8 p-14 mt-14 d-shadow">
              <div className="d-flex-r ai-start">
                <div className="text-color-9 flex-1 minw-80">客服反馈</div>
                <div>{record!.remark}</div>
              </div>
            </div>
          </div>
        )}
        {record?.status === CardTransferStatus.waitSignFor && (
          <div className="p-10 d-flex-r jc-end border-top">
            <Button type="primary" size="sm" onClick={handleSignFor}>
              签收
            </Button>
          </div>
        )}
        {record?.status === CardTransferStatus.waitTransferCard && (
          <div className="p-10 d-flex-r jc-end border-top" onClick={handleTransferCard}>
            <Button type="primary" size="sm">
              转卡
            </Button>
          </div>
        )}
      </Page>
    </div>
  );
}
