import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../components/button';
import Page from '../components/page';
import Radiobox from '../components/radioBox';
import main, { AppLocalKey, Main } from '../main';
import { AppStore } from '../types';
import { APIProtocolType } from '../utils/dict';
import { delay, validateTel } from '../utils/utils';
import { LoadingMask, showConfirm, showToast } from '../components/dialog';
import { apiValidateNum } from '../api';

export default function LoginPage() {
  useSelector((state: AppStore) => state.theme);
  const navigate = useNavigate();
  const [username, setUsername] = useState(main.loginPhone);
  const [selectProtocol, setSelectProtocol] = useState(false);
  const [historyPhone, setHistoryPhone] = useState<string[]>([]);
  const [showHistoryPhone, setShowHistoryPhone] = useState(false);

  useEffect(() => {
    if (!main.loginPhone) {
      let un = localStorage.getItem(AppLocalKey.loginUsername);
      if (un) {
        setUsername(un);
      }
    }
    let _historyPhone = localStorage.getItem(AppLocalKey.historyPhone) ?? '';

    if (!!_historyPhone) {
      setHistoryPhone(_historyPhone.split(','));
    }
  }, []);

  useEffect(() => {
    main.loginPhone = username;
  }, [username]);

  async function validateUsername() {
    let vres: { iccid?: string; phonenumber?: string } = {};
    LoadingMask.show('正在验证...');
    let tmpSuccess = false;
    try {
      let res = await apiValidateNum(username);
      let data = res.data;
      if (data.code === 200) {
        vres.iccid = data.data?.iccid;
        vres.phonenumber = data.data?.phonenumber;
        tmpSuccess = true;
      }
    } catch (e) {
    } finally {
      LoadingMask.hide();
    }
    if (!tmpSuccess) {
      if (validateTel(username)) {
        vres.iccid = undefined;
        vres.phonenumber = undefined;
      }
    }
    return vres;
  }

  async function handleClick() {
    if (!username) {
      return showToast('请输入手机号或卡号');
    }

    if (!selectProtocol) {
      let modalRes = await showConfirm({
        title: '温馨提示',
        content: '点击同意表示您已阅读并同意《用户协议》和《隐私政策》',
        confirmBtnText: '同意',
      });
      if (!modalRes) return;
    }
    let res = await validateUsername();
    if (!res.iccid && !res.phonenumber) {
      return showToast('您好,未查询到该卡号,请联系人工处理!');
    } else if (!res.phonenumber && res.iccid) {
      navigate('/bindTel', { state: { iccid: res.iccid } });
    } else {
      navigate('/loginCode', { state: { username: res.phonenumber } });
    }
  }

  async function handleDoubleClick() {
    if (!username) {
      return showToast('请输入手机号或卡号');
    }

    let res = await validateUsername();
    if (!res.iccid && !res.phonenumber) {
      return showToast('您好,未查询到该卡号,请联系人工处理!');
    } else if (!res.phonenumber && res.iccid) {
      navigate('/bindTel', { state: { iccid: res.iccid } });
    } else {
      navigate('/loginCode', { state: { username: res.phonenumber, test: true } });
    }
  }

  return (
    <Page contentClassName="bw-100 bh-100 d-flex-c jc-center ai-center p-20">
      <img className="w-190 h-90" src={main.pic('img_login_logo.png', true)} alt="" />
      <div className="border border-radius-20 bw-100 pv-10 pl-10 d-flex-r ai-center mt-100 pos-r">
        <img className="w-14 h-20 mr-20" src={main.pic('icon_login_phone.png')} alt="" onClick={handleDoubleClick} />
        <input
          className="flex-1 font-size-14"
          value={username}
          type="number"
          max={11}
          placeholder="请输入自己的手机号/卡号"
          onChange={(e) => setUsername(e.target.value)}
          onFocus={() => setShowHistoryPhone(true)}
          onBlur={() => {
            delay(50).then(() => setShowHistoryPhone(false));
          }}
        />
        {!!username && (
          <div className="ph-10 d-flex-r ai-center" onClick={() => setUsername('')}>
            <img className="w-14" src={main.pic('icon_close_black.png')} alt="" />
          </div>
        )}
        {!!showHistoryPhone && !!historyPhone.length && (
          <div className="z-10 pos-a t-40 bg-color-white d-shadow l-0 r-0 border-radius-8">
            {historyPhone.map((i, idx) => (
              <div
                key={`${i}${idx}`}
                className={`p-10 border-bottom ${i.toString() === username ? 'bg-color-gray' : ''}`}
                onClick={() => {
                  setUsername(i.toString());
                }}
              >
                {i}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mt-10 font-size-12 text-color-9">
        输入手机号，开启全新体验！未注册手机号验证后将自动创建账号。
      </div>
      <Button className="mt-64" type="primary" shape="circle" size="md" long onClick={handleClick}>
        登录
      </Button>
      <div className="d-flex-r ai-center font-size-12 mt-20">
        <Radiobox className="mr-5" value={selectProtocol} onChange={(v) => setSelectProtocol(!!v)} />
        <div onClick={() => setSelectProtocol(!selectProtocol)}>已阅读并同意</div>
        <div
          className="text-color-primary"
          onClick={() => navigate('/protocol', { state: APIProtocolType.userAgreement })}
        >
          《用户协议》
        </div>
        <div>和</div>
        <div
          className="text-color-primary"
          onClick={() => navigate('/protocol', { state: APIProtocolType.privacyAgreement })}
        >
          《隐私政策》
        </div>
      </div>
      <img className="w-80 mt-100" src={main.pic('icon_oper_logo.png', main.currentTheme !== Main.def)} alt="" />
    </Page>
  );
}
