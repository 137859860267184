import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, CardPack, ChargeRenewal } from '../types';
import { cardPackRequest } from '../redux/cardPackReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearEmptyKey, formatNumber, modelParsePrice, parseLocationParams } from '../utils/utils';
import { LoadingMask, showBottom, showConfirm, showToast } from '../components/dialog';
import Page from '../components/page';
import AppBar from '../components/appBar';
import { apiGetBalance, apiPreOrder, apiUnifiedPay } from '../api';
import main from '../main';
import { PackageType, PayType } from '../utils/dict';
import ValidatePasswordDialog from './components/validatePasswordDialog';
import showPayPage from '../utils/pay';
import { currentCardRequest } from '../redux/currentCardReducer';
import { cardRequest } from '../redux/cardReducer';
import { orderRequest } from '../redux/orderReducer';
import { buyPackLogRequest } from '../redux/buyPackLogReducer';
import { incomeRequest } from '../redux/incomeReducer';
import { availableCouponClear } from '../redux/availableCouponReducer';
import { couponRequest } from '../redux/couponReducer';
import dayjs from 'dayjs';
import DefaultError from '../components/defaultPage/defaultError';
import Button from '../components/button';
import CouponSelect from './components/couponSelect';
import ChargeRenewalSelect from './components/chargePremoney';
import PayTypeSelect from './components/payTypeSelect';
import CountCalc from '../components/countCalc';
import { chargeRenewalRequest } from '../redux/chargeRenewalReducer';

export default function PackOrderConfirmPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);
  const currentCard = useSelector((state: AppStore) => state.currentCard);
  const payTypes = useSelector((state: AppStore) => state.payTypeConfig);
  const packList = useSelector((state: AppStore) => state.cardPack[currentCard?.result?.id ?? '']);
  // 已选套餐
  const [pack, setPack] = useState<CardPack>();
  const [numberLevel, setNumberLevel] = useState<number[]>([]);
  // 本期下期
  const [dateType, setDateType] = useState<string>('');
  // 支付类型
  const [payType, setPayType] = useState('');
  // 已选预存
  const [preMoney, setPreMoney] = useState<ChargeRenewal>();
  // 余额
  const [balance, setBalance] = useState(0);
  // 是否有密码
  const [ifPwd, setIfPwd] = useState(false);
  // 余额和密码是否加载完成
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  // 是否允许不足月购买
  const [isFull, setIsFull] = useState('N');
  // 已选优惠券
  const [coupons, setCoupons] = useState<string[]>([]);
  // 购买数量
  const [buyCount, setBuyCount] = useState(isFull === 'Y' ? 2 : 1);
  // 订单预览信息
  const [orderInfo, setOrderInfo] = useState<any>();
  // 微信支付环境是否准备好
  const [wxReady, setWxReady] = useState(false);
  // 预存金额获取
  const renewalList = useSelector((state: AppStore) => state.chargeRenewal?.[currentCard?.result?.id ?? '']);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => setWxReady(true), false);
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => setWxReady(true));
      }
    } else {
      setWxReady(true);
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, []); // eslint-disable-line
  useEffect(() => {
    if (!!packList?.results?.length) {
      let query = parseLocationParams(location.search);
      let _pack = packList.results.find((item) => item.id === query.packId);
      if (!query.packId || !_pack) {
        showToast('未找到套餐，请重试');
        if (location.state?.canBack) {
          navigate(-1);
        } else {
          navigate('/', { replace: true });
        }
      } else {
        setPack(_pack);
        setDateType(query.dateType);
        let _isFull =
          _pack?.isFull === 'Y' && _pack.packageType === PackageType.basicpackage && query.dateType === 'currMonth';
        setIsFull(_isFull ? 'Y' : 'N');
        let _numberLevel: Array<number> = clearEmptyKey(_pack.numberLevel?.split(',') ?? []).map((i: string) =>
          parseInt(i)
        );
        if (_isFull) {
          _numberLevel = _numberLevel.filter((i) => i > 1);
        }
        if (_numberLevel.length && _numberLevel[0] !== buyCount) {
          setNumberLevel(_numberLevel);
          setBuyCount(_numberLevel[0]);
        } else {
          setBuyCount(_isFull ? 2 : 1);
        }
      }
    }
  }, [packList]); // eslint-disable-line

  useEffect(() => {
    if (currentCard?.result?.id) {
      dispatch(cardPackRequest({ cardId: currentCard?.result?.id! }));
      dispatch(chargeRenewalRequest({ cardId: currentCard?.result?.id! }));
      loadUserBalance();
    }
  }, [currentCard]); // eslint-disable-line

  useEffect(() => {
    if (!payType && (payTypes.results?.length ?? 0) > 0) {
      setPayType(payTypes.results![0].payType!);
    }
  }, [payTypes]); // eslint-disable-line

  useEffect(() => {
    if (!!pack) {
      preOrder();
    }
  }, [coupons, buyCount, pack]); // eslint-disable-line

  async function loadUserBalance() {
    try {
      var res = await apiGetBalance(currentCard.result!.id!);
      var data = res.data;
      if (data.code === 200) {
        var _data = data.data;
        setBalance(modelParsePrice(_data.availableAmount));
        setBalanceLoaded(true);
        setIfPwd(_data.isPayPassword === true);
      }
    } catch (e) {}
  }
  async function preOrder() {
    try {
      var res = await apiPreOrder(
        pack!.id!,
        currentCard!.result!.id!,
        coupons,
        dateType === 'currMonth' ? 'now' : 'next',
        buyCount
      );
      let data = res.data;
      if (data.code === 200) {
        let _orderInfo = data.data;
        _orderInfo.salePrice = modelParsePrice(_orderInfo.salePrice);
        _orderInfo.orderPrice = modelParsePrice(_orderInfo.orderPrice);
        _orderInfo.realPrice = modelParsePrice(_orderInfo.realPrice);
        _orderInfo.couponPrice = modelParsePrice(_orderInfo.couponPrice);
        setOrderInfo(_orderInfo);
      } else {
        showToast(data.msg ?? '处理失败，请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '处理失败，请重试');
    }
  }

  async function handlePay() {
    if (user?.result?.payLimit === 'Y') {
      return showToast('当前用户已被限制支付');
    }
    if (!main.openId && !main.isDev) {
      return showToast('授权失败,请在微信内打开');
    }
    if (!balanceLoaded) {
      return showToast('获取余额失败,请重新进入');
    }

    if (!renewalList?.loaded) {
      return showToast('正在获取预存数据,请重试');
    }

    if (
      renewalList?.results?.length &&
      payType !== PayType.balance &&
      (preMoney?.amount ?? 0) < (orderInfo?.realPrice ?? 0)
    ) {
      return showToast('预存费用不能低于套餐金额');
    }

    let pwd: string = '';
    if (payType === PayType.balance) {
      if (!ifPwd) {
        let res = await showConfirm({
          title: '提示',
          content: '请设置操作密码',
          confirmBtnText: '去设置',
        });
        if (res !== 1) return;
        navigate('/passwordEdit');
        return;
      }
      let res = await showBottom({
        maskHide: true,
        buildContent: (onOk) => <ValidatePasswordDialog price={orderInfo.realPrice} onOk={onOk} />,
      });
      if (!res) return;
      pwd = res;
    }
    let price =
      (payType === PayType.balance
        ? orderInfo?.realPrice
        : preMoney?.payAmount ?? preMoney?.amount ?? orderInfo?.realPrice) ?? 0;
    try {
      LoadingMask.show('正在提交...');

      var body: any = {
        cardId: currentCard.result!.id!,
        cardPackDetailId: pack!.id!,
        couponCodeIds: coupons,
        dateType: dateType === 'currMonth' ? 'now' : 'next',
        payType: price > 0 ? payType : PayType.balance,
        openId: main.openId,
        appId: main.appId,
        number: buyCount,
      };
      if (payType === PayType.balance) {
        body.payPassword = pwd;
      } else {
        body.cardPayDetailId = preMoney?.id;
      }

      let res = await apiUnifiedPay(body);
      let data = res.data;
      if (data.code === 200) {
        if (payType === PayType.balance || price === 0) {
          paySuccess();
          navigate('/orderList', { replace: true });
        } else if (payType === PayType.hsmWxJsPay || payType === PayType.aliPage) {
          //  惠收米 收银台支付 第一章
          let _data = data.data;
          let payRes = await showPayPage(_data, payType);
          if (payRes.code === 300) {
            if (payRes.url) {
              LoadingMask.hide();
              if (payType === PayType.aliPage) {
                navigate('/orderList', { replace: true });
              }
              window.location.href = payRes.url;
              // await showConfirm({
              //   title: '提示',
              //   content: '是否支付完成?',
              //   confirmBtnText: '支付完成',
              //   cancelBtnText: '未完成',
              // });
              // paySuccess();
              // navigate('/pack-order-list', { replace: true });
            }
            showToast(payRes.msg);
          } else if (payRes.code === 500) {
            showToast(payRes.msg);
          } else {
            showToast('数据还在准备,请稍后重试');
          }
        } else {
          if (wxReady) {
            let _data = data.data;
            let payRes = await showPayPage(_data, payType);

            if (payRes.code === 200) {
              paySuccess();
              navigate('/orderList', { replace: true });
            } else if (payRes.code === 300) {
              if (payRes.url) {
                LoadingMask.hide();
                navigate('/iframe', { state: { url: payRes.url, title: '银联支付', pay: true } });
              }
              showToast(payRes.msg);
            } else if (payRes.code === 500) {
              showToast(payRes.msg);
            }
          } else {
            showToast('数据还在准备,请稍后重试');
          }
        }
      } else {
        showToast(data.msg ?? '下单失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '下单失败');
    } finally {
      LoadingMask.hide();
    }
  }

  function paySuccess() {
    dispatch(currentCardRequest({ forceRefresh: true }));
    dispatch(cardRequest({ forceRefresh: true }));
    dispatch(orderRequest({ cardId: currentCard.result!.id!, forceRefresh: true }));
    dispatch(buyPackLogRequest({ forceRefresh: true }));
    dispatch(incomeRequest({ forceRefresh: true }));
    dispatch(availableCouponClear());
    dispatch(couponRequest({ cardId: currentCard.result!.id!, forceRefresh: true }));
  }

  if (!pack) {
    return (
      <Page className="bg-color-gray" contentClassName="d-flex-c ai-center pt-50" appBar={<AppBar title="确认订单" />}>
        <DefaultError tips="未找到相关套餐" />
        <Button type="primary" size="sm" shape="circle" onClick={() => navigate('/', { replace: true })}>
          <div className="ph-20">回到首页</div>
        </Button>
      </Page>
    );
  }

  let _st = (orderInfo?.startEffectTime ?? '').replaceAll('/', '-');
  var _et = (orderInfo?.endEffectTime ?? '').replaceAll('/', '-');
  var startTime = _st ? dayjs(_st ?? '').format('YYYY/MM/DD HH:mm') : '';
  var endTime = _et ? dayjs(_et ?? '').format('YYYY/MM/DD HH:mm') : '';

  let price =
    (payType === PayType.balance
      ? orderInfo?.realPrice
      : preMoney?.payAmount ?? preMoney?.amount ?? orderInfo?.realPrice) ?? 0;

  return (
    <Page
      className="bg-color-gray d-flex-c"
      contentClassName="flex-1 h-1 overflow-hidden d-flex-c"
      appBar={<AppBar title="确认订单" className="bg-color-t0" />}
    >
      <div className="flex-1 h-1 overflow-y p-10">
        <div className=" bg-color-white border-radius-8 p-10">
          <div className="d-flex-r">
            <img className="h-60 mr-10" src={main.pic('icon_pack.png', true)} alt="" />
            <div className="flex-1 h-60 d-flex-c jc-around">
              <div className="d-flex-r ai-center">
                <div className="flex-1 font-size-14 font-widget-600">{pack!.name}</div>
                <div className="font-size-16">
                  <span className="font-size-14">￥</span>
                  {pack!.discountPrice ? pack!.discountPrice : pack!.salePrice}
                </div>
              </div>
              <div className="d-flex-r ai-center">
                <div className="flex-1">
                  <span className="text-color-9">套餐包含</span>
                  {orderInfo?.packDetailContent}
                </div>
                {!numberLevel.length && (
                  <CountCalc min={isFull === 'Y' ? 2 : 1} max={24} value={buyCount} onChange={setBuyCount} />
                )}
              </div>
            </div>
          </div>
          <div className="text-color-9 mt-10">
            有效期为 {startTime}至{endTime}
          </div>
        </div>
        {!!numberLevel.length && (
          <div className="bg-color-white border-radius-8 pl-10 pr-10 mt-10">
            <div className="font-size-14 font-widget-600 pv-10">选择购买月份</div>
            <div className=" d-flex-r flex-wrap">
              {numberLevel.map((i, idx) => (
                <div
                  key={i}
                  className={`w-100 h-70 lh-70 text-center bg-color-primary-t1 border border-radius-8 mb-10 border-color-primary${
                    i === buyCount ? '' : '-t1'
                  } ${(idx + 1) % 3 !== 0 ? 'mr-17' : ''}`}
                  onClick={() => setBuyCount(i)}
                >
                  <span className="font-size-22">{i}</span>个月
                </div>
              ))}
            </div>
          </div>
        )}
        <CouponSelect
          className="bg-color-white border-radius-8 mt-10 ph-14"
          cardId={currentCard.result!.id!}
          packDetailId={pack.id!}
          number={buyCount}
          values={coupons}
          onChange={(v) => setCoupons(v)}
        />
        <div className="bg-color-white border-radius-8 ph-10 pb-10 mt-10">
          <div className="font-size-14 font-widget-600 pt-10">价格明细</div>
          <div className="d-flex-r ai-center pt-10">
            <div className="flex-1">商品总价</div>
            <div className="font-size-14">
              <span className="font-size-12">￥</span>
              {formatNumber(orderInfo?.salePrice)}
            </div>
          </div>
          <div className="d-flex-r ai-center pt-10">
            <div className="flex-1">优惠金额</div>
            <div className="font-size-14 text-color-primary">
              <span className="font-size-12">￥</span>
              {formatNumber(orderInfo?.couponPrice)}
            </div>
          </div>
          <div className="d-flex-r ai-center pt-10">
            <div className="flex-1">商品合计</div>
            <div className="font-size-14">
              <span className="font-size-12">￥</span>
              {formatNumber(orderInfo?.orderPrice)}
            </div>
          </div>
        </div>
        {!!renewalList?.results?.length && (
          <div className="bg-color-white border-radius-8 ph-10 pb-10 mt-10">
            <div className="font-size-14 font-widget-600 pv-10">选择充值面额</div>
            <ChargeRenewalSelect
              disabled={payType === PayType.balance}
              cardId={currentCard.result!.id!}
              value={preMoney?.id}
              minPrice={orderInfo?.orderPrice ?? pack.discountPrice ?? pack.salePrice!}
              onChange={(v) => {
                setPreMoney(v);
              }}
            />
          </div>
        )}
        {!!renewalList?.results?.length && (
          <div className="mt-10 text-color-9">*卡片话费余额可以用于订购套餐包和加油包使用</div>
        )}
        {user?.result?.payLimit !== 'Y' && (
          <PayTypeSelect
            className="bg-color-white border-radius-8 mt-10 ph-10 pb-10"
            useBalance
            balance={balance}
            value={payType}
            onChange={(v) => setPayType(v)}
            cardId={currentCard.result!.id!}
          />
        )}
      </div>
      <div className="p-10 d-flex-r bg-color-white ai-center">
        <div className="flex-1">
          合计：￥<span className="font-size-16 font-widget-600 text-color-primary">{price}</span>
        </div>
        <Button type="primary" size="md" shape="circle" onClick={handlePay}>
          <div className="ph-10">立即支付</div>
        </Button>
      </div>
    </Page>
  );
}
