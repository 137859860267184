import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, BuyPackLog } from '../types';
import { cardRequest } from '../redux/cardReducer';
import { buyPackLogRequest } from '../redux/buyPackLogReducer';
import { PackBuyLogStatus } from '../utils/dict';
import { currentCardRequest } from '../redux/currentCardReducer';
import { dataListStatus } from '../utils/utils';
import DefaultNoData from '../components/defaultPage/defaultNoData';
import PopMenu, { PopMenuItem } from '../components/popMenu';
import Page from '../components/page';
import AppBar from '../components/appBar';
import main from '../main';
import dayjs from 'dayjs';
import TabBar from '../components/tabBar';

export default function BuyPackLogListPage() {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStore) => state.user);
  const currCard = useSelector((state: AppStore) => state.currentCard);
  const cardList = useSelector((state: AppStore) => state.card);
  const buyPackLogList = useSelector((state: AppStore) => state.buyPackLog);
  const [tabValue, setTabValue] = useState<string>(PackBuyLogStatus.getTypeList().at(0)!);
  const [filterCard, setFilterCard] = useState(currCard?.result?.iccid ?? 'all');
  useEffect(() => {
    if (user.result?.userId) {
      dispatch(cardRequest());
      dispatch(buyPackLogRequest());
      dispatch(currentCardRequest());
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (!!currCard?.result?.iccid && (!filterCard || filterCard === 'all')) {
      setFilterCard(currCard.result.iccid);
    }
  }, [currCard]); // eslint-disable-line

  let _centerView: any = [];
  let statusView = dataListStatus({ list: buyPackLogList });
  if (statusView) {
    _centerView = [statusView];
  } else {
    let curr: BuyPackLog[] = [];
    buyPackLogList!.results!.forEach((item) => {
      if (item.status === tabValue) {
        if (filterCard !== 'all' && item.iccid !== filterCard) return;
        curr.push(item);
      }
    });
    if (!curr.length) {
      _centerView = [
        <div className="mt-50">
          <DefaultNoData key="no-data" />
        </div>,
      ];
    } else {
      _centerView = curr.map((item) => (
        <div key={item.id} className="mb-10 border-bottom">
          <div className="d-flex-r font-size-14 font-widget-600 mb-10">
            <div className="flex-1">{item.packDetailName}</div>
            <div>X1</div>
          </div>
          <div className="d-flex-r ai-center mb-10">
            <div className="w-60 text-color-9">卡号：</div>
            {item.iccid}
          </div>
          <div className="d-flex-r ai-center mb-10">
            <div className="w-60 text-color-9">订单号：</div>
            {item.id}
          </div>
          <div className="d-flex-r ai-center mb-10">
            <div className="w-60 text-color-9">总期数：</div>
            <div className="flex-1">{item.totalPhases}</div>
            <div className="w-60 text-color-9">当前期数：</div>
            <div className="flex-1">{item.thisPhases}</div>
          </div>
          <div className="d-flex-r ai-center mb-10">
            <div className="w-60 text-color-9">有效期:</div>
            {dayjs(item.startEffectTime).format('YYYY/MM/DD HH:mm')}至
            {dayjs(item.endEffectTime).format('YYYY/MM/DD HH:mm')}
          </div>
        </div>
      ));
    }
  }
  let list: PopMenuItem[] =
    (cardList?.results?.length ?? 0) > 0 ? cardList!.results!.map((i) => ({ label: i.iccid!, value: i.iccid! })) : [];
  list.unshift({ label: '全部', value: 'all' });
  let selectItem: PopMenuItem | undefined = list.find((i) => i.value === filterCard);
  return (
    <div className="vw-100 vh-100 pos-r">
      <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
      <Page
        className="pos-r d-flex-c"
        contentClassName="flex-1 h-1 d-flex-c"
        appBar={<AppBar className="bg-color-t0" white title="订购记录" />}
      >
        <div className="d-flex-r mb-10">
          <PopMenu className="flex-1" options={list} value={selectItem?.value} onClick={(v) => setFilterCard(v)}>
            <div className="vw-100 d-flex-r ai-center ph-20 pv-10 text-color-white">
              <div className="flex-1">卡号：{selectItem?.label}</div>
              <img className="w-14" src={main.pic('icon_arrow_down.png')} alt="" />
            </div>
          </PopMenu>
        </div>
        <TabBar
          white
          value={tabValue}
          options={PackBuyLogStatus.getTypeList().map((key: string) => ({
            label: PackBuyLogStatus.getTypeStr(key),
            value: key,
          }))}
          onChange={setTabValue}
        />
        <div className="flex-1 h-1 overflow-y p-10 m-10 bg-color-white border-radius-8 d-shadow">{_centerView}</div>
      </Page>
    </div>
  );
}
