import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import main from '../../main';
import { chargeRenewalRequest } from '../../redux/chargeRenewalReducer';
import { AppStore, ChargeDirect, ChargeRenewal } from '../../types';
import { formatNumber, modelParsePrice } from '../../utils/utils';
import { CouponType } from '../../utils/dict';
interface RenewalProps {
  /** 禁用 一般是余额支付时 */
  disabled?: boolean;
  /** 卡ID */
  cardId: string;
  /** 已选预存ID */
  value?: string;
  /** 适用金额 小于本金额的不可和选 */
  minPrice: number;
  onChange: (value?: ChargeRenewal) => void;
}
export default function ChargeRenewalSelect({ cardId, value, disabled, minPrice, onChange }: RenewalProps) {
  const renewalList = useSelector((state: AppStore) => state.chargeRenewal?.[cardId]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(chargeRenewalRequest({ cardId }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (disabled && value) {
      onChange(undefined);
    } else if (!disabled && !value && renewalList?.loaded && !!renewalList?.results?.length) {
      let def = renewalList.results.find((item) => item.payAmount >= minPrice);
      onChange(def);
    }
  }, [disabled, renewalList]); // eslint-disable-line

  if (!renewalList?.loaded && !renewalList?.results?.length) {
    return <div></div>;
  }

  let showList: React.ReactNode[] = [];

  let current = renewalList.results!.findIndex((i) => i.id === value);
  let currentValue = renewalList.results![current];
  // 是否显示优惠信息 和 三角箭头指向
  let showCouponInfo = currentValue && currentValue.payAmount < currentValue.amount;
  renewalList!.results!.forEach((i, idx) => {
    let _disabled = disabled || i.payAmount < minPrice;
    showList.push(
      <div
        className={`w-100 h-70 border-radius-8 d-flex-c ai-center jc-center mt-10 pos-r border  ${
          value === i.id && !_disabled ? 'border-color-primary' : 'border-color-primary-t1'
        } ${(idx + 1) % 3 !== 0 ? 'mr-17' : ''} ${_disabled ? 'bg-color-gray' : 'bg-color-primary-t1'}`}
        key={i.id! + idx}
        onClick={_disabled ? undefined : () => onChange(i)}
      >
        {i.payAmount < i.amount && (
          <img className="w-30 h-30 pos-a t-0 r-0" src={main.pic('icon_coupon_flag.png', true)} alt="" />
        )}
        <div className={`${_disabled ? 'text-color-9' : ''} font-widget-600`}>
          <span className="font-size-18">{i.amount}</span>
          <span className="font-size-16">元</span>
        </div>
        <div className={`${_disabled ? 'text-color-9' : 'text-color-primary'} mt-5`}>
          售价<span className="font-size-14">{i.payAmount}</span>元
        </div>
        {showCouponInfo && currentValue.id === i.id && (
          <img className="w-20 pos-a z-10 t-68 l-40" src={main.pic('icon_pack_select_d.png', true)} alt="" />
        )}
      </div>
    );
  });

  if (showCouponInfo) {
    showList.splice(
      Math.floor(current / 3) * 3 + 3,
      0,
      <div className="w-335 h-72 pos-r d-flex-r ai-center mt-10 text-color-primary">
        <img className="pos-a w-335 h-72 z-0" src={main.pic('pic_coupon_desc.png', true)} alt="" />
        <div className="pos-r z-10 w-72 d-flex-c ai-center jc-center">
          <div>
            <span className="font-size-18">{currentValue.amount}</span>元
          </div>
          <div>价值</div>
        </div>
        <div className="pos-r z-10 h-72 flex-1 pl-20 d-flex-c jc-evenly">
          <div>
            充值{currentValue.payAmount}元到账{currentValue.amount}元
          </div>
          <div>充值金额{currentValue.payAmount}元</div>
        </div>
      </div>
    );
  }

  return <div className="d-flex-r flex-wrap">{showList}</div>;
}

interface DirectProps {
  /** 卡ID */
  cardId: string;
  /** 已选预存ID */
  value?: string;
  onChange: (value?: ChargeDirect) => void;
}
export function ChargeDirectSelect({ cardId, value, onChange }: DirectProps) {
  const directList = useSelector((state: AppStore) => state.chargeDirect[cardId]);

  useEffect(() => {
    if (!value && directList?.loaded && !!directList?.results?.length) {
      let def = directList.results[0];
      onChange(def);
    }
  }, [directList]); // eslint-disable-line

  if (!directList?.loaded && !directList?.results?.length) {
    return <div></div>;
  }

  let showList: React.ReactNode[] = [];

  let current = directList.results!.findIndex((i) => i.payDetailId === value);
  let currentValue = directList.results![current];
  let showCouponInfo =
    currentValue && ((currentValue.amount ?? 0) < (currentValue.price ?? 0) || currentValue.coupons?.length);
  directList!.results!.forEach((i, idx) => {
    showList.push(
      <div
        className={`w-110 h-70 border-radius-8 d-flex-c ai-center jc-center mt-10 pos-r border  ${
          value === i.payDetailId ? 'border-color-primary' : 'border-color-primary-t1'
        } ${(idx + 1) % 3 !== 0 ? 'mr-10' : ''} bg-color-primary-t1`}
        key={i.payDetailId! + idx}
        onClick={() => onChange(i)}
      >
        {((i.amount ?? 0) < (i.price ?? 0) || !!i.coupons?.length) && (
          <img className="w-30 h-30 pos-a t-0 r-0" src={main.pic('icon_coupon_flag.png', true)} alt="" />
        )}
        <div className={`font-widget-600`}>
          <span className="font-size-18">{i.price}</span>
          <span className="font-size-16">元</span>
        </div>
        <div className={`text-color-primary mt-5`}>
          售价<span className="font-size-14">{i.amount}</span>元
        </div>
        {showCouponInfo && i.payDetailId === currentValue.payDetailId && (
          <img className="w-20 pos-a z-10 t-68 l-40" src={main.pic('icon_pack_select_d.png', true)} alt="" />
        )}
      </div>
    );
  });

  if (showCouponInfo) {
    // 价值 到账金额 + 抵扣券金额 + 流量券1元1G
    let priceValue = modelParsePrice(currentValue.price ?? 0);
    // 总共送出的流量
    let giveFlow = 0.0;
    // 总共送出的优惠券金额
    let giveCouponPrice = 0.0;
    // 展示送的内容
    let tipsStr: string[] = [];

    if (currentValue.coupons) {
      for (let i of currentValue.coupons) {
        tipsStr.push(`送${i.couponName} X${formatNumber(i.couponNum ?? 0)}张;`);
        if (i.couponType === CouponType.flow) {
          giveFlow += (i.addFlow ?? 0) * (i.couponNum ?? 0);
        } else {
          giveCouponPrice += (i.reduceAmount ?? 0) * (i.couponNum ?? 0);
        }
      }
    }

    priceValue += giveFlow + giveCouponPrice;

    showList.splice(
      Math.floor(current / 3) * 3 + 3,
      0,
      <div key="giveCoupon" className="w-350 h-72 pos-r d-flex-r ai-center mt-10 text-color-primary">
        <img className="pos-a w-350 h-72 z-0" src={main.pic('pic_coupon_desc.png', true)} alt="" />
        <div className="pos-r z-10 w-72 d-flex-c ai-center jc-center">
          <div>
            <span className="font-size-18">{priceValue}</span>元
          </div>
          <div>价值</div>
        </div>
        <div className="pos-r z-10 h-72 flex-1 pl-20 d-flex-c jc-evenly">
          <div>
            充值{currentValue.amount ?? 0}元到账{currentValue.price ?? 0}元(立即到账)
          </div>
          {!!tipsStr.length ? (
            tipsStr.map((i, idx) => (
              <div key={i + idx}>
                {idx + 1},{i}
              </div>
            ))
          ) : (
            <div>1,充值金额{currentValue.amount}元</div>
          )}
        </div>
      </div>
    );
  }

  return <div className="d-flex-r flex-wrap">{showList}</div>;
}
