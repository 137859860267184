import React, { useRef, useState } from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import TabBar from '../components/tabBar';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../types';
import Button from '../components/button';
import { validateTel } from '../utils/utils';
import { LoadingMask, showToast } from '../components/dialog';
import { apiChangeTelByPassword, apiChangeTelByTel, apiGetCommonCaptcha } from '../api';
import { logoutRequest } from '../redux/logoutReducer';
import { useNavigate } from 'react-router-dom';

const time = 60;
export default function ChangePhonePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);
  const [tabValue, setTabvalue] = useState('phone');
  const [oldSmscode, setOldSmscode] = useState('');
  const oldRef = useRef<{ time: number; timer?: NodeJS.Timer }>({ time: 0 });
  const [oldSmstime, setOldSmstime] = useState(0);
  const [newTel, setNewTel] = useState('');
  const [newSmscode, setNewSmscode] = useState('');
  const newRef = useRef<{ time: number; timer?: NodeJS.Timer }>({ time: 0 });
  const [newSmstime, setNewSmstime] = useState(0);
  const [password, setPassword] = useState('');

  async function handleSendOldCaptcha() {
    let res = await sendCaptcha(user.result?.phonenumber ?? '');
    if (!res) return;
    startOldTimer();
  }

  function startOldTimer() {
    if (oldRef.current.timer) return;
    oldRef.current.time = time;
    oldRef.current.timer = setInterval(() => {
      oldRef.current.time--;
      if (oldRef.current.time < 0) {
        clearInterval(oldRef.current.timer);
      }
      setOldSmstime(oldRef.current.time);
    }, 1000);
  }

  async function handleSendNewCaptcha() {
    let res = await sendCaptcha(newTel);
    if (!res) return;
    startNewTimer();
  }

  function startNewTimer() {
    if (newRef.current.timer) return;
    newRef.current.time = time;
    newRef.current.timer = setInterval(() => {
      newRef.current.time--;
      if (newRef.current.time < 0) {
        clearInterval(newRef.current.timer);
      }
      setNewSmstime(newRef.current.time);
    }, 1000);
  }

  function handleSwitchTab(v: string) {
    setTabvalue(v);
    setOldSmscode('');
    setNewSmscode('');
    setNewTel('');
    setPassword('');
  }

  async function sendCaptcha(tel: string) {
    if (!validateTel(tel)) {
      showToast('请输入正确的手机号');
      return 0;
    }
    LoadingMask.show('正在发送...');
    try {
      let res = await apiGetCommonCaptcha(tel);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '发送成功');
        return 1;
      } else {
        showToast(data.msg ?? '发送失败');
        return 0;
      }
    } catch (e: any) {
      showToast(e.message ?? '发送失败');
      return 1;
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleTelChangePhone() {
    if (tabValue === 'tel') {
      if (!oldSmscode) {
        return showToast('请输入现有手机号验证码');
      }
    } else {
    }
    if (!validateTel(newTel)) {
      return showToast('请输入正确的新手机号');
    }
    if (!newSmscode) {
      return showToast('请输入新手机号验证码');
    }
    try {
      LoadingMask.show('正在修改...');
      var res;
      if (tabValue === 'tel') {
        res = await apiChangeTelByTel(user.result?.phonenumber, oldSmscode, newTel, newSmscode);
      } else {
        res = await apiChangeTelByPassword(newTel, newSmscode, password);
      }

      var data = res!.data;
      if (data.code === 200) {
        showToast(data.msg ?? '修改成功,请重新登录');
        dispatch(logoutRequest());
        navigate('/login', { replace: true });
      } else {
        showToast(data.msg || '修改失败');
      }
    } catch (e: any) {
      showToast(e.message || '修改失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page contentClassName="d-flex-c bg-color-white" appBar={<AppBar title="更换手机号" />}>
      <TabBar
        options={[
          { label: '手机号', value: 'phone' },
          { label: '操作密码', value: 'pwd' },
        ]}
        value={tabValue}
        onChange={handleSwitchTab}
      />
      {tabValue === 'phone' ? (
        <div className="d-flex-c p-20 ai-center">
          <div className="text-color-9 mv-20">当前手机号</div>
          <div className="font-size-20 text-color-primary mb-10">{user.result?.phonenumber}</div>
          <div className="text-color-9">
            收不到验证码，可通过
            <span className="text-color-primary" onClick={() => setTabvalue('pwd')}>
              操作密码
            </span>
            更换
          </div>
          <div className="bw-100 d-flex-r ai-center p-10 border-radius-40 border mt-20">
            <input
              className="flex-1"
              type="text"
              placeholder="请输入在用手机号的验证码"
              value={oldSmscode}
              maxLength={4}
              onChange={(e) => setOldSmscode(e.target.value)}
            />
            <div
              className={`ph-10 ${oldSmstime > 0 ? 'text-color-9' : 'text-color-primary'}`}
              onClick={handleSendOldCaptcha}
            >
              {oldSmstime > 0 ? `(${oldSmstime}s)` : '发送验证码'}
            </div>
          </div>
          <div className="bw-100 d-flex-r ai-center p-10 border-radius-40 border mt-20">
            <input
              className="flex-1"
              type="text"
              placeholder="请输入新手机号"
              value={newTel}
              maxLength={11}
              onChange={(e) => setNewTel(e.target.value)}
            />
          </div>
          <div className="bw-100 d-flex-r ai-center p-10 border-radius-40 border mt-20">
            <input
              className="flex-1"
              type="text"
              placeholder="请输入新手机号的验证码"
              value={newSmscode}
              maxLength={4}
              onChange={(e) => setNewSmscode(e.target.value)}
            />
            <div
              className={`ph-10 ${newSmstime > 0 ? 'text-color-9' : 'text-color-primary'}`}
              onClick={handleSendNewCaptcha}
            >
              {newSmstime > 0 ? `(${newSmstime}s)` : '发送验证码'}
            </div>
          </div>
          <div className="bw-100 mt-40">
            <Button type="primary" size="md" shape="circle" long onClick={handleTelChangePhone}>
              确认
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex-c p-20 ai-center">
          <div className="text-color-9 mv-20">当前手机号</div>
          <div className="font-size-20 text-color-primary mb-10">{user.result?.phonenumber}</div>
          <div className="text-color-9">
            忘记操作密码，可通过
            <span className="text-color-primary" onClick={() => setTabvalue('phone')}>
              手机号
            </span>
            更换
          </div>
          <div className="bw-100 d-flex-r ai-center p-10 border-radius-40 border mt-20">
            <input
              className="flex-1"
              type="text"
              placeholder="请输入操作密码"
              value={password}
              maxLength={6}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="bw-100 d-flex-r ai-center p-10 border-radius-40 border mt-20">
            <input
              className="flex-1"
              type="text"
              placeholder="请输入新手机号"
              value={newTel}
              maxLength={11}
              onChange={(e) => setNewTel(e.target.value)}
            />
          </div>
          <div className="bw-100 d-flex-r ai-center p-10 border-radius-40 border mt-20">
            <input
              className="flex-1"
              type="text"
              placeholder="请输入新手机号的验证码"
              value={newSmscode}
              maxLength={4}
              onChange={(e) => setNewSmscode(e.target.value)}
            />
            <div
              className={`ph-10 ${newSmstime > 0 ? 'text-color-9' : 'text-color-primary'}`}
              onClick={handleSendNewCaptcha}
            >
              {newSmstime > 0 ? `(${newSmstime}s)` : '发送验证码'}
            </div>
          </div>
          <div className="bw-100 mt-40">
            <Button type="primary" size="md" shape="circle" long onClick={handleTelChangePhone}>
              确认
            </Button>
          </div>
        </div>
      )}
    </Page>
  );
}
