import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apiPackOrderTimeout } from '../api';
import AppBar from '../components/appBar';
import Button from '../components/button';
import { LoadingMask, showToast } from '../components/dialog';
import Page from '../components/page';
import main from '../main';
import { orderDetailRequest } from '../redux/orderDetailReducer';
import { AppStore } from '../types';
import { OrderDateType, OrderStatus, PackageType, PayType } from '../utils/dict';
import { dataDetailStatus, formatNumber } from '../utils/utils';

export default function OrderDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { canBack } = useLocation().state ?? {};
  const user = useSelector((state: AppStore) => state.user);
  const order = useSelector((state: AppStore) => state.orderDetail[id ?? '']);
  useEffect(() => {
    if (user.loaded && user.result?.userId) {
      if (id) {
        dispatch(orderDetailRequest({ id: id ?? '' }));
      } else {
        if (canBack) {
          navigate(-1);
        } else {
          navigate('/', { replace: true });
        }
      }
    }
  }, [user]); // eslint-disable-line

  async function handleCloseOrder() {
    LoadingMask.show('正在关闭...');
    try {
      let res = await apiPackOrderTimeout(id!);
      let data = res.data;
      if (data.code === 200) {
        showToast('关闭成功');
        dispatch(orderDetailRequest({ id: id ?? '', forceRefresh: true }));
      } else {
        showToast(data.msg ?? '关闭失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '关闭失败');
    } finally {
      LoadingMask.hide();
    }
  }

  let el = dataDetailStatus({ detail: order });
  let detail = order?.result;
  return (
    <div className="vw-100 vh-100 pos-r">
      <div className="vh-50 vw-100 gradient-desc-primary-t0 pos-a z-0 l-0 t-0"></div>
      <Page
        className="d-flex-c pos-a"
        contentClassName="flex-1 h-1 overflow-hidden d-flex-c"
        appBar={<AppBar white className="bg-color-t0" title="订单详情" />}
      >
        {!!el ? (
          <div className="mt-50">{el}</div>
        ) : (
          <div className="flex-1 h-1 p-10 overflow-y">
            <div className="bg-color-white border-radius-10 d-shadow p-10 d-flex-r">
              <img className="w-40 mr-10" src={main.pic('icon_pack.png', true)} alt="" />
              <div className="flex-1 d-flex-c jc-around">
                <div>{detail?.packDetailName}</div>
                <div className="d-flex-r ai-center">
                  <div className="flex-1">￥{formatNumber(detail?.totalPrice)}</div>
                  <div className="text-color-9">X1</div>
                </div>
              </div>
            </div>
            <div className="bg-color-white border-radius-10 d-shadow ph-10 mt-10">
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">商品总价</div>
                <div className="font-size-14">￥{detail?.orderPrice}</div>
              </div>
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">优惠抵扣</div>
                <div className="font-size-14 text-color-primary">￥{detail?.couponPrice}</div>
              </div>
              <div className="pv-10 d-flex-r">
                <div className="flex-1 text-color-9">实付金额</div>
                <div className="font-size-14 text-color-primary">￥{detail?.realPrice}</div>
              </div>
            </div>
            <div className="bg-color-white border-radius-10 d-shadow ph-10 mt-10">
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">订单编号</div>
                <div className="font-size-14">{detail?.id}</div>
              </div>
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">支付方式</div>
                <div>{PayType.typeStr(detail?.payType)}</div>
              </div>
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">充值卡号</div>
                <div className="font-size-14">{detail?.iccid}</div>
              </div>
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">订单状态</div>
                <div>{OrderStatus.getTypeStr(detail?.status)}</div>
              </div>
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">套餐类型</div>
                <div>{PackageType.getTypeStr(detail?.packageType)}</div>
              </div>
              <div className="pv-10 border-bottom d-flex-r">
                <div className="flex-1 text-color-9">时间类型</div>
                <div>{OrderDateType.getTypeStr(detail?.dateType)}</div>
              </div>
              <div className={`pv-10 ${detail?.payTime ? 'border-bottom' : ''} d-flex-r`}>
                <div className="flex-1 text-color-9">创建时间</div>
                <div>{detail?.createTime}</div>
              </div>
              {detail?.payTime && (
                <div className="pv-10 border-bottom d-flex-r">
                  <div className="flex-1 text-color-9">付款时间</div>
                  <div>{detail?.payTime}</div>
                </div>
              )}
            </div>

            {!!detail?.cardPackOrderDetailList?.length && (
              <div className="bg-color-white border-radius-10 d-shadow ph-10 mt-10">
                <div className="font-size-14 pv-10">套餐包含</div>
                {detail!.cardPackOrderDetailList!.map((i, idx) => (
                  <div key={idx} className="pv-10 border-bottom d-flex-r">
                    <div className="flex-1 text-color-9">
                      第{i.thisPhases}/{i.totalPhases}期
                    </div>
                    <div>
                      {dayjs(i.startEffectTime!).format('YYYY-MM-DD')} - {dayjs(i.endEffectTime!).format('YYYY-MM-DD')}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {!el && detail?.status === OrderStatus.wait && (
          <div className="p-10">
            <Button type="primary" size="md" shape="circle" long onClick={handleCloseOrder}>
              关闭订单
            </Button>
          </div>
        )}
      </Page>
    </div>
  );
}
