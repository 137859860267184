import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetPackOrderDetail } from '../api';
import { AppStore, DetailRecord, Order } from '../types';
import { orderUpdate } from './orderReducer';

interface RequestParams {
  id: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams extends RequestParams {
  result: Order;
}

interface FailureParams extends RequestParams {
  error: string;
}

type OrderMap = { [x: string]: DetailRecord<Order> };

const orderDetailSlice = createSlice({
  name: 'orderDetail',
  initialState: {} as OrderMap,
  reducers: {
    orderDetailRequest: (state: OrderMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { fetching: false, loaded: false };
      }
      state[action.payload.id].fetching = true;
      return state;
    },
    orderDetailSuccess: (state: OrderMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { fetching: false, loaded: false };
      }
      state[action.payload.id].fetching = false;
      state[action.payload.id].loaded = true;
      state[action.payload.id].result = action.payload.result!;
      return state;
    },
    orderDetailFailure: (state: OrderMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = { fetching: false, loaded: false };
      }
      state[action.payload.id].fetching = false;
      state[action.payload.id].loaded = true;
      state[action.payload.id].error = action.payload.error;
      return state;
    },
    orderDetailClear: (state: OrderMap) => {
      state = {};
      return state;
    },
  },
});

export const { orderDetailRequest, orderDetailSuccess, orderDetailFailure, orderDetailClear } =
  orderDetailSlice.actions;
export default orderDetailSlice.reducer;

export const orderDetailEffect = {
  'orderDetail/clear': (store: ToolkitStore<AppStore>) => store.dispatch(orderDetailClear()),
  'orderDetail/orderDetailRequest': async (
    store: ToolkitStore<AppStore>,
    action: PayloadAction<RequestParams | undefined>
  ) => {
    var orderDetail = store.getState().orderDetail[action.payload?.id || ''];
    if (orderDetail?.loaded === true && !orderDetail?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(orderDetailSuccess({ id: action.payload?.id ?? '', result: orderDetail!.result! }));
    } else {
      try {
        let res = await apiGetPackOrderDetail(action.payload?.id ?? '');
        let data = res.data;
        if (data.code === 200) {
          store.dispatch(orderDetailSuccess({ id: action.payload?.id ?? '', result: data.data }));
          store.dispatch(orderUpdate({ result: data.data }));
        } else {
          store.dispatch(orderDetailFailure({ id: action.payload?.id ?? '', error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(orderDetailFailure({ id: action.payload?.id ?? '', error: e.toString() || '获取失败' }));
      }
    }
  },
};
