import React, { useEffect } from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../types';
import { useNavigate } from 'react-router-dom';
import { complaintRequest } from '../redux/complaintReducer';
import { dataListStatus } from '../utils/utils';
import main from '../main';
import { ComplaintStatus } from '../utils/dict';

export default function ComplaintListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: AppStore) => state.user);
  const complaint = useSelector((state: AppStore) => state.complaint);
  useEffect(() => {
    if (user.result?.userId) {
      dispatch(complaintRequest());
    }
  }, [user]); // eslint-disable-line

  let el = dataListStatus({ list: complaint });

  return (
    <Page
      className="bg-color-white d-flex-c"
      contentClassName="flex-1 h-1 p-20 overflow-y"
      appBar={<AppBar title="历史投诉记录" />}
    >
      {!!el
        ? el
        : complaint.results!.map((i) => (
            <div
              key={i.id}
              className="d-flex-r ai-center pv-5 border-bottom"
              onClick={() => navigate(`/complaintDetail/${i.id}`, { state: { canBack: true } })}
            >
              <div className="flex-1">
                <div className="d-flex-r ai-center pv-5">
                  <div className="w-60 text-color-9">投诉时间</div>
                  <div className="omit-text">{i.createTime}</div>
                </div>
                <div className="d-flex-r ai-center pv-5">
                  <div className="w-60 text-color-9">投诉类型</div>
                  <div className="omit-text">{i.workOrderName?.split(',').join('/')}</div>
                </div>
                <div className="d-flex-r ai-center pv-5">
                  <div className="w-60 text-color-9">投诉原因</div>
                  <div className="omit-text">{i.workOrderContent}</div>
                </div>
                <div className="d-flex-r ai-center pv-5">
                  <div className="w-60 text-color-9">处理结果</div>
                  <div className={`omit-text text-color-${ComplaintStatus.getTypeColor(i.status)}`}>
                    {ComplaintStatus.getTypeStr(i.status)}
                  </div>
                </div>
              </div>
              <img className="ml-20 h-14" src={main.pic('icon_right_xs.png')} alt="" />
            </div>
          ))}
    </Page>
  );
}
