import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { APIProtocolType } from '../utils/dict';
import { apiGetProtocol } from '../api';
import { AppStore, DetailRecord, Protocol } from '../types';

// 请求时需要传的参数
interface ActionParams {
  type: APIProtocolType;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  type: APIProtocolType;
  record: Protocol;
}

interface FailureParams {
  type: APIProtocolType;
  error: string;
}

type ProtocolStore = { [x in APIProtocolType]: DetailRecord<Protocol> };
const protocolSlice = createSlice({
  name: 'protocol',
  initialState: {} as ProtocolStore,
  reducers: {
    protocolRequest: (state: ProtocolStore, action: PayloadAction<ActionParams>) => {
      if (!state[action.payload.type]) {
        state[action.payload.type] = { fetching: true, loaded: false };
      }
      state[action.payload.type].fetching = true;
      return state;
    },
    protocolSuccess: (state: ProtocolStore, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.type]) {
        state[action.payload.type] = { fetching: false, loaded: true };
      }
      state[action.payload.type].fetching = false;
      state[action.payload.type].loaded = true;
      state[action.payload.type].result = action.payload.record!;
      return state;
    },
    protocolFailure: (state: ProtocolStore, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.type]) {
        state[action.payload.type] = { fetching: false, loaded: true };
      }
      state[action.payload.type].fetching = false;
      state[action.payload.type].loaded = true;
      state[action.payload.type].error = action.payload.error;
      return state;
    },
    protocolClear: (state: ProtocolStore) => {
      state = {} as ProtocolStore;
      return state;
    },
  },
});

export const { protocolRequest, protocolSuccess, protocolFailure, protocolClear } = protocolSlice.actions;
export default protocolSlice.reducer;

export const protocolEffect = {
  'protocol/clear': (store: ToolkitStore<AppStore>) => store.dispatch(protocolClear()),
  'protocol/protocolRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<ActionParams>) => {
    try {
      let res = await apiGetProtocol(action.payload.type);
      let data = res.data;
      if (data.code === 200) {
        let _protocol = (data.rows?.length ? data.rows[0] : {}) as Protocol;
        store.dispatch(protocolSuccess({ type: action.payload.type, record: _protocol }));
      } else {
        store.dispatch(protocolFailure({ type: action.payload.type, error: data.msg || '获取失败' }));
      }
    } catch (e: any) {
      store.dispatch(protocolFailure({ type: action.payload.type, error: e.toString() || '获取失败' }));
    }
  },
};
