import React from 'react';
import main from '../../main';
interface Props {
  tips?: string;
}
export default function DefaultError({ tips }: Props) {
  return (
    <div className="text-center">
      <img className="w-200 h-140" src={main.pic('pic_error.png', true)} alt="" />
      <div>{tips ?? '加载失败'}</div>
    </div>
  );
}
