import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { apiGetDirectRechargeList } from '../api';
import { AppStore, ChargeDirect, RecordList } from '../types';
import { modelParseFlow } from '../utils/utils';

interface RequestParams {
  cardId: string;
  forceRefresh?: boolean;
}

// 请求成功时自动传送的参数
interface SuccessParams {
  cardId: string;
  results: Array<ChargeDirect>;
}

interface FailureParams {
  cardId: string;
  error: string;
}

type ChargeDirectMap = {
  [key: string]: RecordList<ChargeDirect>;
};

const chargeDirectSlice = createSlice({
  name: 'chargeDirect',
  initialState: {} as ChargeDirectMap,
  reducers: {
    chargeDirectRequest: (state: ChargeDirectMap, action: PayloadAction<RequestParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = true;
      return state;
    },
    chargeDirectSuccess: (state: ChargeDirectMap, action: PayloadAction<SuccessParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].results = action.payload.results!;
      return state;
    },
    chargeDirectFailure: (state: ChargeDirectMap, action: PayloadAction<FailureParams>) => {
      if (!state[action.payload.cardId]) {
        state[action.payload.cardId] = { fetching: false, loaded: false };
      }
      state[action.payload.cardId].fetching = false;
      state[action.payload.cardId].loaded = true;
      state[action.payload.cardId].error = action.payload.error;
      return state;
    },
    chargeDirectClear: (state: ChargeDirectMap) => {
      state = {};
      return state;
    },
  },
});

export const { chargeDirectRequest, chargeDirectSuccess, chargeDirectFailure, chargeDirectClear } =
  chargeDirectSlice.actions;
export default chargeDirectSlice.reducer;

export const chargeDirectEffect = {
  'chargeDirect/clear': (store: ToolkitStore<AppStore>) => store.dispatch(chargeDirectClear()),
  'chargeDirect/chargeDirectRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<RequestParams>) => {
    var chargeDirectList = store.getState().chargeDirect[action.payload.cardId];
    if (chargeDirectList?.loaded === true && !chargeDirectList?.error && action?.payload?.forceRefresh !== true) {
      store.dispatch(
        chargeDirectSuccess({
          cardId: action.payload.cardId,
          results: chargeDirectList.results!,
        })
      );
    } else {
      try {
        let res = await apiGetDirectRechargeList(action.payload.cardId);
        let data = res.data;
        if (data.code === 200) {
          let list = (data.data ?? []).map((i: any) => {
            let item = Object.assign({}, i, { coupons: [] });
            if (i.clientCardDirectPayDetailCouponVos) {
              for (let _item of i.clientCardDirectPayDetailCouponVos) {
                let tmp = Object.assign({}, _item, _item.payCoupon, { couponName: _item.payCoupon?.name });
                tmp.addFlow = modelParseFlow(tmp.addFlow);
                item.coupons.push(tmp);
              }
            }
            return item;
          });
          store.dispatch(chargeDirectSuccess({ cardId: action.payload.cardId, results: list }));
        } else {
          store.dispatch(chargeDirectFailure({ cardId: action.payload.cardId, error: data.msg || '获取失败' }));
        }
      } catch (e: any) {
        store.dispatch(chargeDirectFailure({ cardId: action.payload.cardId, error: e.toString() || '获取失败' }));
      }
    }
  },
};
