import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

import { startUp } from './startupReducer';
import { AppStore } from '../types';
import main, { AppLocalKey } from '../main';

const logoutSlice = createSlice({
  name: 'logout',
  initialState: undefined,
  reducers: {
    logoutRequest: (state: undefined) => {
      return state;
    },
  },
});

export const { logoutRequest } = logoutSlice.actions;
export default logoutSlice.reducer;

export const logoutEffect = {
  'logout/logoutRequest': async (store: ToolkitStore<AppStore>, action: PayloadAction<{ callback?: Function }>) => {
    main.bindCardNum = 0;
    main.bindCardNumLoaded = false;
    main.loginToken = '';
    /** 是否跳转过绑卡页面 */
    main.jumpBindCard = false;
    /** 是否强制跳转过活动 */
    main.jumpToActivity = {};
    /** 是否强制跳转过买套餐 */
    main.jumpToBuyPack = {};
    /** 是否强制跳转过实名 */
    main.jumpToRealName = {};
    /** 是否跳转过直充活动页面 */
    main.jumpToCharge = {};
    /** 是否跳转过申请换卡页面 */
    main.jumpToTransfer = {};
    /** 套餐过期弹窗是否显示过 */
    main.packExpiredShowModal = {};
    /** 流量不足提醒 */
    main.flowLack = {};
    /** 流量超出提醒 */
    main.flowExceed = {};
    /** 标签弹窗是否显示过 {[iccid+config.configKey]:boolean} */
    main.tagDiaowShow = {};
    delete main.api.defaults.headers.common['Authorization'];
    localStorage.removeItem(AppLocalKey.loginToken);
    store.dispatch(startUp('start'));
  },
};
